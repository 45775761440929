import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { cloneDeep, isEqual } from 'lodash';
import { Tree, Input, Button, Tooltip } from 'antd';
import { TreeProps } from 'antd/lib/tree';

import { selectMenu } from 'redux/menu/selectors';
import { selectIikoEnabled } from 'redux/user/selectors';
import { Category } from 'entities/menu';
import { BlockModalConfig, useBlockerWithModal } from 'utils/navigation';
import { EyeInvisibleOutlined } from '@ant-design/icons';

import css from './MenuList.module.scss';

type TreeTitleProps = {
  id: number;
  onEdit: () => void;
  title: string;
  hideEdit: boolean;
  isHidden: boolean;
  type: 'category' | 'dish';
};

const TreeTitle = ({ title, id, onEdit, hideEdit, isHidden, type }: TreeTitleProps) => {
  const iikoEnabled = useSelector(selectIikoEnabled);

  return (
    <div className={css.treeElement} data-name={`${type}-${id}`}>
      <span className={css.treeTitle}>{title}</span>
      <div className={css.actionButtons}>
        {isHidden && (
          <span>
            <Tooltip
              title={
                type === 'category'
                  ? 'Данная категория скрыта в меню для пользователей'
                  : 'Данное блюдо скрыто в меню для пользователей'
              }
            >
              <EyeInvisibleOutlined
                style={{
                  color: 'rgb(119, 119, 119)'
                }}
              />
            </Tooltip>
          </span>
        )}
        {!hideEdit && (
          <span
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
            className={css.editButton}
          >
            {iikoEnabled ? 'Посмотреть' : 'Изменить'}
          </span>
        )}
      </div>
    </div>
  );
};

type Props = {
  sendNewMenuOrder: (categories: Category[]) => void;
};

const blockModalConfig: BlockModalConfig = {
  title: 'Вы не сохранили порядок меню',
  description: 'Уходя со страницы вы не сохраните изменения в сортировке меню. Продолжить?',
  onOkText: 'Продолжить',
  onCancelText: 'Отмена'
};

const MenuList = ({ sendNewMenuOrder }: Props) => {
  const iikoEnabled = useSelector(selectIikoEnabled);

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [expandedKeysCache, setExpandedKeysCache] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const menu = useSelector(selectMenu);
  const navigate = useNavigate();
  const [isDraggingCategory, setIsDraggingCategory] = useState(false);

  const [menuCopy, setMenuCopy] = useState<Category[]>([]);

  const isOrderChanged = useMemo(
    () => !!menu.length && !!menuCopy.length && !isEqual(menuCopy, menu),
    [menuCopy, menu]
  );

  const resetOrder = useCallback(() => {
    setMenuCopy(cloneDeep(menu));
  }, [menu]);

  useEffect(() => {
    if (isDraggingCategory) {
      setExpandedKeysCache(expandedKeys);
      setExpandedKeys([]);
    } else {
      setExpandedKeys(expandedKeysCache);
      setExpandedKeysCache([]);
    }
    // eslint-disable-next-line
  }, [isDraggingCategory]);

  useBlockerWithModal(blockModalConfig, isOrderChanged);

  useEffect(() => {
    setExpandedKeys(menuCopy.map((category) => `category-${category.categoryId}`));
  }, [menuCopy]);

  useEffect(() => {
    setMenuCopy(cloneDeep(menu));
  }, [menu]);

  const onEditDish = useCallback(
    (id: number) => {
      navigate(`/dashboard/menu/edit-dish/${id}`);
    },
    [navigate]
  );

  const onEditCategory = useCallback(
    (id: number) => {
      navigate(`/dashboard/menu/edit-category/${id}`);
    },
    [navigate]
  );

  const treeData: TreeProps['treeData'] = useMemo(() => {
    const data: TreeProps['treeData'] = [];

    menuCopy.forEach((category) => {
      const categoryItems: TreeProps['treeData'] = [];

      category.items.forEach((dish) => {
        if (
          (searchValue && dish.name.toLowerCase().includes(searchValue.trim().toLowerCase())) ||
          !searchValue.length
        ) {
          categoryItems.push({
            title: (
              <TreeTitle
                id={dish.id}
                title={dish.name}
                onEdit={() => onEditDish(dish.id)}
                hideEdit={isOrderChanged}
                isHidden={dish.isHidden}
                type="dish"
              />
            ),
            key: `dish-${category.categoryId}-${dish.id}`,
            selectable: false,
            // @ts-ignore
            type: 'dish'
          });
        }
      });

      return data.push({
        title: (
          <TreeTitle
            id={category.categoryId}
            title={category.categoryName}
            onEdit={() => onEditCategory(category.categoryId)}
            hideEdit={isOrderChanged || iikoEnabled}
            isHidden={category.isHidden}
            type="category"
          />
        ),
        key: `category-${category.categoryId}`,
        children: categoryItems,
        // @ts-ignore
        type: 'category'
      });
    });

    return data;
  }, [iikoEnabled, isOrderChanged, menuCopy, onEditCategory, onEditDish, searchValue]);

  return (
    <div className="mt-20 relative" data-name="products_tree">
      {isOrderChanged ? (
        <div className="mb-20">
          <Button type="primary" className="mr-8" onClick={() => sendNewMenuOrder(menuCopy)}>
            Сохранить порядок
          </Button>
          <Button onClick={resetOrder}>Отменить</Button>
        </div>
      ) : (
        <Input.Search
          className="mb-20"
          placeholder="Введите название блюда"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          data-name="products_search_input"
        />
      )}

      <Tree
        expandedKeys={expandedKeys}
        className={css.menuTreeWrapper}
        onDragStart={({ node }) => {
          if (
            // @ts-ignore
            node.type === 'category'
          ) {
            setIsDraggingCategory(true);
          }
        }}
        onDragEnd={() => {
          setIsDraggingCategory(false);
        }}
        onDrop={({ dragNode, node, dropPosition }) => {
          setMenuCopy((prevState) => {
            const nextState = cloneDeep(prevState);

            const [, dragCategoryIdx, dragDishIdx] = dragNode.pos.split('-').map((item) => parseInt(item, 10));
            const [, targetCategoryIdx, targetDishIdx] = node.pos.split('-').map((item) => parseInt(item, 10));

            // @ts-ignore
            if (dragNode.type === 'dish') {
              const categoryWithItem = nextState[dragCategoryIdx];
              const draggedItem = categoryWithItem.items[dragDishIdx];

              // When item dragged to category
              if (targetDishIdx === undefined) {
                categoryWithItem.items.splice(dragDishIdx, 1);
                nextState[targetCategoryIdx].items.unshift(draggedItem);
                return nextState;
              }

              if (dragCategoryIdx === targetCategoryIdx) {
                categoryWithItem.items = [
                  ...categoryWithItem.items.slice(0, dragDishIdx),
                  ...categoryWithItem.items.slice(dragDishIdx + 1)
                ];

                const targetIdx = dragDishIdx > targetDishIdx ? targetDishIdx + 1 : targetDishIdx;

                categoryWithItem.items = [
                  ...categoryWithItem.items.slice(0, targetIdx),
                  draggedItem,
                  ...categoryWithItem.items.slice(targetIdx)
                ];
              } else {
                // assign array without dragged item using slice
                nextState[dragCategoryIdx].items = [
                  ...categoryWithItem.items.slice(0, dragDishIdx),
                  ...categoryWithItem.items.slice(dragDishIdx + 1)
                ];

                // add item to target array
                nextState[targetCategoryIdx].items = [
                  ...nextState[targetCategoryIdx].items.slice(0, targetDishIdx + 1),
                  draggedItem,
                  ...nextState[targetCategoryIdx].items.slice(targetDishIdx + 1)
                ];
              }

              return nextState;
            }

            const dragCategory = nextState[dragCategoryIdx];

            if (dropPosition === -1) {
              nextState.splice(dragCategoryIdx, 1);
              nextState.splice(targetCategoryIdx, 0, dragCategory);
            } else {
              const resultPosition = dragCategoryIdx >= dropPosition ? dropPosition : dropPosition - 1;

              nextState.splice(dragCategoryIdx, 1);
              nextState.splice(resultPosition, 0, dragCategory);
            }

            return nextState;
          });
        }}
        draggable={!iikoEnabled && !searchValue}
        onSelect={(_, data) => {
          const toggledNodeKey = String(data.node.key);
          setExpandedKeys((oldKeys) => {
            if (oldKeys.includes(String(toggledNodeKey))) {
              return oldKeys.filter((key) => key !== toggledNodeKey);
            }
            return [...oldKeys, toggledNodeKey];
          });
        }}
        defaultExpandAll
        treeData={treeData}
        allowDrop={({ dragNode, dropNode, dropPosition }) => {
          // @ts-ignore
          const dragNodeType = dragNode.type as 'dish' | 'category';
          // @ts-ignore
          const dropNodeType = dropNode.type as 'dish' | 'category';

          if (dragNodeType === 'dish' && dropNodeType === 'dish') {
            return dropPosition === 1;
          }

          if (dragNodeType === 'dish' && dropNodeType === 'category') {
            return dropPosition === 0;
          }

          if (dragNodeType === 'category' && dropNodeType === 'dish') {
            return false;
          }

          if (dragNodeType === 'category' && dropNodeType === 'category' && dropPosition === 0) {
            return false;
          }

          return true;
        }}
      />
    </div>
  );
};

export default MenuList;
