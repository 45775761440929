import axios from 'utils/axios';
import { PromoItem } from 'entities/promo';

type Response = {
  items: PromoItem[];
};

const getList = async (pointSlug: string) =>
  axios.get<Response>('/promos', {
    params: {
      page: 1,
      perPage: 25,
      pointSlug
    }
  });

export default getList;
