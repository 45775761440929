/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Button, Spin, notification, Select } from 'antd';
import { useFormik, FormikConfig } from 'formik';
import * as yup from 'yup';

import { modifiersApi } from 'api';
import { fetchAllModifiers } from 'redux/modifiers/actions';
import errorMessages from 'utils/validation/validationErrorMessages';
import { useBlockerWithModal, BlockModalConfig } from 'utils/navigation';
import { useAppThunkDispatch, useFormValidatedOnce } from 'utils/hooks';
import { selectModifierCategories } from 'redux/modifiers/selectors';
import { selectIikoEnabled } from 'redux/user/selectors';
import { numberWithTwoDecimals } from 'utils/validation/fields';
import notificationMessages from 'utils/notificationMessages';
import { ErrorMessageLabel, MenuSectionTitle } from 'components';
import RemoveModifierModal from './RemoveModifierModal';

import css from './SubmitModifierContainer.module.scss';

type Params = {
  id: string | undefined;
};

type SubmitFormData = {
  categoryId: number;
  name: string;
  price: string;
};

const formId = 'submitDishForm';

const blockModalConfig: BlockModalConfig = {
  title: 'Вы не сохранили модификатор',
  description: 'Уходя вы не сохраните изменения в модификаторе. Продолжить?',
  onOkText: 'Продолжить',
  onCancelText: 'Отмена'
};

const formValidationSchema = yup
  .object({
    name: yup.string().required(errorMessages.common.isEmpty('Название')),
    price: yup.string().required(errorMessages.common.isEmpty('Цена')),
    categoryId: yup
      .number()
      .required(errorMessages.common.isEmpty('Категория'))
      .typeError(errorMessages.common.isEmpty('Категория'))
  })
  .required();

const initialValues: SubmitFormData = {
  name: '',
  // @ts-ignore
  categoryId: null,
  price: ''
};

const SubmitModifierContainer = () => {
  const iikoEnabled = useSelector(selectIikoEnabled);
  const dispatch = useAppThunkDispatch();
  const categories = useSelector(selectModifierCategories);
  const [modifierIdToRemove, setModifierIdToRemove] = useState<number | null>(null);

  const params = useParams<Params>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const isNew = !params.id;
  const modifierId = parseInt(params.id || '0', 10);

  const onSubmit: FormikConfig<SubmitFormData>['onSubmit'] = useCallback(
    async (data, { resetForm }) => {
      const { price, ...rest } = data;

      const fixedPrice = Math.round(+price * 100);

      const nextData = {
        ...rest,
        price: fixedPrice
      };

      return (isNew ? modifiersApi.createModifier(nextData) : modifiersApi.updateModifier(modifierId, nextData))
        .then(() => {
          notification.success({
            message: isNew
              ? notificationMessages.modifier.create.success()
              : notificationMessages.modifier.update.success()
          });
          resetForm({
            values: data,
            isSubmitting: true
          });
          setTimeout(() => {
            navigate('/dashboard/modifiers');
            dispatch(fetchAllModifiers());
          }, 20);
        })
        .catch(() => {
          notification.error({
            message: notificationMessages.defaultError()
          });
        });
    },
    [modifierId, isNew, navigate, dispatch]
  );

  const { setSubmitCount, validatedOnce } = useFormValidatedOnce();

  const formik = useFormik<SubmitFormData>({
    initialValues,
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: validatedOnce,
    onSubmit
  });

  useBlockerWithModal(blockModalConfig, formik.dirty);

  const { resetForm, values } = formik;

  setSubmitCount(formik.submitCount);

  useEffect(() => {
    let shouldOmitResponse = false;

    if (!isNew) {
      setLoading(true);
      modifiersApi
        .getModifier(modifierId)
        .then((res) => {
          if (!shouldOmitResponse) {
            const { data } = res;
            const { name, price, categoryId } = data;

            resetForm({
              values: {
                name,
                price: (price / 100).toFixed(2),
                categoryId
              }
            });

            setLoading(false);
          }
        })
        .catch(() => {
          notification.error({
            message: notificationMessages.modifier.get.failure(modifierId)
          });
          navigate('/dashboard/modifiers');
        });
    } else {
      resetForm({
        values: { ...initialValues, categoryId: categories[0].id }
      });
    }

    return () => {
      shouldOmitResponse = true;
    };
  }, [isNew, modifierId, resetForm, navigate, categories]);

  const onDeleteModifier = useCallback(() => {
    setModifierIdToRemove(modifierId);
  }, [modifierId]);

  const onDeleteModifierModalClosed = useCallback(() => {
    resetForm();
    setModifierIdToRemove(null);
    setTimeout(() => {
      navigate('/dashboard/modifiers');
      dispatch(fetchAllModifiers());
    }, 50);
  }, [dispatch, navigate, resetForm]);

  return (
    <div className={css.submitModifierContainer}>
      <Spin spinning={formik.isSubmitting || loading}>
        <Form
          className={css.submitModifierForm}
          name="create-modifier"
          layout="vertical"
          id={formId}
          autoComplete="off"
          onFinish={formik.submitForm}
        >
          <div className={css.headerContainer}>
            <MenuSectionTitle
              title={isNew ? 'Создать модификатор' : 'Изменить модификатор'}
              onButtonClick={() => navigate('/dashboard/modifiers')}
            />
          </div>

          <div className={css.fieldsContainer}>
            <Form.Item label="Название" validateStatus={formik.errors.name ? 'error' : undefined}>
              <Input placeholder="Введите название модификатора" {...formik.getFieldProps('name')} />
              <ErrorMessageLabel message={formik.errors.name} />
            </Form.Item>

            <Form.Item label="Цена" validateStatus={formik.errors.price ? 'error' : undefined}>
              <Input
                disabled={iikoEnabled}
                placeholder="Введите цену"
                value={values.price}
                onChange={(event) => {
                  event.stopPropagation();
                  const { value } = event.target;

                  if (!numberWithTwoDecimals(value)) {
                    return undefined;
                  }

                  return formik.setFieldValue(`price`, value);
                }}
              />
              <ErrorMessageLabel message={formik.errors.price} />
            </Form.Item>

            <Form.Item label="Категория" validateStatus={formik.errors.categoryId ? 'error' : undefined}>
              <Select
                {...formik.getFieldProps('categoryId')}
                placeholder="Выберите категорию модификатора"
                disabled={iikoEnabled}
                onChange={(nextCategoryId) => {
                  formik.setFieldValue('categoryId', nextCategoryId);
                }}
              >
                {categories.map((category) => (
                  <Select.Option key={category.id} value={category.id}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
              <ErrorMessageLabel message={formik.errors.categoryId} />
            </Form.Item>

            <div className={css.submitButtonsContainer}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              >
                {isNew ? 'Создать' : 'Сохранить'}
              </Button>
              {!isNew && !iikoEnabled && (
                <Button type="primary" danger onClick={onDeleteModifier} className="ml-12">
                  Удалить
                </Button>
              )}
            </div>
          </div>
        </Form>
        <RemoveModifierModal modifierId={modifierIdToRemove} onClose={onDeleteModifierModalClosed} />
      </Spin>
    </div>
  );
};

export default SubmitModifierContainer;
