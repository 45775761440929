import { createAsyncThunk } from '@reduxjs/toolkit';

import { pointsApi, userApi } from 'api';
import { handleError } from 'utils/axios';
import { getErrorMessage } from 'utils/getErrorMessage';
import type { InitialState } from './types';

const fetchUserInfo = createAsyncThunk<Omit<InitialState, 'isLoading'>>(
  'user/fetchUserInfo',
  async (_, { rejectWithValue }) => {
    try {
      const { data: userData } = await userApi.fetchUserInfo();

      if (userData.pointId) {
        const { data: pointData } = await pointsApi.getPoint(userData.pointId);

        return { ...userData, point: pointData };
      }

      return userData;
    } catch (e) {
      const { axiosError, error } = handleError(e);
      if (axiosError) {
        return rejectWithValue(getErrorMessage(axiosError));
      }
      return rejectWithValue(error?.message);
    }
  }
);

// eslint-disable-next-line import/prefer-default-export
export { fetchUserInfo };
