import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, notification } from 'antd';
import { promoApi } from 'api';

import { useAppThunkDispatch } from 'utils/hooks';
import { fetchAllPromos } from 'redux/promo/actions';
import notificationMessages from 'utils/notificationMessages';

type Props = {
  promoId: number | null;
  onClose: () => void;
};

const DeletePromoModal = ({ promoId, onClose }: Props) => {
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleRemove = async () => {
    setLoading(true);
    try {
      await promoApi.deletePromo(promoId!);
      notification.success({
        message: notificationMessages.promo.delete.success()
      });
      setLoading(false);
      onClose();
      dispatch(fetchAllPromos());
      navigate('/dashboard/promo');
    } catch (error: any) {
      notification.error({
        message: notificationMessages.defaultError()
      });
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={!!promoId}
      confirmLoading={loading}
      title="Хотите удалить акцию?"
      cancelText="Назад"
      cancelButtonProps={{
        disabled: loading
      }}
      closable={!loading}
      onCancel={onClose}
      okButtonProps={{
        danger: true
      }}
      okText="Удалить"
      onOk={handleRemove}
    >
      Если вы удалите акцию, назад её вернуть будет нельзя
    </Modal>
  );
};

export default DeletePromoModal;
