import { getErrorMessage } from 'utils/getErrorMessage';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Menu } from 'entities/menu';
import { menuApi } from 'api';
import { handleError } from '../../utils/axios';
import { AsyncThunkConfig } from '../../utils/redux';
import { selectOwnerPointSlug } from '../user/selectors';

const fetchFullMenu = createAsyncThunk<Menu, void, AsyncThunkConfig>(
  'menu/getFullMenu',
  async (_, { rejectWithValue, getState }) => {
    try {
      const pointSlug = selectOwnerPointSlug(getState());
      const { data } = await menuApi.getFullMenu(pointSlug!);

      return data;
    } catch (e) {
      const { axiosError, error } = handleError(e);
      if (axiosError) {
        return rejectWithValue(getErrorMessage(axiosError));
      }
      return rejectWithValue(error?.message);
    }
  }
);

export {
  // eslint-disable-next-line import/prefer-default-export
  fetchFullMenu
};
