import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tree } from 'antd';
import type { TreeProps } from 'antd/lib/tree';

import { useAppSelector } from 'utils/hooks';
import { selectIikoEnabled } from 'redux/user/selectors';
import { selectAllTables } from 'redux/tables/selectors';
import QRCodeContainer from '../QRCodeContainer';

import css from './TableList.module.scss';

type TreeTitleProps = {
  onEdit: () => void;
  hideEdit: boolean;
  item: {
    name: string;
    number?: number;
    uuid?: string;
  };
};

const TreeTitle = ({ onEdit, item, hideEdit }: TreeTitleProps) => (
  <div className={css.treeElement}>
    <p className={css.treeTitle}>
      {item.name}
      {item.number && <span>{` №${item.number}`}</span>}
    </p>

    <div className={css.actionButtons}>
      {item.uuid && <QRCodeContainer title={`${item.name} № ${item.number}`} tableId={item.uuid} />}
      {!hideEdit && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
          className={css.editButton}
        >
          Изменить
        </span>
      )}
    </div>
  </div>
);

const TableList = () => {
  const iikoEnabled = useSelector(selectIikoEnabled);
  const navigate = useNavigate();
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const halls = useAppSelector(selectAllTables);

  useEffect(() => {
    setExpandedKeys(halls.map((hall) => `hall-${hall.id}`));
  }, [halls]);

  const onEditHall = useCallback(
    (id: number) => {
      navigate(`/dashboard/tables/edit-hall/${id}`);
    },
    [navigate]
  );

  const onEditTable = useCallback(
    (id: number) => {
      navigate(`/dashboard/tables/edit-table/${id}`);
    },
    [navigate]
  );

  const treeData: TreeProps['treeData'] = useMemo(() => {
    const data: TreeProps['treeData'] = [];

    halls.forEach((hall) => {
      const hallItems: TreeProps['treeData'] = [];

      hall.tables.forEach((table) => {
        hallItems.push({
          title: <TreeTitle item={table} onEdit={() => onEditTable(table.id)} hideEdit={false} />,
          key: `table-${hall.id}-${table.id}`,
          selectable: false
        });
      });

      return data.push({
        title: <TreeTitle item={hall} onEdit={() => onEditHall(hall.id)} hideEdit={iikoEnabled} />,
        key: `hall-${hall.id}`,
        children: hallItems
      });
    });

    return data;
  }, [iikoEnabled, onEditHall, onEditTable, halls]);

  return (
    <div className="mt-20 relative">
      <Tree
        className={css.tablesTreeWrapper}
        expandedKeys={expandedKeys}
        checkStrictly
        treeData={treeData}
        onSelect={(_, data) => {
          const toggledNodeKey = String(data.node.key);
          // @ts-ignore
          setExpandedKeys((oldKeys) => {
            // @ts-ignore
            if (oldKeys.includes(String(toggledNodeKey))) {
              // @ts-ignore
              return oldKeys.filter((key) => key !== toggledNodeKey);
            }
            return [...oldKeys, toggledNodeKey];
          });
        }}
        defaultExpandAll
      />
    </div>
  );
};

export default TableList;
