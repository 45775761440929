import { PointInList, Point, GetAllPointsResponse } from 'entities/points';
import mock from './mockAdapter';

const getMockedPointInList = (id: number): PointInList => ({
  id,
  address: `Москва, Ленина, д.${id}`,
  name: `Кафе "Кафе Ленина" ${id}${id}`,
  createdAt: '2020-01-01T00:00:00.000Z',
  updatedAt: '2020-01-01T00:00:00.000Z',
  userId: id % 2 === 0 ? 2 : null,
  menuIsDescriptionShown: true,
  menuIsImageShown: true,
  menuView: 'card',
  menuGridColsCount: 2,
  mode: 'JIFF',
  slug: 'qwe123'
});

const getMockedPointById = (id: number): Point => ({
  id,
  address: `Москва, Ленина, д.${id}`,
  name: `Кафе "Кафе Ленина" ${id}${id}`,
  createdAt: '2020-01-01T00:00:00.000Z',
  updatedAt: '2020-01-01T00:00:00.000Z',
  rateId: 1,
  expiresAt: '2020-01-01T00:00:00.000Z',
  slug: 'qwe123',
  mode: 'JIFF',
  userId: 1,
  integrations: {},
  menuConfig: {
    menuView: 'card',
    menuGridColsCount: 2,
    menuIsDescriptionShown: true,
    menuIsImageShown: true
  }
});

const allPointsSuccessResponse: GetAllPointsResponse = {
  page: 1,
  perPage: 3,
  totalCount: 3,
  totalPages: 1,
  items: [1, 2, 3].map(getMockedPointInList)
};

// get all points
mock
  .onGet('/points')
  .replyOnce(401, {
    errorMessage: 'Invalid token'
  })
  .onGet('/points')
  .reply(200, allPointsSuccessResponse);

// get point
mock.onGet(/\/points\/\d+/).reply((config) => {
  const [, , pointId] = config!.url!.split('/');

  if (pointId === '123') {
    return [400, null];
  }

  return [200, getMockedPointById(parseInt(pointId, 10))];
});

// create point
mock.onPost('/points').replyOnce(401).onPost('/points').reply(200, {
  id: 10
});

// update point
mock.onPut(/\/points\/\d+/).reply((config) => {
  const [, , pointId] = config!.url!.split('/');
  return [
    200,
    {
      id: parseInt(pointId, 10)
    }
  ];
});

// delete point
mock.onDelete(/\/points\/\d+/).reply(200, null);

// invite owner
mock.onPost(/\/points\/\d+\/set_user/).reply(200, null);
