import jwtDecode from 'jwt-decode';
import { AuthToken } from '../entities/auth';

const accessTokenPath = 'access_token';
const refreshTokenPath = 'refresh_token';

export const getAccessToken = (): string | null => localStorage.getItem(accessTokenPath);
export const getRefreshToken = (): string | null => localStorage.getItem(refreshTokenPath);

export const getDataFromToken = () => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return null;
  }

  return jwtDecode<AuthToken>(accessToken);
};

export const setAuthTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(accessTokenPath, accessToken);
  localStorage.setItem(refreshTokenPath, refreshToken);
};

export const clearAuthTokens = () => {
  localStorage.removeItem(accessTokenPath);
  localStorage.removeItem(refreshTokenPath);
};

export const logoutRedirect = () => {
  clearAuthTokens();
  window.location.href = '/';
};
