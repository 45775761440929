import { StopListResponse } from 'entities/stopList';
import axios from 'utils/axios';

const getStopList = async (pointSlug: string) =>
  axios.get<StopListResponse>(`/stop-list`, {
    params: {
      pointSlug
    }
  });

export default getStopList;
