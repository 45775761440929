import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet, useMatch } from 'react-router-dom';
import { Button, Divider, notification, Spin } from 'antd';
import cn from 'classnames';

import { MenuSectionTitle, PagePreloader } from 'components';
import { selectIikoEnabled } from 'redux/user/selectors';
import { useAppThunkDispatch } from 'utils/hooks';
import { selectAllTables, selectTablesLoadingFlags } from 'redux/tables/selectors';
import { fetchAllHalls } from 'redux/tables/actions';

import css from './TablesPage.module.scss';
import TableList from './TableList';

const TablesPage = () => {
  const iikoEnabled = useSelector(selectIikoEnabled);
  const match = useMatch('/dashboard/tables');
  const dispatch = useAppThunkDispatch();
  const { loading, loaded } = useSelector(selectTablesLoadingFlags);
  const tables = useSelector(selectAllTables);

  const getAllHalls = useCallback(async () => {
    try {
      await dispatch(fetchAllHalls()).unwrap();
    } catch (error: any) {
      notification.error({ message: error });
    }
  }, [dispatch]);

  useEffect(() => {
    getAllHalls();
  }, [getAllHalls]);

  if (!loaded) {
    return <PagePreloader />;
  }

  return (
    <>
      <div
        className={cn(css.tablesContainer, {
          [css.tablesContainerHidden]: !match
        })}
      >
        <Spin spinning={loading}>
          <div className="m-20">
            <MenuSectionTitle title="Управление столами" />
            <Divider />
            <div className={css.tablesButtonsContainer}>
              {!iikoEnabled && !!tables.length && (
                <Link to="/dashboard/tables/create-table">
                  <Button type="primary">Добавить стол</Button>
                </Link>
              )}
              {!iikoEnabled && (
                <Link to="/dashboard/tables/create-hall">
                  <Button type="primary">Добавить зал</Button>
                </Link>
              )}
            </div>

            <TableList />
          </div>
        </Spin>
      </div>

      <Outlet />
    </>
  );
};

export default TablesPage;
