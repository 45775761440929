import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';

const selectState = (state: RootState) => state.stopList;

const selectStopListLoadingFlags = createSelector(selectState, ({ loading, loaded }) => ({ loading, loaded }));

const selectStopList = createSelector(selectState, ({ stopList }) => stopList);

export { selectStopListLoadingFlags, selectStopList };
