import React, { ContextType, useContext, useEffect } from 'react';
import { UserRole } from 'entities/auth';
import { Modal } from 'antd';

import type { Blocker, History, Transition } from 'history';
import { Navigator as BaseNavigator, UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

const userRoleToRootPathMap: Record<UserRole, string> = {
  admin: '/dashboard/managers',
  manager: '/dashboard/points',
  owner: '/dashboard/menu'
};

const getDefaultPathForRole = (role: UserRole) => userRoleToRootPathMap[role];

interface Navigator extends BaseNavigator {
  block: History['block'];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & { navigator: Navigator };

// React router v6 doesn't support history.block, so we need to use a custom implementation 🤡
/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
function useBlocker(blocker: Blocker, when = true) {
  const { navigator } = useContext(NavigationContext) as NavigationContextWithBlock;

  useEffect(() => {
    if (!when) {
      return undefined;
    }

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        }
      };

      blocker(autoUnblockingTx);
    });

    // Setting unblock function to window so it can be called after failed /refresh
    // @ts-ignore
    window.globalUnblock = unblock;

    return unblock;
  }, [navigator, blocker, when]);
}

export type BlockModalConfig = {
  title: string;
  description: string;
  onOkText: string;
  onCancelText: string;
};

const useBlockerWithModal = (config: BlockModalConfig, when: boolean) => {
  const blockerFunction = (tx: Transition) => {
    Modal.confirm({
      title: config.title,
      content: config.description,
      okText: config.onOkText,
      modalRender: (node) => <div data-name="redirect_warning_modal">{node}</div>,
      cancelText: config.onCancelText,
      onOk() {
        tx.retry();
      },
      onCancel() {
        // do nothing
      }
    });
  };

  useBlocker(blockerFunction, when);
};

export {
  // eslint-disable-ext-line import/prefer-default-export
  getDefaultPathForRole,
  useBlocker,
  useBlockerWithModal
};
