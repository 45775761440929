import axios from 'utils/axios';

export type Data = {
  terminalGroup: string;
  apiLogin: string;
};

const bindIiko = async (pointId: number, data: Data) => axios.post<null>(`/points/${pointId}/bind_iiko`, data);

export default bindIiko;
