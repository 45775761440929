import mock from './mockAdapter';
import { PromoItem } from '../../../entities/promo';

const promoList: {
  items: PromoItem[];
} = {
  items: [
    {
      id: 1,
      name: 'Промо продукт 1',
      description: 'Описание промо продукта 1',
      productIds: [1, 2, 3],
      image: 'https://picsum.photos/200/300',
      createdAt: '2020-01-01T00:00:00.000Z'
    },
    {
      id: 2,
      name: 'Промо продукт 2',
      description: 'Описание промо продукта 2',
      productIds: [4, 5, 6],
      image: 'https://picsum.photos/200/300',
      createdAt: '2020-01-01T00:00:00.000Z'
    }
  ]
};

// get all points
mock.onGet('/promos').reply(200, promoList);

mock.onGet(/\/promos\/\d+/).reply(200, {
  id: 1,
  name: 'Промо продукт 1',
  description: 'Описание промо продукта 1',
  productsIds: [1, 2, 3],
  image: 'https://picsum.photos/200/300'
});

mock.onPost('/promos').reply(200, null);

mock.onPut(/\/promos\/\d+/).reply(200, null);

mock.onDelete(/\/promos\/\d+/).reply(200, null);
