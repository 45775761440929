import axios from 'utils/axios';
import { PointFormSubmitData } from 'entities/points';

export type UpdatePointResponse = {
  id: number;
};

const updatePoint = async (id: number, data: PointFormSubmitData) =>
  axios.put<UpdatePointResponse>(`/points/${id}`, data);

export default updatePoint;
