import { createReducer } from '@reduxjs/toolkit';
import { InitialState } from './types';
import { fetchUserInfo } from './actions';

const initialState: InitialState = {
  role: undefined,
  firstName: '',
  lastName: '',
  point: undefined,
  isLoading: false
};

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUserInfo.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.role = action.payload.role;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.pointId = action.payload.pointId;
      state.pointSlug = action.payload.pointSlug;

      if (action.payload.point) {
        state.point = action.payload.point;
      }

      state.isLoading = false;
    })
    .addCase(fetchUserInfo.rejected, (state) => {
      state.isLoading = false;
    });
});

export default userReducer;
