/* eslint-disable react/jsx-no-undef */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik, FormikConfig, FormikProvider, FieldArray } from 'formik';
import * as yup from 'yup';
import { Form, Input, Button, Spin, Upload, Select, notification, Checkbox, Tooltip } from 'antd';
import { UploadOutlined, DeleteOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';

import { menuApi, filesApi } from 'api';
import { SubmitDishData, WeightType, DishSize } from 'entities/menu';
import errorMessages from 'utils/validation/validationErrorMessages';
import { useBlockerWithModal, BlockModalConfig } from 'utils/navigation';
import ImgCrop from 'antd-img-crop';

import { isBlob } from 'utils/utils';
import { useAppSelector, useAppThunkDispatch, useFormValidatedOnce } from 'utils/hooks';
import { selectMenu } from 'redux/menu/selectors';
import { fetchFullMenu } from 'redux/menu/actions';
import { numberWithTwoDecimals } from 'utils/validation/fields';
import getImagePath, { getImageUuid } from 'utils/getImagePath';
import notificationMessages from 'utils/notificationMessages';
import { selectModifiersLoadingFlags } from 'redux/modifiers/selectors';
import { selectIikoEnabled } from 'redux/user/selectors';
import { fetchAllModifiers } from 'redux/modifiers/actions';
import { ErrorMessageLabel, MenuSectionTitle } from 'components';
import RemoveDishModal from './RemoveDishModal';
import ModifiersSelector from './ModifiersSelector';
import { formId, getFormContainer, scrollableFormContainer } from './utils';

import css from './SubmitDishContainer.module.scss';

const maxDescriptionLength = 800;

const weightTypes: WeightType[] = ['гр', 'мл', 'кг', 'шт', 'л'];

type ArrayHelpers = {
  push: (obj: any) => void;
  remove<T>(index: number): T | undefined;
};

type Params = {
  id: string | undefined;
};

type SubmitDishSize = Omit<DishSize, 'price'> & { price: string };
// TODO move this transformation to entities
export type SubmitFormData = Omit<SubmitDishData, 'sizes'> & { weightType: WeightType; sizes: SubmitDishSize[] };

const maxSelectedRecommendations = 5;

const blockModalConfig: BlockModalConfig = {
  title: 'Вы не сохранили блюдо',
  description: 'Уходя вы не сохраните изменения в блюде. Продолжить?',
  onOkText: 'Продолжить',
  onCancelText: 'Отмена'
};

const formValidationSchema = yup
  .object({
    name: yup.string().required(errorMessages.common.isEmpty('Название')),
    // ingridients: yup.string().max(255, 'Must be exactly 255 digits'),
    // description: yup.string().max(255, 'Must be exactly 255 digits'),
    weight: yup.string().required(errorMessages.common.isEmpty('Вес/объем')),
    sizes: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required(errorMessages.common.isEmpty('Название')),
          price: yup.string().required(errorMessages.common.isEmpty('Цена'))
        })
      )
      .required('Должен быть задан хотя бы один размер'),
    categoryId: yup
      .number()
      .required(errorMessages.common.isEmpty('Категория'))
      .typeError(errorMessages.common.isEmpty('Категория'))
  })
  .required();

const initialValues: SubmitFormData = {
  name: '',
  image: null,
  // @ts-ignore
  categoryId: null,
  description: '',
  ingredients: '',
  sizes: [
    {
      id: undefined,
      name: 'Стандарт',
      price: '',
      isDefault: true
    }
  ],
  weight: '',
  weightType: 'гр',
  recommendedProductIds: [],
  modifierIds: [],
  isHidden: false
};

const SubmitDishContainer = () => {
  const iikoEnabled = useSelector(selectIikoEnabled);
  const dispatch = useAppThunkDispatch();
  const menu = useSelector(selectMenu);
  const { loaded: modifiersLoaded } = useAppSelector(selectModifiersLoadingFlags);
  const [dishIdToRemove, setDishIdToRemove] = useState<number | null>(null);

  const previousImageRef = useRef<string | null>(null);

  const params = useParams<Params>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const isNew = !params.id;
  const dishId = parseInt(params.id || '0', 10);

  useEffect(() => {
    if (!modifiersLoaded) {
      dispatch(fetchAllModifiers());
    }
  }, [modifiersLoaded, dispatch]);

  const onSubmit: FormikConfig<SubmitFormData>['onSubmit'] = useCallback(
    async (data, { resetForm }) => {
      const { weightType, weight, image, sizes, ...rest } = data;

      const fixedSizes = sizes.map((size) => ({ ...size, price: Math.round(+size.price * 100) }));

      let imageLink = isBlob(image) ? null : image;

      if (data.image !== null && typeof data.image !== 'string') {
        const response = await filesApi.uploadFile(data.image);

        imageLink = response.data.file;
      }

      const nextData = {
        ...rest,
        sizes: fixedSizes,
        weight: `${weight} ${weightType}`,
        image: imageLink
      };

      return (isNew ? menuApi.createDish(nextData) : menuApi.updateDish(dishId, nextData))
        .then(() => {
          if (previousImageRef.current && previousImageRef.current !== imageLink) {
            const imageUuid = getImageUuid(previousImageRef.current);
            filesApi.deleteFile(imageUuid);
          }
          notification.success({
            message: isNew ? notificationMessages.dish.create.success() : notificationMessages.dish.update.success()
          });
          resetForm({
            values: data,
            isSubmitting: true
          });
          setTimeout(() => {
            navigate('/dashboard/menu');
            dispatch(fetchFullMenu());
          }, 20);
        })
        .catch(() => {
          notification.error({
            message: notificationMessages.defaultError()
          });
        });
    },
    [dishId, isNew, navigate, dispatch]
  );

  const { setSubmitCount, validatedOnce } = useFormValidatedOnce();

  const formik = useFormik<SubmitFormData>({
    initialValues,
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: validatedOnce,
    onSubmit
  });

  useBlockerWithModal(blockModalConfig, formik.dirty);

  const { resetForm, values, setValues } = formik;

  const onSubmitHandler = async () => {
    await setValues({
      ...values,
      name: values.name.trim(),
      description: values.description.trim(),
      ingredients: values.ingredients.trim(),
      sizes: values.sizes.map((size) => ({
        ...size,
        name: size.name.trim()
      }))
    });
    await formik.submitForm();
  };

  setSubmitCount(formik.submitCount);

  useEffect(() => {
    let shouldOmitResponse = false;

    if (!isNew) {
      setLoading(true);
      menuApi
        .getDish(dishId)
        .then((res) => {
          if (!shouldOmitResponse) {
            const { data } = res;
            const {
              name,
              description,
              ingredients,
              weight,
              sizes,
              categoryId,
              image,
              recommendedProductIds,
              modifierIds,
              isHidden
            } = data;

            const [weightValue, weightType] = String(weight).split(' ');
            resetForm({
              values: {
                name,
                description,
                ingredients,
                sizes: [...sizes.map((size) => ({ ...size, price: (size.price / 100).toFixed(2) }))],
                weight: weightValue,
                weightType: (weightTypes.includes(weightType as WeightType) ? weightType : 'гр') as WeightType,
                categoryId,
                image,
                recommendedProductIds,
                modifierIds,
                isHidden
              }
            });
            previousImageRef.current = image;
            setLoading(false);
          }
        })
        .catch(() => {
          notification.error({
            message: notificationMessages.dish.get.failure(dishId)
          });
          navigate('/dashboard/menu');
        });
    } else {
      resetForm({
        values: { ...initialValues, categoryId: menu[0].categoryId }
      });
    }

    return () => {
      shouldOmitResponse = true;
    };
  }, [isNew, dishId, resetForm, navigate, menu]);

  const recommendedItemsLimitReached = formik.values.recommendedProductIds.length >= maxSelectedRecommendations;

  const onDeleteDish = useCallback(() => {
    setDishIdToRemove(dishId);
  }, [dishId]);

  const onDeleteDishModalClosed = useCallback(() => {
    resetForm();

    setTimeout(() => {
      setDishIdToRemove(null);
      dispatch(fetchFullMenu());
      navigate('/dashboard/menu');
    }, 50);
  }, [resetForm, dispatch, navigate]);

  const getDishSizes = useCallback(
    ({ push, remove }: ArrayHelpers) => (
      <div className={css.sizeContainer}>
        <div className={css.sizesList}>
          {formik.values.sizes.map((size, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={css.sizesItem}>
              <div className={css.sizeInputs}>
                <Form.Item
                  label="Название"
                  // @ts-ignore
                  validateStatus={formik.errors?.sizes?.[index]?.name ? 'error' : undefined}
                >
                  <Input
                    name={`sizes.${index}.name`}
                    disabled={iikoEnabled}
                    placeholder="Введите название"
                    value={size.name}
                    onChange={(event) => {
                      event.stopPropagation();
                      const { value } = event.target;

                      return formik.setFieldValue(`sizes.${index}.name`, value);
                    }}
                  />
                  <ErrorMessageLabel
                    // @ts-ignore
                    message={formik.errors?.sizes?.[index]?.name}
                  />
                </Form.Item>
                <Form.Item
                  label="Цена"
                  // @ts-ignore
                  validateStatus={formik.errors?.sizes?.[index]?.price ? 'error' : undefined}
                >
                  <Input
                    name={`sizes.${index}.price`}
                    disabled={iikoEnabled}
                    placeholder="Введите цену"
                    value={size.price}
                    onChange={(event) => {
                      event.stopPropagation();
                      const nextValue = event.target.value.trim();

                      if (!numberWithTwoDecimals(nextValue)) {
                        return undefined;
                      }

                      return formik.setFieldValue(`sizes.${index}.price`, nextValue);
                    }}
                  />
                  <ErrorMessageLabel
                    // @ts-ignore
                    message={formik.errors?.sizes?.[index]?.price}
                  />
                </Form.Item>
              </div>

              {!iikoEnabled && (
                <div className={css.sizeButtons}>
                  <Tooltip
                    placement="top"
                    title={
                      formik.values.sizes[index].isDefault
                        ? 'Размер выбран по умолчанию. Для удаления необходимо выбрать другой размер по умолчанию.'
                        : undefined
                    }
                  >
                    <Button
                      type="default"
                      onClick={() => remove(index)}
                      icon={<CloseOutlined />}
                      disabled={formik.values.sizes.length === 1 || formik.values.sizes[index].isDefault}
                      danger
                    >
                      Удалить размер
                    </Button>
                  </Tooltip>
                  {formik.values.sizes[index].isDefault ? (
                    <span className={css.sizeText}>По умолчанию</span>
                  ) : (
                    <Button
                      type="default"
                      onClick={() => {
                        formik.setFieldValue(
                          'sizes',
                          formik.values.sizes.map((item) => ({ ...item, isDefault: false }))
                        );
                        formik.setFieldValue(`sizes[${index}].isDefault`, true);
                      }}
                      disabled={formik.values.sizes[index].isDefault}
                    >
                      Выбрать по умолчанию
                    </Button>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>

        {!iikoEnabled && (
          <Button
            type="default"
            onClick={() => push({ name: '', price: '', isDefault: false })}
            className="mt-4"
            icon={<PlusOutlined />}
          >
            Добавить размер
          </Button>
        )}
      </div>
    ),
    [formik, iikoEnabled]
  );

  return (
    <div className={css.submitDishContainer}>
      <Spin spinning={formik.isSubmitting || loading}>
        <FormikProvider value={formik}>
          <Form
            className={css.submitDishForm}
            name="create-dish"
            layout="vertical"
            id={formId}
            autoComplete="off"
            onFinish={onSubmitHandler}
          >
            <div className={css.headerContainer}>
              <MenuSectionTitle
                title={isNew ? 'Создать блюдо' : 'Изменить блюдо'}
                onButtonClick={() => navigate('/dashboard/menu')}
              />
            </div>

            <div className={css.fieldsContainer} id={scrollableFormContainer}>
              <Form.Item label="Название блюда" validateStatus={formik.errors.name ? 'error' : undefined}>
                <Input placeholder="Введите название блюда" {...formik.getFieldProps('name')} disabled={iikoEnabled} />
                <ErrorMessageLabel message={formik.errors.name} />
              </Form.Item>
              {formik.values.image && (
                <Form.Item>
                  <img
                    className={css.image}
                    src={
                      typeof formik.values.image === 'string'
                        ? getImagePath(formik.values.image)
                        : URL.createObjectURL(formik.values.image)
                    }
                    alt="Ссылка на картинку"
                  />
                </Form.Item>
              )}
              <Form.Item label="Изменение изображения">
                <ImgCrop
                  quality={1}
                  aspect={320 / 195}
                  modalTitle="Кадрировать изображение"
                  modalOk="Применить"
                  modalCancel="Отмена"
                >
                  <Upload
                    name="logo"
                    beforeUpload={(data) => {
                      formik.setFieldValue('image', data);
                    }}
                    listType="picture"
                    fileList={[]}
                    accept="image/*"
                  >
                    <Button icon={<UploadOutlined />} className="mr-16" disabled={iikoEnabled}>
                      {formik.values.image ? 'Изменить изображение' : 'Загрузить изображение'}
                    </Button>
                    {formik.values.image && (
                      <Button
                        icon={<DeleteOutlined />}
                        className="mt-12"
                        onClick={(e) => {
                          e.stopPropagation();
                          formik.setFieldValue('image', null);
                        }}
                        disabled={iikoEnabled}
                      >
                        Удалить изображение
                      </Button>
                    )}
                  </Upload>
                </ImgCrop>
              </Form.Item>
              <Form.Item
                label={`Описание (${formik.values.description.length}/${maxDescriptionLength})`}
                validateStatus={formik.errors.description ? 'error' : undefined}
                className={css.descriptionContainer}
              >
                <Input.TextArea
                  placeholder="Введите описание блюда"
                  {...formik.getFieldProps('description')}
                  maxLength={maxDescriptionLength}
                  autoSize={{
                    minRows: 10
                  }}
                  disabled={iikoEnabled}
                />
                <ErrorMessageLabel message={formik.errors.description} />
              </Form.Item>
              <Form.Item label="Состав" validateStatus={formik.errors.ingredients ? 'error' : undefined}>
                <Input.TextArea
                  disabled={iikoEnabled}
                  placeholder="Введите состав блюда"
                  maxLength={255}
                  {...formik.getFieldProps('ingredients')}
                />
                <ErrorMessageLabel message={formik.errors.ingredients} />
              </Form.Item>
              <Form.Item label="Вес/объем">
                <div className={css.weightContainer}>
                  <Input
                    {...formik.getFieldProps('weight')}
                    disabled={iikoEnabled}
                    placeholder="Введите вес/объем блюда"
                    status={formik.errors.weight ? 'error' : undefined}
                    onChange={(e) => {
                      e.stopPropagation();
                      const nextValue = e.target.value.trim();

                      if (Number.isNaN(+nextValue)) {
                        return undefined;
                      }

                      return formik.setFieldValue('weight', nextValue);
                    }}
                  />
                  <Select
                    {...formik.getFieldProps('weightType')}
                    onChange={(nextWeightType) => {
                      formik.setFieldValue('weightType', nextWeightType);
                    }}
                    getPopupContainer={getFormContainer}
                    disabled={iikoEnabled}
                    className={css.weightType}
                    placeholder="тип"
                  >
                    {weightTypes.map((type) => (
                      <Select.Option key={type} value={type}>
                        {type}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <ErrorMessageLabel message={formik.errors.weight} />
              </Form.Item>

              <Form.Item label="Размеры">
                <FieldArray name="sizes" render={getDishSizes} />
              </Form.Item>

              <Form.Item label="Категория" validateStatus={formik.errors.categoryId ? 'error' : undefined}>
                <Select
                  {...formik.getFieldProps('categoryId')}
                  placeholder="Выберите категорию блюда"
                  disabled={iikoEnabled}
                  getPopupContainer={getFormContainer}
                  onChange={(nextCategoryId) => {
                    formik.setFieldValue('categoryId', nextCategoryId);
                  }}
                >
                  {menu.map((category) => (
                    <Select.Option key={category.categoryId} value={category.categoryId}>
                      {category.categoryName}
                    </Select.Option>
                  ))}
                </Select>
                <ErrorMessageLabel message={formik.errors.categoryId} />
              </Form.Item>
              <Form.Item>
                <Checkbox {...formik.getFieldProps('isHidden')} checked={values.isHidden}>
                  Скрыть блюдо
                </Checkbox>
              </Form.Item>

              <ModifiersSelector />

              <h4 className="pt-12">Рекомендации к блюду (максимум {maxSelectedRecommendations})</h4>
              <Form.Item label="Можно не указывать рекомендации">
                <Select
                  {...formik.getFieldProps('recommendedProductIds')}
                  mode="multiple"
                  disabled={iikoEnabled}
                  placement="topLeft"
                  optionFilterProp="label"
                  placeholder="Выберите одно или несколько блюд"
                  getPopupContainer={getFormContainer}
                  onChange={(nextValue) => {
                    formik.setFieldValue('recommendedProductIds', nextValue);
                  }}
                >
                  {menu.map((category) => {
                    const sanitizedItems = category.items.filter((item) => item.id !== dishId);

                    if (!sanitizedItems.length) return null;

                    return (
                      <Select.OptGroup key={category.categoryId} label={category.categoryName}>
                        {sanitizedItems.map((item) => {
                          const isActive = formik.values.recommendedProductIds.includes(item.id);
                          const shouldBeDisabled = !isActive && recommendedItemsLimitReached;

                          return (
                            <Select.Option key={item.id} disabled={shouldBeDisabled} value={item.id} label={item.name}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                      </Select.OptGroup>
                    );
                  })}
                </Select>
              </Form.Item>

              <div className={css.submitDishButtonsContainer}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
                >
                  {isNew ? 'Создать' : 'Сохранить'}
                </Button>
                {!isNew && !iikoEnabled && (
                  <Button type="primary" danger onClick={onDeleteDish} className="ml-12">
                    Удалить
                  </Button>
                )}
              </div>
            </div>
          </Form>
        </FormikProvider>
        <RemoveDishModal dishId={dishIdToRemove} onClose={onDeleteDishModalClosed} />
      </Spin>
    </div>
  );
};

export default SubmitDishContainer;
