import { createAsyncThunk } from '@reduxjs/toolkit';
import { managersApi } from 'api';
import { handleError } from 'utils/axios';
import { getErrorMessage } from 'utils/getErrorMessage';

const fetchManagers = createAsyncThunk<
  Awaited<ReturnType<typeof managersApi.getAllManagers>>['data'],
  number,
  {
    rejectValue?: string;
  }
>('managers/getAllManager', async (page, { rejectWithValue }) => {
  try {
    const response = await managersApi.getAllManagers({
      page
    });

    return response.data;
  } catch (e) {
    const { axiosError, error } = handleError(e);
    if (axiosError) {
      return rejectWithValue(getErrorMessage(axiosError));
    }
    return rejectWithValue(error?.message);
  }
});

// eslint-disable-next-line import/prefer-default-export
export { fetchManagers };
