import axios from 'utils/axios';
import { GetAllPointsResponse } from '../../entities/points';

type GetAllPointsParams = {
  page?: number;
  perPage?: number;
};

const getAllPoints = async (params: GetAllPointsParams = {}) =>
  axios.get<GetAllPointsResponse>('/points', {
    params: {
      page: params.page || 1,
      perPage: params.perPage || 25
    }
  });

export default getAllPoints;
