import React, { useCallback, useEffect } from 'react';
import { Button, Checkbox, Form, notification, Radio, RadioGroupProps, Tooltip } from 'antd';
import { FormikConfig, useFormik } from 'formik';
import { pointsApi } from 'api';
import { useAppSelector, useAppThunkDispatch, useFormValidatedOnce } from '../../../utils/hooks';
import MenuPreview from './MenuPreview';

import css from './MenuCustomization.module.scss';
import { MenuConfig } from '../../../entities/points';
import { selectOwnerPointId, selectPointMenuConfig } from '../../../redux/user/selectors';
import notificationMessages from '../../../utils/notificationMessages';
import { fetchUserInfo } from '../../../redux/user/actions';
import { BlockModalConfig, useBlockerWithModal } from '../../../utils/navigation';

type FormData = MenuConfig;

const blockModalConfig: BlockModalConfig = {
  title: 'Вы не сохранили настройки меню',
  description: 'Уходя вы не сохраните изменения конфигурации меню. Продолжить?',
  onOkText: 'Продолжить',
  onCancelText: 'Отмена'
};

const initialValues: FormData = {
  menuView: 'card',
  menuIsDescriptionShown: true,
  menuIsImageShown: true,
  menuGridColsCount: 2
};

const MenuCustomization = () => {
  const { setSubmitCount, validatedOnce } = useFormValidatedOnce();
  const pointId = useAppSelector(selectOwnerPointId);
  const menuConfig = useAppSelector(selectPointMenuConfig)!;
  const dispatch = useAppThunkDispatch();

  const onSubmit: FormikConfig<FormData>['onSubmit'] = useCallback(
    async (data) => {
      try {
        await pointsApi.setMenuConfig(pointId!, data);
        await dispatch(fetchUserInfo()).unwrap();
        notification.success({
          message: notificationMessages.settings.success()
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log({ e });
        notification.error({
          message: notificationMessages.defaultError()
        });
      }
    },
    [dispatch, pointId]
  );

  const formik = useFormik<FormData>({
    initialValues,
    validateOnBlur: false,
    validateOnChange: validatedOnce,
    onSubmit
  });

  const { values, setFieldValue, resetForm } = formik;

  useEffect(() => {
    resetForm({
      values: menuConfig
    });
  }, [menuConfig, resetForm]);

  setSubmitCount(formik.submitCount);

  useBlockerWithModal(blockModalConfig, formik.dirty);

  const onChangeLayoutType: RadioGroupProps['onChange'] = useCallback(
    // @ts-ignore
    (e) => {
      const value = e.target.value as FormData['menuView'];

      if (value === 'list') {
        setFieldValue('menuIsImageShown', false);
      }
      setFieldValue('menuView', value);
    },
    [setFieldValue]
  );

  return (
    <div className={css.container}>
      <div className={css.side}>
        <Form name="menu-settings" layout="vertical" autoComplete="off" onFinish={formik.submitForm}>
          <Form.Item label="Вид">
            <Radio.Group {...formik.getFieldProps('menuView')} onChange={onChangeLayoutType}>
              <Radio.Button value="card">Карточка</Radio.Button>
              <Radio.Button value="list">Текст</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Tooltip title={values.menuView === 'list' && 'Нельзя настроить для выбранного вида'} placement="bottomLeft">
            <Form.Item>
              <Checkbox
                {...formik.getFieldProps('menuIsImageShown')}
                value={!values.menuIsImageShown}
                onChange={(e) => {
                  formik.setFieldValue('menuIsImageShown', !e.target.checked);
                }}
                checked={!values.menuIsImageShown}
                disabled={values.menuView === 'list'}
              >
                Скрыть картинки
              </Checkbox>
            </Form.Item>
          </Tooltip>
          <Form.Item>
            <Checkbox
              {...formik.getFieldProps('menuIsDescriptionShown')}
              value={!values.menuIsDescriptionShown}
              onChange={(e) => {
                formik.setFieldValue('menuIsDescriptionShown', !e.target.checked);
              }}
              checked={!values.menuIsDescriptionShown}
            >
              Скрыть описание
            </Checkbox>
          </Form.Item>
          <Tooltip title={values.menuView === 'list' && 'Нельзя настроить для выбранного вида'} placement="bottomLeft">
            <Form.Item label="Количество элементов в ряду">
              <Radio.Group
                {...formik.getFieldProps('menuGridColsCount')}
                value={String(values.menuGridColsCount)}
                onChange={(e) => {
                  formik.setFieldValue('menuGridColsCount', parseInt(e.target.value, 10));
                }}
                disabled={values.menuView === 'list'}
              >
                <Radio.Button value="1">1</Radio.Button>
                <Radio.Button value="2">2</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Tooltip>

          <Button type="primary" htmlType="submit" disabled={formik.isSubmitting || !formik.isValid}>
            Сохранить
          </Button>
        </Form>
      </div>
      <div className={css.side}>
        <MenuPreview {...formik.values} />
      </div>
    </div>
  );
};

export default MenuCustomization;
