import axios from 'utils/axios';

type Params = {
  name: string;
  pointId: number;
};

const updateHall = async (id: number, data: Params) => axios.put(`/halls/${id}`, data);

export default updateHall;
