import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import QRCode from 'react-qr-code';
import { Button, Modal } from 'antd';

import { selectOwnerPointSlug } from 'redux/user/selectors';

import css from './QRCodeContainer.module.scss';

type Props = {
  title: string;
  tableId: string;
};

const QRCodeContainer = ({ title, tableId }: Props) => {
  const [qrCodeModalVisible, setQrCodeModalVisible] = useState(false);
  const pointSlug = useSelector(selectOwnerPointSlug);

  const svgDivRef = useRef<HTMLDivElement>(null);

  const openPrintPage = useCallback(() => {
    if (svgDivRef.current) {
      const printWindow = window.open('', '', 'height=512,width=512');

      if (printWindow) {
        printWindow.document.write(`
          <!DOCTYPE html>
          <html lang="en">
              <head>
                  <title>${title}</title>
              </head>
              <body>
                  ${svgDivRef.current!.innerHTML}
              </body>
          </html>
        `);
        printWindow.stop();
        printWindow.print();
        printWindow.close();
        setQrCodeModalVisible(false);
      }
    }
  }, [title]);

  return (
    <>
      <span
        className={css.QRButton}
        onClick={(event) => {
          event.stopPropagation();
          setQrCodeModalVisible(true);
        }}
      >
        QR код
      </span>

      <Modal
        footer={null}
        visible={qrCodeModalVisible}
        onCancel={() => setQrCodeModalVisible(false)}
        className={css.QRModal}
      >
        <h2 className={css.QRTitle}>{title}</h2>
        <div ref={svgDivRef}>
          <QRCode
            className="display-block margin-horizontal-auto"
            value={`${process.env.REACT_APP_CLIENT_FULL_URL}/${pointSlug}?tableId=${tableId}`}
          />
        </div>
        <Button className="mt-24" onClick={openPrintPage}>
          Перейти к печати
        </Button>
      </Modal>
    </>
  );
};

export default QRCodeContainer;
