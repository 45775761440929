import * as yup from 'yup';
import validationErrorMessages from './validationErrorMessages';

const inputPatterns = {
  email:
    /(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,15}/,
  password: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30}/
};

const emailFieldValidation = yup
  .string()
  .required(validationErrorMessages.common.isEmpty('Email'))
  .matches(inputPatterns.email, validationErrorMessages.common.isInvalid('Email'));

const passwordFieldValidation = yup
  .string()
  .required(validationErrorMessages.common.isEmpty('Пароль'))
  .matches(inputPatterns.password, validationErrorMessages.password.notMatchPattern());

const repeatPasswordFieldValidation = yup
  .string()
  .required(validationErrorMessages.common.isEmpty('Пароль'))
  .matches(inputPatterns.password, validationErrorMessages.password.notMatchPattern())
  .oneOf([yup.ref('password'), null], validationErrorMessages.password.passwordsNotMatch());

const numberWithTwoDecimals = (value: string) => {
  if (Number.isNaN(+value)) {
    return false;
  }

  const [, decimal] = value.split('.');
  if (decimal && decimal.length > 2) {
    return false;
  }

  return true;
};

export { emailFieldValidation, passwordFieldValidation, repeatPasswordFieldValidation, numberWithTwoDecimals };
