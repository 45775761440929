import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, notification, Select, Card } from 'antd';
import { FormikConfig, useFormik } from 'formik';
import { ErrorMessageLabel } from 'components';
import { useAppSelector, useAppThunkDispatch, useFormValidatedOnce } from 'utils/hooks';
import { selectOwnerPoint } from 'redux/user/selectors';
import { pointsApi } from 'api';
import * as yup from 'yup';
import { fetchUserInfo } from 'redux/user/actions';
import notificationMessages from 'utils/notificationMessages';
import errorMessage from 'utils/validation/validationErrorMessages';
import { TerminalGroup } from 'entities/points';

type FormData = {
  iikoIntegrationEnabled: boolean;
  iikoApiKey?: string;
  terminalGroupId?: string;
};

const initialValues: FormData = {
  iikoIntegrationEnabled: false,
  iikoApiKey: undefined,
  terminalGroupId: undefined
};

const formValidationSchema = yup
  .object({
    iikoApiKey: yup.string().when('iikoIntegrationEnabled', {
      is: true,
      then: yup.string().required(errorMessage.common.isEmpty('Ключ API'))
    }),
    terminalGroupId: yup.string().when('iikoIntegrationEnabled', {
      is: true,
      then: yup.string().required(errorMessage.common.isEmpty('Ключ API'))
    })
  })
  .required();

const successfulUpdateNotificationKey = 'point_mode_updated';
const successfulUpdateNotification = sessionStorage.getItem(successfulUpdateNotificationKey);

const IikoIntegration = () => {
  const dispatch = useAppThunkDispatch();
  const ownerPoint = useAppSelector(selectOwnerPoint);
  const { setSubmitCount, validatedOnce } = useFormValidatedOnce();
  const [wrongApiKey, setWrongApiKey] = useState(false);

  useEffect(() => {
    if (successfulUpdateNotification) {
      notification.success({
        message: notificationMessages.settings.success()
      });
      sessionStorage.removeItem(successfulUpdateNotificationKey);
    }
  }, []);

  const onSubmit: FormikConfig<FormData>['onSubmit'] = useCallback(
    async (data) => {
      const shouldUpdateIikoData =
        (data.iikoApiKey !== ownerPoint?.integrations.iiko?.apiLogin ||
          data.terminalGroupId !== ownerPoint?.integrations.iiko?.terminalGroup) &&
        data.iikoIntegrationEnabled;

      const shouldUpdateMode = data.iikoIntegrationEnabled !== (ownerPoint!.mode === 'IIKO');

      if (shouldUpdateIikoData) {
        await pointsApi.bindIiko(ownerPoint!.id, {
          apiLogin: data.iikoApiKey!,
          terminalGroup: data.terminalGroupId!
        });
      }

      if (shouldUpdateMode) {
        await pointsApi.changeMode(ownerPoint!.id, data.iikoIntegrationEnabled ? 'IIKO' : 'JIFF');
      }

      if (shouldUpdateMode || shouldUpdateIikoData) {
        await dispatch(fetchUserInfo())
          .unwrap()
          .then(() => {
            // TODO: update all tabs

            if (shouldUpdateMode) {
              window.location.reload();
              sessionStorage.setItem(successfulUpdateNotificationKey, '1');
            }

            notification.success({
              message: notificationMessages.settings.success()
            });
          });
      }
    },
    [dispatch, ownerPoint]
  );
  const [terminalGroups, setTerminalGroups] = useState<TerminalGroup[]>([]);
  const [terminalGroupsLoading, setTerminalGroupsLoading] = useState(false);

  const formik = useFormik<FormData>({
    initialValues,
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: validatedOnce,
    onSubmit
  });

  const { values, resetForm, setFieldValue } = formik;
  const { iikoApiKey } = values;

  useEffect(() => {
    resetForm({
      values: {
        iikoIntegrationEnabled: ownerPoint!.mode === 'IIKO',
        iikoApiKey: ownerPoint?.integrations?.iiko?.apiLogin || undefined,
        terminalGroupId: ownerPoint?.integrations?.iiko?.terminalGroup || undefined
      },
      submitCount: 0
    });
  }, [ownerPoint, resetForm]);

  setSubmitCount(formik.submitCount);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (iikoApiKey) {
        setTerminalGroupsLoading(true);
        setWrongApiKey(false);
        pointsApi
          .getTerminalGroups(iikoApiKey)
          .then((res) => {
            setTerminalGroups(res.data);

            if (res.data.length === 1) {
              setFieldValue('terminalGroupId', res.data[0].id);
            } else if (
              values.terminalGroupId &&
              res.data.findIndex((group) => group.id === values.terminalGroupId) === -1
            ) {
              setFieldValue('terminalGroupId', undefined);
            }

            setTerminalGroupsLoading(false);
          })
          .catch(() => {
            setWrongApiKey(true);
          });
      }
    }, 300);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iikoApiKey, setFieldValue]);

  return (
    <Card title="Интеграция с IIKO">
      <Form name="point-settings" layout="vertical" autoComplete="off" onFinish={formik.submitForm}>
        <Form.Item>
          <Checkbox {...formik.getFieldProps('iikoIntegrationEnabled')} checked={values.iikoIntegrationEnabled}>
            Включить интеграцию с iiko
          </Checkbox>
        </Form.Item>

        <Form.Item label="Введите IIKO API key" validateStatus={formik.errors.iikoApiKey ? 'error' : undefined}>
          <Input
            disabled={!values.iikoIntegrationEnabled}
            placeholder="Введите ключ от api"
            {...formik.getFieldProps('iikoApiKey')}
          />
          <ErrorMessageLabel message={formik.errors.iikoApiKey || wrongApiKey ? 'Неправильный апи ключ' : undefined} />
        </Form.Item>
        <Form.Item
          label="Выберите терминальную группу"
          validateStatus={formik.errors.terminalGroupId ? 'error' : undefined}
        >
          <Select
            {...formik.getFieldProps('terminalGroupId')}
            value={terminalGroups.length ? values.terminalGroupId : undefined}
            onChange={(nextValue) => {
              formik.setFieldValue('terminalGroupId', nextValue);
            }}
            disabled={!values.iikoIntegrationEnabled || terminalGroupsLoading}
            loading={terminalGroupsLoading}
            placeholder="Выберите терминальную группу"
          >
            {terminalGroups.map((group) => (
              <Select.Option key={group.id} value={group.id}>
                {group.name}
              </Select.Option>
            ))}
          </Select>
          <ErrorMessageLabel message={formik.errors.terminalGroupId} />
        </Form.Item>
        <Button type="primary" htmlType="submit" disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
          Сохранить
        </Button>
      </Form>
    </Card>
  );
};

export default IikoIntegration;
