import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Pagination, Table, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { selectPagination, selectPoints } from 'redux/points/selectors';
import { useAppThunkDispatch } from 'utils/hooks';
import { fetchPoints } from 'redux/points/actions';
import { NavLink } from 'react-router-dom';

import { PointInList } from '../../../entities/points';
import InviteOwnerModal from './InviteOwnerModal';
import css from './PointTable.module.scss';

const PointsTable = () => {
  const dispatch = useAppThunkDispatch();
  const points = useSelector(selectPoints);
  const pagination = useSelector(selectPagination);
  const [invitePointId, setInvitePointId] = useState<number | null>(null);

  const onPaginationChanged = useCallback(
    (nextPage: number) => {
      dispatch(fetchPoints(nextPage));
    },
    [dispatch]
  );

  const columns: ColumnsType<PointInList> = useMemo(
    () => [
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
        width: '30%'
      },
      {
        title: 'Адрес',
        key: 'address',
        dataIndex: 'address',
        width: '30%'
      },
      {
        title: 'Управляющий',
        key: 'manager',
        dataIndex: 'userEmail',
        render: (_, record) => (
          <div>
            {record.userId ? (
              <a href="#">Управляющий создан (id {record.userId})</a>
            ) : (
              <a onClick={() => setInvitePointId(record.id)} href="#">
                Пригласить управляющего
              </a>
            )}
          </div>
        )
      },
      {
        key: 'settings',
        title: 'Настройки',
        dataIndex: 'settings',
        render: (_, record) => (
          <div>
            <NavLink to={`/dashboard/points/edit/${record.id}`}>Изменить</NavLink>
          </div>
        )
      }
    ],
    []
  );

  return (
    <div>
      <div className={css.header}>
        <h3>Точки</h3>
        <NavLink to="/dashboard/points/create">
          <Button type="primary">Создать</Button>
        </NavLink>
      </div>
      <Table
        pagination={false}
        dataSource={points}
        columns={columns}
        rowKey={(what) => what.id}
        className={css.pointsTable}
      />
      <Pagination
        className="mt-20"
        onChange={onPaginationChanged}
        current={pagination.page}
        pageSize={pagination.perPage}
        total={pagination.totalCount}
      />
      <InviteOwnerModal onCancel={() => setInvitePointId(null)} pointId={invitePointId} />
    </div>
  );
};
export default PointsTable;
