const notificationMessages = {
  user: {
    forgotPassword: {
      success: () => 'Инструкции по восстановлению пароля были отправлены на ваш email',
      failure: () => 'Не удалось отправить инструкции по восстановлению пароля на ваш email'
    }
  },
  owner: {
    invite: {
      success: () => 'Приглашение успешно отправлено',
      failure: () => 'Не удалось отправить сообщение'
    }
  },
  rates: {
    get: { failure: () => 'Не удалось получить тарифы' }
  },
  menu: {
    newOrder: {
      success: () => 'Порядок изменён успешно',
      failure: () => 'Не удалось сохранить изменения в меню'
    }
  },
  point: {
    get: { failure: (id: number) => `Не удалось найти точку с id ${id}` },
    create: { success: (name: string) => `Точка ${name} успешно создана` },
    update: { success: (name: string) => `Точка ${name} успешно ` },
    delete: { success: (name: string) => `Точка ${name} успешно создана` }
  },
  category: {
    get: { failure: (id: number) => `Не удалось найти категорию с id ${id}` },
    create: { success: () => 'Категория успешно создана' },
    update: { success: () => 'Категория успешно обновлена' },
    delete: { success: () => 'Категория успешно удалена' }
  },
  dish: {
    get: { failure: (id: number) => `Не удалось найти блюдо с id ${id}` },
    create: { success: () => 'Блюдо успешно создано' },
    update: { success: () => 'Блюдо успешно обновлено' },
    delete: { success: () => 'Блюдо успешно удалено' }
  },
  promo: {
    get: { failure: (id: number) => `Не удалось найти промо акцию с id ${id}` },
    create: { success: () => 'Промо акция успешно создана' },
    update: { success: () => 'Промо акция успешно обновлена' },
    delete: { success: () => 'Промо акция успешно удалена' },
    maxCount: { failure: (count: number) => `Вы не можете создать больше ${count} акций` }
  },
  stopList: {
    update: { success: () => 'Стоп-лист успешно обновлён' }
  },
  settings: {
    success: () => 'Настройки успешно сохранены'
  },
  hall: {
    get: { failure: (id: number) => `Не удалось найти  зал с id ${id}` },
    create: { success: () => 'Зал успешно создан' },
    update: { success: () => 'Настройки зала успешно обновлены' },
    delete: { success: () => 'Зал успешно удалён' }
  },
  table: {
    get: { failure: (id: number) => `Не удалось найти  стол с id ${id}` },
    create: {
      success: () => 'Стол успешно создан',
      failure: () => `Произошла ошибка. Стол с таким номером уже существует.`
    },
    update: { success: () => 'Настройки стола успешно обновлены' },
    delete: { success: () => 'Стол успешно удалён' }
  },
  modifier: {
    get: { failure: (id: number) => `Не удалось найти  Модификатор с id ${id}` },
    create: {
      success: () => 'Модификатор успешно создан',
      failure: () => `Произошла ошибка. Категория с таким названием уже существует.`
    },
    update: { success: () => 'Настройки модификатора успешно обновлены' },
    delete: { success: () => 'Модификатор успешно удалён' }
  },
  defaultError: () => 'Что-то пошло не так. Попробуйте повторить запрос позднее.'
};

export default notificationMessages;
