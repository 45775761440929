import React from 'react';
import { useLocation, Location, Link, useNavigate } from 'react-router-dom';
import { Form, Input, Button, notification } from 'antd';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { forgotPassword } from 'redux/auth/actions';
import { ForgotPasswordRequestData } from 'entities/auth';
import { useAppThunkDispatch, useFormValidatedOnce } from 'utils/hooks';
import notificationMessages from 'utils/notificationMessages';
import { emailFieldValidation } from 'utils/validation/fields';
import { ErrorMessageLabel } from 'components';
import { ReactComponent as UserIcon } from 'images/user-icon.svg';

import css from './ForgotPasswordPage.module.scss';

type LocationType = Location & {
  state?: {
    email: string;
  };
};

const formValidationSchema = yup
  .object({
    email: emailFieldValidation
  })
  .required();

function ForgotPasswordPage() {
  const dispatch = useAppThunkDispatch();
  const location = useLocation() as LocationType;
  const navigate = useNavigate();
  const initialEmail = location.state?.email || '';
  const { setSubmitCount, validatedOnce } = useFormValidatedOnce();

  const onSubmit = async (formValues: ForgotPasswordRequestData) => {
    try {
      await dispatch(forgotPassword(formValues)).unwrap();
      notification.success({ message: notificationMessages.user.forgotPassword.success() });
      navigate('./auth/login');
    } catch (error: any) {
      notification.error({ message: notificationMessages.user.forgotPassword.failure() });
    }
  };

  const formik = useFormik<ForgotPasswordRequestData>({
    initialValues: {
      email: initialEmail
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: validatedOnce,
    onSubmit
  });

  setSubmitCount(formik.submitCount);

  return (
    <Form
      id="forgotPassword"
      className={css.forgotPasswordForm}
      name="recover-password"
      autoComplete="off"
      onFinish={formik.submitForm}
    >
      <h1 className={css.forgotPasswordFormTitle}>Восстановить пароль</h1>
      <Form.Item data-name="emailFormItem" validateStatus={formik.errors.email ? 'error' : undefined}>
        <Input prefix={<UserIcon />} placeholder="Email" {...formik.getFieldProps('email')} />
        <ErrorMessageLabel message={formik.errors.email} />
      </Form.Item>

      <Form.Item className={css.forgotPasswordFormButtons}>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          data-name="forgotPasswordButton"
          disabled={formik.isSubmitting || !formik.isValid}
          className={css.forgotPasswordSubmitButton}
        >
          Отправить
        </Button>
        <Button type="text" size="large" className={css.backButton} disabled={formik.isSubmitting}>
          <Link to="/auth/login">Назад</Link>
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ForgotPasswordPage;
