import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet, useMatch } from 'react-router-dom';
import { Spin, Button, Divider, notification } from 'antd';
import cn from 'classnames';

import { selectLoadingFlags, selectMenu } from 'redux/menu/selectors';
import { menuApi } from 'api';
import { useAppThunkDispatch } from 'utils/hooks';
import notificationMessages from 'utils/notificationMessages';
import { fetchFullMenu } from 'redux/menu/actions';
import { Category } from 'entities/menu';
import { selectIikoEnabled, selectOwnerPointId } from 'redux/user/selectors';
import { MenuSectionTitle, PagePreloader } from 'components';
import MenuList from './MenuList';

import css from './MenuPage.module.scss';

const MenuPage = () => {
  const dispatch = useAppThunkDispatch();
  const match = useMatch('/dashboard/menu');
  const { loading, loaded } = useSelector(selectLoadingFlags);
  const menu = useSelector(selectMenu);
  const [sortMenuLoading, setSortMenuLoading] = useState(false);
  const pointId = useSelector(selectOwnerPointId);
  const iikoEnabled = useSelector(selectIikoEnabled);

  const sendNewMenuOrder = useCallback(
    (categories: Category[]) => {
      setSortMenuLoading(true);

      const orderPayload = {
        categories: categories.map((category) => ({
          id: category.categoryId,
          products: category.items.map((item) => item.id)
        }))
      };

      menuApi
        .sendNewMenuOrder(pointId!, orderPayload)
        .then(() => {
          notification.success({
            message: notificationMessages.menu.newOrder.success()
          });
          dispatch(fetchFullMenu());
        })
        .catch((e) => {
          notification.error({
            message: notificationMessages.menu.newOrder.failure()
          });
          // eslint-disable-next-line no-console
          console.log({ e });
        })
        .finally(() => {
          setSortMenuLoading(false);
        });
    },
    [dispatch, pointId]
  );

  const getFullMenu = useCallback(async () => {
    try {
      await dispatch(fetchFullMenu()).unwrap();
    } catch (error: any) {
      notification.error({ message: error });
    }
  }, [dispatch]);

  useEffect(() => {
    getFullMenu();
  }, [getFullMenu]);

  if (!loaded) {
    return <PagePreloader />;
  }

  return (
    <>
      <div
        className={cn(css.menuContainer, {
          [css.menuContainerHidden]: !match
        })}
      >
        <Spin spinning={loading || sortMenuLoading}>
          <div className="m-20">
            <MenuSectionTitle title="Управление меню" />
            <Divider />
            <div className={css.menuButtonsContainer}>
              {!iikoEnabled && menu.length > 0 && (
                <Link to="/dashboard/menu/create-dish" data-name="add_dish_button">
                  <Button type="primary">Добавить блюдо</Button>
                </Link>
              )}
              {!iikoEnabled && (
                <Link to="/dashboard/menu/create-category" data-name="add_category_button">
                  <Button type="primary">Добавить категорию</Button>
                </Link>
              )}
            </div>
            <MenuList sendNewMenuOrder={sendNewMenuOrder} />
          </div>
        </Spin>
      </div>
      <Outlet />
    </>
  );
};

export default MenuPage;
