import { createReducer } from '@reduxjs/toolkit';
import { StoreState } from './types';
import { toggleSideBarOnMobile } from './actions';

const initialState: StoreState = {
  isSideBarOpen: false,
  mobileView: false
};

const layoutReducer = createReducer(initialState, (builder) => {
  builder.addCase(toggleSideBarOnMobile, (state, action) => {
    state.isSideBarOpen = action.payload;
  });
});

export default layoutReducer;
