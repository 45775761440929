import axios from 'utils/axios';
// import { mockedAxios as axios } from 'utils/axios';
import { Menu } from 'entities/menu';

const getFullMenu = async (pointSlug: string) =>
  axios.get<Menu>(`/products`, {
    params: {
      pointSlug,
      page: 1,
      perPage: 25
    }
  });

export default getFullMenu;
