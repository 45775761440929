import { createAsyncThunk } from '@reduxjs/toolkit';
import { stopListApi } from 'api';
import { StopListProductIds } from 'entities/stopList';
import { handleError } from 'utils/axios';
import { getErrorMessage } from 'utils/getErrorMessage';
import { selectOwnerPointSlug } from '../user/selectors';
import type { RootState } from '../store';

const fetchStopList = createAsyncThunk<
  StopListProductIds,
  void,
  {
    state: RootState;
  }
>('menu/getStopList', async (_, { rejectWithValue, getState }) => {
  try {
    const pointSlug = selectOwnerPointSlug(getState());
    const { data } = await stopListApi.getStopList(pointSlug!);

    return data.items;
  } catch (e) {
    const { axiosError, error } = handleError(e);
    if (axiosError) {
      return rejectWithValue(getErrorMessage(axiosError));
    }
    return rejectWithValue(error?.message);
  }
});

const updateStopListAction = createAsyncThunk<
  void,
  number[],
  {
    state: RootState;
  }
>('menu/updateStopList', async (productIds, { rejectWithValue, getState }) => {
  try {
    const pointSlug = selectOwnerPointSlug(getState());
    await stopListApi.updateStopList(pointSlug!, productIds);
    return undefined;
  } catch (e) {
    const { axiosError, error } = handleError(e);
    if (axiosError) {
      return rejectWithValue(getErrorMessage(axiosError));
    }
    return rejectWithValue(error?.message);
  }
});

export { fetchStopList, updateStopListAction };
