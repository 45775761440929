import React from 'react';
import { Dish } from '../../../../entities/menu';

import css from './CardItem.module.scss';

type Props = {
  item: Dish;
  hideImage: boolean;
  hideDescription: boolean;
};

const CardItem = ({ item, hideImage, hideDescription }: Props) => (
  <div className={css.container}>
    {!hideImage && (
      <div className={css.imageContainer}>
        <img className={css.image} src="/images/dishExample.jpeg" alt={item.name} />
      </div>
    )}
    <div className={css.infoContainer}>
      <div className={css.title}>{item.name}</div>
      <div className={css.weight}>{item.weight} г</div>
      {!hideDescription && <div className={css.description}>{item.description}</div>}
      <div className={css.price}>{item.sizes.find((size) => size.isDefault)?.price}₽</div>
    </div>
  </div>
);

export default CardItem;
