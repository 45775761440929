import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, notification } from 'antd';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { loginAction } from 'redux/auth/actions';
import { AuthRequestData } from 'entities/auth';
import { useAppThunkDispatch, useFormValidatedOnce } from 'utils/hooks';
import { emailFieldValidation } from 'utils/validation/fields';
import errorMessages from 'utils/validation/validationErrorMessages';
import { ErrorMessageLabel } from 'components';
import { ReactComponent as UserIcon } from 'images/user-icon.svg';
import { ReactComponent as LockIcon } from 'images/lock-icon.svg';

import css from './LoginPage.module.scss';

const formValidationSchema = yup
  .object({
    email: emailFieldValidation,
    // We remove the password validation because we don't want to expose possible password values
    password: yup.string().required(errorMessages.common.isEmpty('Пароль'))
  })
  .required();

function LoginPage() {
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();

  const onSubmit = async (formValues: AuthRequestData) => {
    try {
      await dispatch(loginAction(formValues)).unwrap();
      navigate('/dashboard');
    } catch (error: any) {
      notification.error({ message: error });
    }
  };

  const { setSubmitCount, validatedOnce } = useFormValidatedOnce();

  const formik = useFormik<AuthRequestData>({
    initialValues: {
      password: '',
      email: ''
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: validatedOnce,
    onSubmit
  });

  setSubmitCount(formik.submitCount);

  const forgotPasswordButtonClickHandler = () => {
    const { email } = formik.values;
    navigate('/auth/forgot_password', { state: { email } });
  };

  return (
    <Form className={css.loginForm} name="login" autoComplete="off" onFinish={formik.submitForm}>
      <h1 className={css.loginFormTitle}>Войти</h1>

      <Form.Item data-name="emailFormItem" validateStatus={formik.errors.email ? 'error' : undefined}>
        <Input prefix={<UserIcon />} placeholder="Email" {...formik.getFieldProps('email')} />
        <ErrorMessageLabel message={formik.errors.email} />
      </Form.Item>
      <Form.Item data-name="passwordFormItem" validateStatus={formik.errors.password ? 'error' : undefined}>
        <Input.Password
          prefix={<LockIcon />}
          placeholder="Пароль"
          visibilityToggle={false}
          {...formik.getFieldProps('password')}
        />
        <ErrorMessageLabel message={formik.errors.password} />
      </Form.Item>

      <Form.Item className={css.loginFormButtons}>
        <Button
          onClick={forgotPasswordButtonClickHandler}
          disabled={formik.isSubmitting}
          type="text"
          size="large"
          className={css.forgotPasswordButton}
        >
          Забыли пароль?
        </Button>

        <Button
          className={css.loginSubmitButton}
          type="primary"
          size="large"
          htmlType="submit"
          data-name="submitLoginButton"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          Войти
        </Button>
      </Form.Item>
    </Form>
  );
}

export default LoginPage;
