import axios from 'utils/axios';

type Response = {
  file: string;
};

const uploadFile = async (data: Blob) => {
  const formData = new FormData();

  formData.append('image', data);

  return axios.post<Response>(`/file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export default uploadFile;
