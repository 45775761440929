import React, { useCallback } from 'react';
import { Button, Form, Input, notification } from 'antd';
import * as yup from 'yup';

import { passwordFieldValidation, repeatPasswordFieldValidation } from 'utils/validation/fields';
import { SetInitialPasswordFormData } from 'entities/auth';
import { useFormik } from 'formik';
import { useFormValidatedOnce } from 'utils/hooks';
import { ErrorMessageLabel } from 'components';
import { ReactComponent as LockIcon } from 'images/lock-icon.svg';

import css from './SetInitialPasswordForm.module.scss';

const formValidationSchema = yup
  .object({
    password: passwordFieldValidation,
    repeatPassword: repeatPasswordFieldValidation
  })
  .required();

type Props = {
  onSubmit: (data: SetInitialPasswordFormData) => Promise<void>;
  submitButtonText: string;
  passwordInputPlaceholder: string;
  repeatPasswordInputPlaceholder: string;
  title: string;
};

const SetInitialPasswordForm = ({
  onSubmit,
  submitButtonText,
  passwordInputPlaceholder,
  repeatPasswordInputPlaceholder,
  title
}: Props) => {
  const { setSubmitCount, validatedOnce } = useFormValidatedOnce();

  const onSubmitHandler = useCallback(
    (data: SetInitialPasswordFormData) =>
      onSubmit(data).catch((e) => {
        if (typeof e === 'string') {
          notification.error({
            message: e
          });
        }
      }),
    [onSubmit]
  );
  const formik = useFormik<SetInitialPasswordFormData>({
    initialValues: {
      password: '',
      repeatPassword: ''
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: validatedOnce,
    onSubmit: onSubmitHandler
  });

  setSubmitCount(formik.submitCount);

  return (
    <Form className={css.initialPasswordForm} autoComplete="off" onFinish={formik.submitForm}>
      <h1 className={css.initialPasswordFormTitle}>{title}</h1>
      <Form.Item validateStatus={formik.errors.password ? 'error' : undefined}>
        <Input.Password
          prefix={<LockIcon />}
          placeholder={passwordInputPlaceholder}
          {...formik.getFieldProps('password')}
          visibilityToggle={false}
          size="large"
        />
        <ErrorMessageLabel message={formik.errors.password} />
      </Form.Item>

      <Form.Item validateStatus={formik.errors.repeatPassword ? 'error' : undefined}>
        <Input.Password
          prefix={<LockIcon />}
          placeholder={repeatPasswordInputPlaceholder}
          {...formik.getFieldProps('repeatPassword')}
          visibilityToggle={false}
          size="large"
        />
        <ErrorMessageLabel message={formik.errors.repeatPassword} />
      </Form.Item>

      <Form.Item>
        <Button
          disabled={formik.isSubmitting || !formik.isValid}
          type="primary"
          size="large"
          htmlType="submit"
          className={css.initialPasswordSubmitButton}
        >
          {submitButtonText}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SetInitialPasswordForm;
