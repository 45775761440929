import React, { useState } from 'react';
import { Modal, notification } from 'antd';

import { modifiersApi } from 'api';
import notificationMessages from 'utils/notificationMessages';

type Props = {
  modifierId: number | null;
  onClose: () => void;
};

const RemoveModifierModal = ({ modifierId, onClose }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleRemove = async () => {
    setLoading(true);
    try {
      await modifiersApi.deleteModifier(modifierId!);
      notification.success({
        message: notificationMessages.modifier.delete.success()
      });
      setLoading(false);
      onClose();
    } catch (error: any) {
      notification.error({
        message: notificationMessages.defaultError()
      });
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={!!modifierId}
      confirmLoading={loading}
      title="Удаление"
      cancelText="Назад"
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        danger: true
      }}
      closable={!loading}
      onCancel={onClose}
      okText="Удалить"
      onOk={handleRemove}
    >
      Если вы удалите модификатор, назад его вернуть будет нельзя
    </Modal>
  );
};

export default RemoveModifierModal;
