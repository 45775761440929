import React from 'react';
import { Helmet } from 'react-helmet';

import { useAppSelector } from 'utils/hooks';
import { selectRole } from 'redux/auth/selectors';
import { UserRole } from 'entities/auth';
import Sidebar from './Sidebar';

import css from './DashboardLayout.module.scss';

type Props = {
  children: React.ReactNode;
};

const roleNameMap: Record<UserRole, string> = {
  admin: 'Управление менеджерами',
  manager: 'Управление точками',
  owner: 'Управление меню'
};

const DashboardLayout = ({ children }: Props) => {
  const role = useAppSelector(selectRole);

  return (
    <div className={css.container}>
      <Helmet>
        <title>{`Jiff Food - ${roleNameMap[role]}`}</title>
      </Helmet>
      <Sidebar />
      <div className={css.contentContainer}>{children}</div>
    </div>
  );
};

export default DashboardLayout;
