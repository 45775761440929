import React, { memo, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import './MainLayout.module.scss';
import { useAppSelector, useAppThunkDispatch } from 'utils/hooks';
import { selectIsAuthorized } from 'redux/auth/selectors';
import { initApp } from 'redux/auth/actions';

const MainLayout = () => {
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const dispatch = useAppThunkDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthorized === undefined) {
      dispatch(initApp())
        .unwrap()
        .then((role) => {
          if (role && !location.pathname.startsWith('/dashboard')) {
            navigate('/dashboard');
          }
          if (!role && !location.pathname.startsWith('/auth')) {
            navigate('/auth');
          }
        });
    }
  }, [dispatch, isAuthorized, location, navigate]);

  if (isAuthorized === undefined) {
    return null;
  }

  return <Outlet />;
};

export default memo(MainLayout);
