import { createReducer } from '@reduxjs/toolkit';
import { InitialState } from './types';
import { fetchPoints } from './actions';

const initialState: InitialState = {
  page: 1,
  perPage: 1,
  totalCount: 1,
  totalPages: 1,
  points: [],
  isLoading: false,
  loaded: false
};

const pointsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchPoints.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(fetchPoints.fulfilled, (state, action) => {
      state.isLoading = false;
      state.points = action.payload.items;
      state.totalCount = action.payload.totalCount;
      state.totalPages = action.payload.totalPages;
      state.page = action.payload.page;
      state.perPage = action.payload.perPage;
      state.loaded = true;
    })
    .addCase(fetchPoints.rejected, (state) => {
      state.isLoading = false;
      state.loaded = false;
    });
});

export default pointsReducer;
