import { createAsyncThunk } from '@reduxjs/toolkit';
import { promoApi } from 'api';
import type { RootState } from 'redux/store';
import { handleError } from 'utils/axios';
import { getErrorMessage } from 'utils/getErrorMessage';
import { PromoItem } from 'entities/promo';
import { selectOwnerPointSlug } from '../user/selectors';

const fetchAllPromos = createAsyncThunk<
  PromoItem[],
  void,
  {
    rejectValue?: string;
    state: RootState;
  }
>('promo/fetchAllPromos', async (_, { rejectWithValue, getState }) => {
  try {
    const pointSlug = selectOwnerPointSlug(getState());
    const { data } = await promoApi.getList(pointSlug!);
    return data.items;
  } catch (e) {
    const { axiosError, error } = handleError(e);
    if (axiosError) {
      return rejectWithValue(getErrorMessage(axiosError));
    }
    return rejectWithValue(error?.message);
  }
});

// eslint-disable-next-line import/prefer-default-export
export { fetchAllPromos };
