import { createAsyncThunk } from '@reduxjs/toolkit';

import { pointsApi } from 'api';
import { getErrorMessage } from 'utils/getErrorMessage';
import { handleError } from 'utils/axios';

const fetchPoints = createAsyncThunk<
  Awaited<ReturnType<typeof pointsApi.getAllPoints>>['data'],
  number,
  {
    rejectValue?: string;
  }
>('points/fetchPoints', async (page, { rejectWithValue }) => {
  try {
    const response = await pointsApi.getAllPoints({
      page
    });

    return response.data;
  } catch (e) {
    const { axiosError, error } = handleError(e);
    if (axiosError) {
      return rejectWithValue(getErrorMessage(axiosError));
    }
    return rejectWithValue(error?.message);
  }
});

// eslint-disable-next-line import/prefer-default-export
export { fetchPoints };
