import axios from 'utils/axios';
import { PointFormSubmitData } from 'entities/points';

export type CreatePointResponse = {
  id: number;
};

const createPoint = async (data: PointFormSubmitData) => axios.post<CreatePointResponse>('/points', data);

export default createPoint;
