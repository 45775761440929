import { getErrorMessage } from 'utils/getErrorMessage';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { tablesApi } from 'api';
import { Hall } from 'entities/tables';
import { handleError } from '../../utils/axios';
import { AsyncThunkConfig } from '../../utils/redux';
import { selectOwnerPointId } from '../user/selectors';

const fetchAllHalls = createAsyncThunk<Hall[], void, AsyncThunkConfig>(
  'tables/fetchAllHalls',
  async (_, { rejectWithValue, getState }) => {
    try {
      const pointId = selectOwnerPointId(getState());
      const { data } = await tablesApi.getAllHalls(pointId!);

      return data;
    } catch (e) {
      const { axiosError, error } = handleError(e);
      if (axiosError) {
        return rejectWithValue(getErrorMessage(axiosError));
      }
      return rejectWithValue(error?.message);
    }
  }
);

export {
  // eslint-disable-next-line import/prefer-default-export
  fetchAllHalls
};
