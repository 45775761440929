import { createReducer } from '@reduxjs/toolkit';
import { InitialState } from './types';
import { loginAction, initApp, resetPassword } from './actions';

const initialState: InitialState = {
  isAuthorized: undefined,
  role: undefined,
  loading: false
};

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loginAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(loginAction.fulfilled, (state, action) => {
      state.isAuthorized = true;
      state.role = action.payload;
      state.loading = false;
    })
    .addCase(loginAction.rejected, (state) => {
      state.isAuthorized = false;
      state.loading = false;
    })
    .addCase(initApp.fulfilled, (state, action) => {
      state.isAuthorized = !!action.payload;
      state.role = action.payload;
      state.loading = false;
    })
    .addCase(initApp.rejected, (state) => {
      state.isAuthorized = false;
      state.loading = false;
    })
    .addCase(resetPassword.fulfilled, (state, action) => {
      state.role = action.payload;
      state.isAuthorized = true;
    });
});

export default authReducer;
