import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Avatar, Button } from 'antd';
import { CloseOutlined, SelectOutlined, UserOutlined } from '@ant-design/icons';

import { useAppSelector, useAppThunkDispatch } from 'utils/hooks';
import { selectRole } from 'redux/auth/selectors';
import { UserRole } from 'entities/auth';
import { selectSideBarOnMobile } from 'redux/layout/selectors';
import { usePermissions } from 'utils/hooks/permissions';
import { logout } from 'redux/auth/actions';
import { toggleSideBarOnMobile } from 'redux/layout/actions';

import cn from 'classnames';
import css from './Sidebar.module.scss';

const roleNameMap: Record<UserRole, string> = {
  admin: 'Администратор',
  manager: 'Менеджер',
  owner: 'Управляющий'
};

type LinkItem = {
  to: string;
  label: string;
};

const Sidebar = () => {
  const dispatch = useAppThunkDispatch();
  const { pathname } = useLocation();

  const closeSidebarHandler = useCallback(() => {
    dispatch(toggleSideBarOnMobile(false));
  }, [dispatch]);

  useEffect(() => {
    closeSidebarHandler();
  }, [pathname, closeSidebarHandler]);

  const role = useAppSelector(selectRole);
  const { firstName, lastName, point } = useAppSelector((state) => state.user);
  const isMobileSideBarOpen = useAppSelector(selectSideBarOnMobile);
  const overlayRef = useRef(null);

  const additionalLinks = useMemo(() => {
    const links: Array<React.ReactNode> = [];

    if (role === 'owner') {
      links.push(
        <a
          href={process.env.REACT_APP_FAQ_URL!}
          target="_blank"
          rel="noopener noreferrer"
          className={`${css.link} ${css.helpLink}`}
        >
          <div className={css.buttonContainer}>Справка</div>
          <SelectOutlined className={css.helpIcon} />
        </a>
      );
    }

    return links;
  }, [role]);

  const can = usePermissions();

  const links = useMemo(() => {
    const resultLinks: LinkItem[] = [];

    if (can.seeManagersPage) {
      resultLinks.push({
        label: 'Менеджеры',
        to: '/dashboard/managers'
      });
    }

    if (can.seePointsPage) {
      resultLinks.push({
        label: 'Точки',
        to: '/dashboard/points'
      });
    }

    if (can.seePointPage) {
      resultLinks.push({
        label: 'Меню',
        to: '/dashboard/menu'
      });
      resultLinks.push({
        label: 'Модификаторы',
        to: '/dashboard/modifiers'
      });
      resultLinks.push({
        label: 'Маркетинговые акции',
        to: '/dashboard/promo'
      });
      resultLinks.push({
        label: 'Стоп-лист',
        to: '/dashboard/stop-list'
      });
      resultLinks.push({
        label: 'Залы и столы',
        to: '/dashboard/tables'
      });
      resultLinks.push({
        label: 'Внешний вид',
        to: '/dashboard/menu-settings'
      });
      resultLinks.push({
        label: 'Настройки',
        to: '/dashboard/settings'
      });
    }

    return resultLinks;
  }, [can]);

  const onOverlayClick = (event: any) => {
    if (event.target === overlayRef.current) {
      closeSidebarHandler();
    }
  };

  return (
    <div
      className={`${css.wrapper} ${isMobileSideBarOpen && css.visibleOnMobile}`}
      ref={overlayRef}
      onClick={onOverlayClick}
    >
      <div className={css.container}>
        {isMobileSideBarOpen && (
          <Button onClick={closeSidebarHandler} className={css.closeSideBarButton}>
            <CloseOutlined />
          </Button>
        )}
        <div className={css.userInfo}>
          <Avatar className={css.avatar} size="large" icon={<UserOutlined />} />
          <span className={css.role}>{roleNameMap[role]}</span>
          <span className={css.name}>{`${firstName} ${lastName}`}</span>
        </div>
        {point && (
          <div className="ml-24 mr-24">
            <div className={css.pointInfo}>{point.name}</div>
            <a
              href={`${process.env.REACT_APP_CLIENT_FULL_URL}/${point.slug}`}
              target="_blank"
              rel="noopener noreferrer"
              className={css.restaurantLink}
            >
              Перейти в ресторан
            </a>
          </div>
        )}
        <div className={css.mainLinksContainer}>
          <div className={css.linksContainer}>
            {links.map((link) => (
              <NavLink
                key={link.to}
                to={link.to}
                className={cn(css.link, {
                  [css.linkActive]: pathname.startsWith(link.to)
                })}
              >
                {link.label}
              </NavLink>
            ))}
          </div>
          {additionalLinks.length > 0 && (
            <div>
              <div className={cn(css.divider, 'mb-8')} />
              {additionalLinks.map((link, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>{link}</div>
              ))}
            </div>
          )}
        </div>

        <div className={css.settingsContainer}>
          <Button type="primary" className={css.logoutButton} onClick={() => dispatch(logout())}>
            Выйти
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
