import axios from 'utils/axios';

type Params = {
  name: string;
  pointId: number;
};

const updateCategory = async (id: number, data: Params) => axios.put(`/menu_categories/${id}`, data);

export default updateCategory;
