import axios from 'utils/axios';
// import { mockedAxios as axios } from 'utils/axios';

import { Modifier } from 'entities/modifiers';

type ModifiersResponse = {
  modifiers: Modifier[];
};

const getModifiers = async (pointSlug: string) =>
  axios.get<ModifiersResponse>(`/modifiers`, {
    params: {
      pointSlug,
      page: 1,
      perPage: 25
    }
  });

export default getModifiers;
