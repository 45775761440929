import axios from 'utils/axios';
import { AuthResponseWithTokens } from 'entities/auth';

type Args = {
  password: string;
  token: string;
};

const resetPassword = async (data: Args) => axios.post<AuthResponseWithTokens>('/reset_password', data);

export default resetPassword;
