import React, { useCallback, useEffect, useState } from 'react';
import { SetInitialPasswordForm } from 'components/Forms';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SetInitialPasswordFormData } from 'entities/auth';
import { useAppThunkDispatch } from 'utils/hooks';
import { resetPassword } from 'redux/auth/actions';
// import { PagePreloader } from 'components';
import { authApi } from 'api';
import { notification, Spin } from 'antd';

const ResetPasswordPage = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppThunkDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get('token');

  const onSubmit = useCallback(
    (data: SetInitialPasswordFormData) =>
      dispatch(
        resetPassword({
          password: data.password,
          token: token!
        })
      )
        .unwrap()
        .then(() => {
          navigate('/dashboard');
        }),
    [dispatch, token, navigate]
  );

  useEffect(() => {
    if (!token) {
      navigate('/auth');
    } else {
      setLoading(true);
      authApi
        .checkResetToken(token)
        .then(() => {
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          notification.error({
            message: e?.response?.data?.errorMessage || e?.message || 'Что-то пошло не так'
          });
          navigate('/auth');
        });
    }
  }, [navigate, token]);

  if (!token) {
    return null;
  }

  // if (loading) {
  //   return (
  //     <div style={{ height: 200, position: 'relative' }}>
  //       <PagePreloader />
  //     </div>
  //   );
  // }

  return (
    <Spin spinning={loading}>
      <SetInitialPasswordForm
        onSubmit={onSubmit}
        passwordInputPlaceholder="Новый пароль"
        repeatPasswordInputPlaceholder="Повторите"
        submitButtonText="Сохранить"
        title="Введите новый пароль"
      />
    </Spin>
  );
};

export default ResetPasswordPage;
