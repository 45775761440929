import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Divider, Input, notification, Spin, Tooltip, Typography } from 'antd';
import { Link, Outlet, useMatch } from 'react-router-dom';
import moment from 'moment';
import cn from 'classnames';

import { useAppSelector, useAppThunkDispatch } from 'utils/hooks';
import { fetchAllPromos } from 'redux/promo/actions';
import { MenuSectionTitle, PagePreloader } from 'components';
import { selectLoadingState, selectPromos } from 'redux/promo/selectors';

import css from './PromoListPage.module.scss';

const maxPromosCount = 10;

const PromoListPage = () => {
  const match = useMatch('/dashboard/promo');
  const [searchValue, setSearchValue] = useState('');
  const { loading } = useAppSelector(selectLoadingState);
  const promos = useAppSelector(selectPromos);
  const dispatch = useAppThunkDispatch();

  const getAllPromos = useCallback(async () => {
    try {
      await dispatch(fetchAllPromos()).unwrap();
    } catch (error: any) {
      notification.error({ message: error });
    }
  }, [dispatch]);

  useEffect(() => {
    getAllPromos();
  }, [getAllPromos]);

  const filteredPromos = useMemo(
    () => promos.filter((promo) => promo.name.toLowerCase().includes(searchValue.toLowerCase())),
    [promos, searchValue]
  );

  const canAddMore = filteredPromos.length < maxPromosCount;

  if (loading) {
    return <PagePreloader />;
  }

  return (
    <>
      <div
        className={cn(css.promoPageContainer, {
          [css.promoPageContainerHidden]: !match
        })}
      >
        <Spin spinning={loading}>
          <div className="m-20">
            <MenuSectionTitle title="Маркетинговые акции" />
            <Divider />
            <div>
              <Tooltip title={!canAddMore && 'Вы не можете добавить более 10 акций'}>
                <Button disabled={!canAddMore} className="mr-20" type="primary">
                  <Link to="/dashboard/promo/create">Добавить акцию</Link>
                </Button>
              </Tooltip>
            </div>
            {promos.length === 0 ? (
              <h3 className="mt-20">У вас пока нет маркетинговых акций</h3>
            ) : (
              <div>
                <Input.Search
                  className="mb-20 mt-20"
                  placeholder="Введите название акции"
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                />
                {filteredPromos.map((promo) => (
                  <div className={css.promoItemContainer} key={promo.id}>
                    <div className="flex-direction-vertical">
                      <div>{promo.name}</div>
                      <Typography.Text className="fs-12" type="secondary">
                        {moment(promo.createdAt).format('DD.MM.YYYY HH:mm')}
                      </Typography.Text>
                    </div>
                    <div className="display-flex flex-align-items-center">
                      <Link className="mr-16" to={`/dashboard/promo/edit/${promo.id}`}>
                        Изменить
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Spin>
      </div>
      <Outlet />
    </>
  );
};

export default PromoListPage;
