import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';

const selectState = (state: RootState) => state.tables;

const selectTablesLoadingFlags = createSelector(selectState, ({ loading, loaded }) => ({ loading, loaded }));

const selectAllTables = createSelector(selectState, ({ halls }) => halls);

export { selectTablesLoadingFlags, selectAllTables };
