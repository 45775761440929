import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../store';

const selectState = (state: RootState) => state.managers;

const selectManagers = createSelector(selectState, (state) => state.managers);

const selectPagination = createSelector(selectState, ({ page, totalPages, totalCount, perPage }) => ({
  page,
  totalPages,
  totalCount,
  perPage
}));

export { selectState, selectManagers, selectPagination };
