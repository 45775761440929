import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';

const selectState = (state: RootState) => state.menu;

const selectLoadingFlags = createSelector(selectState, ({ loading, loaded }) => ({ loading, loaded }));

const selectMenu = createSelector(selectState, ({ menu }) => menu.menu);

export { selectLoadingFlags, selectMenu };
