import React, { useState } from 'react';
import { Modal, notification } from 'antd';

import { menuApi } from 'api';
import notificationMessages from 'utils/notificationMessages';

type Props = {
  categoryId: number | null;
  onClose: () => void;
};

const RemoveCategoryModal = ({ categoryId, onClose }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleRemove = async () => {
    setLoading(true);
    try {
      await menuApi.deleteCategory(categoryId!);
      notification.success({
        message: notificationMessages.category.delete.success()
      });
      setLoading(false);
      onClose();
    } catch (error: any) {
      notification.error({
        message: notificationMessages.defaultError()
      });
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={!!categoryId}
      confirmLoading={loading}
      title="Удаление категории"
      cancelText="Назад"
      cancelButtonProps={{
        disabled: loading
      }}
      closable={!loading}
      okButtonProps={{
        danger: true
      }}
      onCancel={onClose}
      okText="Удалить"
      onOk={handleRemove}
    >
      Если вы удалите категорию, <b>все блюда в ней удалятся безвозвратно</b>
    </Modal>
  );
};

export default RemoveCategoryModal;
