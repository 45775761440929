import React from 'react';
import { MenuSectionTitle } from '../../components';
import IikoIntegration from './IikoIntegration';

import css from './PointSettings.module.scss';

const PointSettings = () => (
  <div className={css.container}>
    <MenuSectionTitle title="Настройки" />
    <div className="mb-16">
      <IikoIntegration />
    </div>
  </div>
);

export default PointSettings;
