import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ResetPasswordPage, SetInitialPasswordPage, LoginPage, ForgotPasswordPage } from 'pages';
import { AuthLayout } from './layouts';

const DashboardRouter = () => (
  <AuthLayout>
    <Routes>
      <Route index element={<Navigate to="login" />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="reset" element={<ResetPasswordPage />} />
      <Route path="forgot_password" element={<ForgotPasswordPage />} />
      <Route path="set" element={<SetInitialPasswordPage />} />
      {/* TODO Remove tests routes at some point */}
      <Route path="*" element={<Navigate to="/auth" />} />
    </Routes>
  </AuthLayout>
);

export default DashboardRouter;
