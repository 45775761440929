import axios from 'utils/axios';
import { Rate } from 'entities/rates';

type Response = {
  items: Rate[];
};

const getRates = async () =>
  axios.get<Response>('/rates', {
    params: {
      page: 1,
      perPage: 25
    }
  });

export default getRates;
