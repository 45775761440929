import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'utils/hooks';
import { selectRole } from 'redux/auth/selectors';
import { selectIikoEnabled } from 'redux/user/selectors';
import { getDefaultPathForRole } from 'utils/navigation';
import {
  PointsPage,
  PointPage,
  AdminPage,
  MenuPage,
  SubmitDishContainer,
  SubmitCategoryContainer,
  PromoListPage,
  SubmitPromoContainer,
  StopListPage,
  PointSettings,
  TablesPage,
  SubmitHallContainer,
  SubmitTableContainer,
  PointMenuSettings,
  ModifiersPage,
  SubmitModifierCategoryContainer,
  SubmitModifierContainer
} from 'pages';
import { usePermissions } from '../utils/hooks/permissions';
import { DashboardLayout } from './layouts';

const DashboardRouter = () => {
  const role = useAppSelector(selectRole);
  const defaultPath = getDefaultPathForRole(role);
  const iikoEnabled = useAppSelector(selectIikoEnabled);

  const can = usePermissions();

  return (
    <DashboardLayout>
      <Routes>
        {/* Route for admin */}
        {can.seeManagersPage && <Route path="/managers" element={<AdminPage />} />}
        {/* Route for manager */}
        {can.seePointsPage && <Route path="/points" element={<PointsPage />} />}
        {can.seePointsPage && <Route path="/points/create" element={<PointPage />} />}
        {can.seePointsPage && <Route path="/points/edit/:id" element={<PointPage />} />}
        {/* Route for owner */}
        {can.seePointPage && (
          <Route path="menu" element={<MenuPage />}>
            {!iikoEnabled && (
              <>
                <Route path="create-dish" element={<SubmitDishContainer />} />
                <Route path="create-category" element={<SubmitCategoryContainer />} />
                <Route path="edit-category/:id" element={<SubmitCategoryContainer />} />
              </>
            )}
            <Route path="edit-dish/:id" element={<SubmitDishContainer />} />
          </Route>
        )}
        {can.seePointPage && (
          <Route path="modifiers" element={<ModifiersPage />}>
            {!iikoEnabled && (
              <>
                <Route path="create-modifier" element={<SubmitModifierContainer />} />
                <Route path="create-category" element={<SubmitModifierCategoryContainer />} />
                <Route path="edit-category/:id" element={<SubmitModifierCategoryContainer />} />
                <Route path="edit-modifier/:id" element={<SubmitModifierContainer />} />
              </>
            )}
          </Route>
        )}
        {can.seePointPage && (
          <Route path="promo" element={<PromoListPage />}>
            <Route path="create" element={<SubmitPromoContainer />} />
            <Route path="edit/:id" element={<SubmitPromoContainer />} />
          </Route>
        )}
        {can.seePointPage && <Route path="/stop-list" element={<StopListPage />} />}
        {can.seePointPage && <Route path="/settings" element={<PointSettings />} />}
        {can.seePointPage && <Route path="/menu-settings" element={<PointMenuSettings />} />}
        {can.seePointPage && (
          <Route path="tables" element={<TablesPage />}>
            {!iikoEnabled && (
              <>
                <Route path="create-hall" element={<SubmitHallContainer />} />
                <Route path="create-table" element={<SubmitTableContainer />} />
                <Route path="edit-hall/:id" element={<SubmitHallContainer />} />
              </>
            )}
            <Route path="edit-table/:id" element={<SubmitTableContainer />} />
          </Route>
        )}
        <Route path="/" element={<Navigate to={defaultPath} />} />
        <Route path="*" element={<Navigate to={defaultPath} />} />
      </Routes>
    </DashboardLayout>
  );
};

export default DashboardRouter;
