import axios from 'utils/axios';

type Params = {
  name: string;
  number: number;
  hallId: number;
};

const updateTables = async (id: number, data: Params) => axios.put(`/tables/${id}`, data);

export default updateTables;
