import React from 'react';
import { Dish } from '../../../../entities/menu';

import css from './ListItem.module.scss';

type Props = {
  item: Dish;
  hideDescription: boolean;
};

const ListItem = ({ item, hideDescription }: Props) => (
  <div className={css.container}>
    <div className={css.title}>{item.name}</div>
    {!hideDescription && <div className={css.description}>{item.description}</div>}
    <div className={css.subInfo}>
      <div className={css.price}>{item.sizes.find((size) => size.isDefault)?.price}₽</div>
      <div className={css.weight}>{item.weight} г</div>
    </div>
  </div>
);

export default ListItem;
