import React, { useEffect, useMemo, useState } from 'react';
import { Input, Tree } from 'antd';
import type { TreeProps } from 'antd/lib/tree';
import { useSelector } from 'react-redux';
import { selectMenu } from 'redux/menu/selectors';

import css from './DishList.module.scss';
import { selectIikoEnabled } from '../../../redux/user/selectors';

type Props = {
  currentStopList: number[];
  onSelect: (productId: number, action: 'add') => void;
};

const DishList = ({ currentStopList, onSelect }: Props) => {
  const iikoEnabled = useSelector(selectIikoEnabled);
  const menu = useSelector(selectMenu);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setExpandedKeys(menu.map(({ categoryId }) => `category-${categoryId}`));
  }, [menu]);

  const treeData: TreeProps['treeData'] = useMemo(() => {
    const data: TreeProps['treeData'] = [];

    menu.forEach((category) => {
      const categoryItems: TreeProps['treeData'] = [];
      const menuListCategoryItems = category.items.filter((dish) => !currentStopList.includes(dish.id));

      menuListCategoryItems.forEach((dish) => {
        if (
          (searchValue && dish.name.toLowerCase().includes(searchValue.trim().toLowerCase())) ||
          !searchValue.length
        ) {
          categoryItems.push({
            title: dish.name,
            key: dish.id,
            selectable: false,
            checkable: true
          });
        }
      });

      if (categoryItems.length === 0) {
        return undefined;
      }

      return data.push({
        title: category.categoryName,
        key: `category-${category.categoryId}`,
        children: categoryItems,
        checkable: false,
        selectable: true
      });
    });

    return data;
  }, [menu, searchValue, currentStopList]);

  const onCheck: TreeProps['onCheck'] = (_, checkedItem) => {
    onSelect(checkedItem.node.key as number, 'add');
  };

  return (
    <div className={css.container}>
      <h3 className={css.title}>Выберите блюдо</h3>
      <Input.Search
        className="mb-20"
        placeholder="Поиск"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />

      <Tree
        expandedKeys={expandedKeys}
        checkedKeys={[]}
        defaultExpandAll
        checkable={!iikoEnabled}
        checkStrictly
        treeData={treeData}
        onCheck={onCheck}
        onSelect={(_, data) => {
          const toggledNodeKey = String(data.node.key);
          setExpandedKeys((oldKeys) => {
            if (oldKeys.includes(String(toggledNodeKey))) {
              return oldKeys.filter((key) => key !== toggledNodeKey);
            }
            return [...oldKeys, toggledNodeKey];
          });
        }}
      />
    </div>
  );
};

export default DishList;
