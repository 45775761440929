import React, { useState } from 'react';
import { Modal, notification } from 'antd';

import { menuApi } from 'api';
// import { Dish } from 'entities/menu';

import notificationMessages from 'utils/notificationMessages';

type Props = {
  dishId: number | null;
  onClose: () => void;
};

const RemoveDishModal = ({ dishId, onClose }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleRemove = async () => {
    setLoading(true);
    try {
      await menuApi.deleteDish(dishId!);
      notification.success({
        message: notificationMessages.dish.delete.success()
      });
      setLoading(false);
      onClose();
    } catch (error: any) {
      notification.error({
        message: notificationMessages.defaultError()
      });
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={!!dishId}
      confirmLoading={loading}
      title="Удаление"
      cancelText="Назад"
      cancelButtonProps={{
        disabled: loading
      }}
      okButtonProps={{
        danger: true
      }}
      closable={!loading}
      onCancel={onClose}
      okText="Удалить"
      onOk={handleRemove}
    >
      Если вы удалите блюдо, назад его вернуть будет нельзя
    </Modal>
  );
};

export default RemoveDishModal;
