import axios from 'utils/axios';
// import { mockedAxios as axios } from 'utils/axios';

type SubmitData = {
  name: string;
  price: number;
};

const updateModifier = async (id: number, data: SubmitData) => axios.put<null>(`/modifiers/${id}`, data);

export default updateModifier;
