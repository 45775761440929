import React from 'react';

import css from './ErrorMessageLabel.module.scss';

type Props = {
  message?: string;
  dataName?: string;
};

const ErrorMessageLabel = ({ message, dataName = 'errorMessage' }: Props) => {
  if (!message) {
    return null;
  }

  return (
    <div className={css.error} data-name={dataName}>
      {message}
    </div>
  );
};

export default ErrorMessageLabel;
