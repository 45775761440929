import React from 'react';
import { MenuSectionTitle } from '../../components';
import MenuCustomization from './MenuCustomization';

import css from './PointMenuSettings.module.scss';

const PointMenuSettings = () => (
  <div className={css.container}>
    <MenuSectionTitle title="Внешний вид" />
    <MenuCustomization />
  </div>
);

export default PointMenuSettings;
