import React, { useCallback } from 'react';
import { Alert, Button } from 'antd';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';

import { useSelector } from 'react-redux';
import css from './MenuSectionTitle.module.scss';
import { toggleSideBarOnMobile } from '../../redux/layout/actions';
import { useAppThunkDispatch } from '../../utils/hooks';
import { selectIikoEnabled } from '../../redux/user/selectors';

type Props = {
  title: string;
  onButtonClick?: () => void;
  dataName?: string;
};

const MenuSectionTitle = ({ title, onButtonClick, dataName = 'closePageButton' }: Props) => {
  const dispatch = useAppThunkDispatch();
  const iikoEnabled = useSelector(selectIikoEnabled);

  const openMobileMenu = useCallback(() => dispatch(toggleSideBarOnMobile(true)), [dispatch]);

  return (
    <>
      <div className={css.titleContainer}>
        <div className="display-flex">
          <Button className={css.menuButton} icon={<MenuOutlined />} onClick={openMobileMenu} />
          <h2>{title}</h2>
        </div>
        {onButtonClick && <Button icon={<CloseOutlined />} data-name={dataName} onClick={onButtonClick} />}
      </div>
      {iikoEnabled && (
        <Alert
          message="Включена интеграция с IIKO, не все функции могут быть доступны"
          className="mb-8"
          type="info"
          showIcon
        />
      )}
    </>
  );
};

export default MenuSectionTitle;
