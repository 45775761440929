import axios from 'utils/axios';

import { Hall } from 'entities/tables';

const getAllHalls = async (pointId: number) =>
  axios.get<Hall[]>(`/halls`, {
    params: {
      pointId,
      page: 1,
      perPage: 25
    }
  });

export default getAllHalls;
