import { Modifier, ModifierCtegory } from 'entities/modifiers';
import mock from './mockAdapter';

type ModifierCategories = {
  modifiers: ModifierCtegory[];
};

const mockedModifierCategories: ModifierCategories = {
  modifiers: [
    {
      id: 1,
      pointId: 1,
      name: 'Добавки к пицце',
      items: [1, 2],
      isDefault: false
    },
    {
      id: 2,
      pointId: 1,
      name: 'Соусы',
      items: [3, 4],
      isDefault: false
    },
    {
      id: 3,
      pointId: 1,
      name: 'Общий',
      items: [5],
      isDefault: true
    }
  ]
};

type MockedModifiers = {
  modifiers: Modifier[];
};

const mockedModifiers: MockedModifiers = {
  modifiers: [
    {
      id: 1,
      categoryId: 1,
      pointId: 1,
      name: 'Бекон',
      price: 50
    },
    {
      id: 2,
      categoryId: 1,
      pointId: 1,
      name: 'Сыр',
      price: 50
    },
    {
      id: 3,
      categoryId: 2,
      pointId: 1,
      name: 'Соус Тысяча Островов',
      price: 20
    },
    {
      id: 4,
      categoryId: 2,
      pointId: 1,
      name: 'Сырный соус',
      price: 30
    },
    {
      id: 5,
      categoryId: 3,
      pointId: 1,
      name: 'Жареный лук',
      price: 50
    }
  ]
};

mock.onGet('/api/v1/modifier_categories').reply(200, mockedModifierCategories);
mock.onGet('/api/v1/modifiers').reply(200, mockedModifiers);

mock.onPost(`/api/v1/modifier_categories`).reply(200);
mock.onPost('/api/v1/modifiers').reply(200);

mockedModifiers.modifiers.forEach((modifier) => {
  mock.onGet(`/api/v1/modifiers/${modifier.id}`).reply(200, modifier);
  mock.onPut(`/api/v1/modifier_categories/${modifier.id}`).reply(200, `Модификатор c id ${modifier.id} обновлён`);
  mock.onDelete(`/api/v1/modifier_categories/${modifier.id}`).reply(200, `Модификатор c id ${modifier.id} удалён`);
});

mockedModifierCategories.modifiers.forEach((category) => {
  mock.onGet(`/api/v1/modifier_categories/${category.id}`).reply(200, category);
  mock.onPut(`/api/v1/modifier_categories/${category.id}`).reply(200, `Категория c id ${category.id} обновлена`);
  mock.onDelete(`/api/v1/modifier_categories/${category.id}`).reply(200, `Категория c id ${category.id} удалена`);
});
