import { AxiosError } from 'axios';

const defaultErrorMessagesMap: Record<number, string> = {
  400: 'Некорректный запрос.',
  401: 'Неправильное имя пользователя или пароль.',
  403: 'Доступ запрещён.',
  404: 'Запрашиваемый ресурс не найден.',
  500: 'Произошла ошибка на сервере.'
};

const getErrorMessage = (error: AxiosError) => {
  const errorCode = error.response?.status;

  return (errorCode && defaultErrorMessagesMap[errorCode]) || defaultErrorMessagesMap[500];
};

export { getErrorMessage, defaultErrorMessagesMap };
