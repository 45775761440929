import React, { useCallback, useMemo } from 'react';
import { Pagination, Table, Button } from 'antd';
import type { ColumnsType } from 'antd/lib/table';

import { Manager } from 'entities/managers';
import { selectManagers, selectPagination } from 'redux/managers/selectors';
import { useAppSelector, useAppThunkDispatch } from 'utils/hooks';
import { fetchManagers } from 'redux/managers/actions';

import css from './ManagersTable.module.scss';

function ManagersTable({ addManager }: { addManager: () => void }) {
  const dispatch = useAppThunkDispatch();
  const managers = useAppSelector(selectManagers);
  const pagination = useAppSelector(selectPagination);

  const onPaginationChanged = useCallback(
    (nextPage: number) => {
      dispatch(fetchManagers(nextPage));
    },
    [dispatch]
  );

  const columns: ColumnsType<Manager> = useMemo(
    () => [
      {
        title: 'Почта аккаунта',
        dataIndex: 'email',
        key: 'email'
      },
      {
        title: 'ФИО',
        key: 'fullName',
        dataIndex: 'fullName',
        render: (_, record) => `${record.firstName} ${record.lastName}`
      }
    ],
    []
  );

  return (
    <div className={css.container}>
      <div className={css.header}>
        <h3 className={css.title}>Список менеджеров</h3>
        <Button type="primary" onClick={addManager} className={css.addManagerButton}>
          Добавить
        </Button>
      </div>
      <Table dataSource={managers} columns={columns} rowKey={(item) => item.id} pagination={false} />
      <Pagination
        className="mt-20"
        onChange={onPaginationChanged}
        current={pagination.page}
        pageSize={pagination.perPage}
        total={pagination.totalCount}
      />
    </div>
  );
}

export default ManagersTable;
