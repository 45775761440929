import axios from 'utils/axios';
// import { mockedAxios as axios } from 'utils/axios';

type Params = {
  pointId: number;
  name: string;
};

const createCategory = async (data: Params) => axios.post(`/modifier_categories`, data);

export default createCategory;
