import axios from 'utils/axios';

type Payload = {
  email: string;
  firstName: string;
  lastName: string;
};

const inviteOwner = async (id: number, data: Payload) => axios.post<null>(`/points/${id}/set_user`, data);

export default inviteOwner;
