import React, { useEffect, useMemo, useState } from 'react';
import { Button, Tree } from 'antd';
import type { TreeProps } from 'antd/lib/tree';
import { useSelector } from 'react-redux';

import { selectMenu } from 'redux/menu/selectors';

import css from './StopList.module.scss';
import { selectIikoEnabled } from '../../../redux/user/selectors';

type Props = {
  currentStopList: number[];
  onSelect: (productId: number, action: 'remove') => void;
  clearList: () => void;
};

const StopList = ({ currentStopList, onSelect, clearList }: Props) => {
  const iikoEnabled = useSelector(selectIikoEnabled);

  const menu = useSelector(selectMenu);
  const [expandedKeys, setExpandedKeys] = useState<number[]>([]);

  useEffect(() => {
    setExpandedKeys(menu.map((category) => category.categoryId));
  }, [menu]);

  const treeData: TreeProps['treeData'] = useMemo(() => {
    const data: TreeProps['treeData'] = [];

    menu.forEach((category) => {
      const stopListCategoryItems = category.items.filter((dish) => currentStopList.includes(dish.id));

      if (stopListCategoryItems.length === 0) {
        return undefined;
      }

      return stopListCategoryItems.forEach((item) =>
        data.push({
          title: item.name,
          key: item.id,
          checkable: true,
          selectable: false
        })
      );
    });

    return data;
  }, [menu, currentStopList]);

  const onCheck: TreeProps['onCheck'] = (_, checkedItem) => {
    onSelect(checkedItem.node.key as number, 'remove');
  };

  return (
    <div className={css.container}>
      <div>Добавляйте блюда в стоп-лист и в меню для клиента.</div>
      <div className="mb-20">Они будут временно недоступны.</div>
      <div className={css.info}>
        <span className={css.dishesNumber}>Выбрано блюд: {currentStopList.length}</span>
        {!iikoEnabled && (
          <Button type="link" onClick={clearList}>
            Удалить все
          </Button>
        )}
      </div>

      {currentStopList.length > 0 && (
        <Tree
          expandedKeys={expandedKeys}
          checkedKeys={currentStopList}
          defaultExpandAll
          checkable={!iikoEnabled}
          checkStrictly
          treeData={treeData}
          onCheck={onCheck}
        />
      )}
    </div>
  );
};

export default StopList;
