import mock from './mockAdapter';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mockedAdminToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyLCJyb2xlIjoiYWRtaW4ifQ.HEvMnFJ5dU18e-VvYEBVXvoY3lsYxf-Onel3RCfb0Bc';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mockedManagerToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyLCJyb2xlIjoibWFuYWdlciJ9.obL8gPU-Afm7n9eAZb8kLKdhEO7IdGQdG_SpWGOt0HI';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mockedOwnerToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyLCJyb2xlIjoib3duZXIifQ.pJq5rJQtlKzL5E820tmSvw-RBYPTiN2DYqhLZg11Lmg';

const mockedToken = mockedOwnerToken;

// auth/login
mock
  .onPost('/login')
  .replyOnce(400, {
    errorMessage: 'Invalid credentials'
  })
  .onPost('/login')
  .reply(200, {
    accessToken: mockedToken,
    refreshToken: mockedToken
  });

// auth/reset
mock
  .onPost('/reset')
  .replyOnce(400, {
    errorMessage: 'Invalid token'
  })
  .onPost('/reset')
  .reply(200, {
    accessToken: mockedToken,
    refreshToken: mockedToken
  });

// auth/recover_password
mock
  .onPost('/forgot_password')
  .replyOnce(400, {
    errorMessage: 'Internal server error'
  })
  .onPost('/forgot-password')
  .reply(200);

// refresh token if everything is ok
mock.onPost('/auth/refresh').reply(200, {
  accessToken: mockedToken,
  refreshToken: mockedToken
});

mock.onGet('/check_token').reply((config) => {
  const { token } = config.params;
  if (token === 'invalid') {
    return [401, { errorMessage: 'Invalid token' }];
  }

  return [200];
});

// Uncomment to test failed refresh token
// mock.onPost('/auth/refresh').reply(403);
