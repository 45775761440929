import React, { useCallback, useEffect, useState } from 'react';
import { Divider, notification, Spin } from 'antd';

import { PagePreloader } from 'components';
import { selectState, selectPagination } from 'redux/managers/selectors';
import { useAppSelector, useAppThunkDispatch } from 'utils/hooks';
import { fetchManagers } from '../../redux/managers/actions';
import ManagersTable from './ManagersTable';
import CreateManagerModal from './CreateManagerModal';

// import css from './AdminPage.module.scss';

function AdminPage() {
  const dispatch = useAppThunkDispatch();
  const { page } = useAppSelector(selectPagination);
  const { isLoading, loaded } = useAppSelector(selectState);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const getAllManagers = useCallback(async () => {
    try {
      await dispatch(fetchManagers(page)).unwrap();
    } catch (error) {
      if (typeof error === 'string') {
        notification.error({ message: error });
      }
    }
  }, [dispatch, page]);

  const closeModalHandler = useCallback(() => {
    setIsModalOpened(false);
  }, []);

  const addManagerButtonClickHandler = () => {
    setIsModalOpened(true);
  };

  useEffect(() => {
    getAllManagers();
  }, [getAllManagers]);

  if (!loaded) {
    return <PagePreloader />;
  }

  return (
    <div className="m-20">
      <h2>Управление менеджерами</h2>
      <Divider />
      <div>
        <Spin spinning={isLoading}>
          <ManagersTable addManager={addManagerButtonClickHandler} />
        </Spin>
      </div>
      <CreateManagerModal isOpened={isModalOpened} onClose={closeModalHandler} onSuccess={getAllManagers} />
    </div>
  );
}

export default AdminPage;
