import axios from 'utils/axios';
// import { mockedAxios as axios } from 'utils/axios';

import { ModifierCtegory } from 'entities/modifiers';

type ModifierCategoriesResponse = {
  modifierCategories: ModifierCtegory[];
};

const getModifierCategories = async (pointSlug: string) =>
  axios.get<ModifierCategoriesResponse>(`/modifier_categories`, {
    params: {
      pointSlug,
      page: 1,
      perPage: 25
    }
  });

export default getModifierCategories;
