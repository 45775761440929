import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Spin, notification, Button } from 'antd';
import { useFormik, FormikConfig } from 'formik';
import * as yup from 'yup';

import { modifiersApi } from 'api';
import { useBlockerWithModal, BlockModalConfig } from 'utils/navigation';
import { useAppThunkDispatch, useFormValidatedOnce } from 'utils/hooks';
import { fetchAllModifiers } from 'redux/modifiers/actions';
import { selectOwnerPointId } from 'redux/user/selectors';
import errorMessage from 'utils/validation/validationErrorMessages';
import notificationMessages from 'utils/notificationMessages';
import { ErrorMessageLabel, MenuSectionTitle } from 'components';
import RemoveCategoryModal from './RemoveCategoryModal';

import css from './SubmitModifierCategoryContainer.module.scss';

const blockModalConfig: BlockModalConfig = {
  title: 'Вы не сохранили категорию',
  description: 'Уходя со страницы вы не сохраните изменения в категории. Продолжить?',
  onOkText: 'Продолжить',
  onCancelText: 'Отмена'
};

const formValidationSchema = yup
  .object({
    name: yup.string().required(errorMessage.common.isEmpty('Название'))
  })
  .required();

type FormData = {
  name: string;
};

type Params = {
  id: string | undefined;
};

type SubmitFormData = {
  name: string;
};

const initialValues: FormData = {
  name: ''
};

const SubmitModifierCategoryContainer = () => {
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  const pointId = useSelector(selectOwnerPointId)!;
  const params = useParams<Params>();
  const categoryId = parseInt(params.id || '0', 10);
  const [categoryIdToRemove, setCategoryIdToRemove] = useState<number | null>(null);

  const onSubmit: FormikConfig<SubmitFormData>['onSubmit'] = useCallback(
    (data: FormData, { resetForm }) =>
      (categoryId
        ? modifiersApi.updateCategory(categoryId, {
            ...data,
            pointId
          })
        : modifiersApi.createCategory({
            ...data,
            pointId
          })
      )
        .then(() => {
          notification.success({
            message: categoryId
              ? notificationMessages.category.update.success()
              : notificationMessages.category.create.success()
          });
          resetForm({ values: initialValues });
          setTimeout(() => {
            navigate('/dashboard/modifiers');
            dispatch(fetchAllModifiers());
          }, 20);
        })
        .catch((error) => {
          notification.error({
            message:
              error.response.status === 400
                ? notificationMessages.modifier.create.failure()
                : notificationMessages.defaultError()
          });
        }),
    [categoryId, dispatch, pointId, navigate]
  );

  const { setSubmitCount, validatedOnce } = useFormValidatedOnce();

  const formik = useFormik<FormData>({
    initialValues,
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: validatedOnce,
    onSubmit
  });

  setSubmitCount(formik.submitCount);

  useBlockerWithModal(blockModalConfig, formik.dirty);

  const { resetForm, setSubmitting } = formik;

  useEffect(() => {
    let shouldOmitResponse = false;

    if (categoryId) {
      setSubmitting(true);
      modifiersApi
        .getCategory(categoryId)
        .then((res) => {
          if (!shouldOmitResponse) {
            if (res.data.isDefault) {
              navigate('/dashboard/modifiers');
            } else {
              resetForm({
                values: {
                  name: res.data.name
                }
              });
            }
          }
        })
        .catch(() => {
          notification.error({ message: notificationMessages.category.get.failure(categoryId) });
          navigate('/dashboard/modifiers');
        });
    } else {
      resetForm({
        values: initialValues
      });
    }

    return () => {
      shouldOmitResponse = true;
    };
  }, [categoryId, resetForm, setSubmitting, navigate]);

  const onDeleteCategory = useCallback(() => {
    setCategoryIdToRemove(categoryId);
  }, [categoryId]);

  const onDeleteCategoryModalClosed = useCallback(() => {
    resetForm();
    setCategoryIdToRemove(null);
    setTimeout(() => {
      navigate('/dashboard/modifiers');
      dispatch(fetchAllModifiers());
    }, 50);
  }, [dispatch, navigate, resetForm]);

  return (
    <div className={css.submitCategoryContainer}>
      <Spin spinning={formik.isSubmitting}>
        <Form
          name="create-category"
          className={css.submitCategoryForm}
          layout="vertical"
          autoComplete="off"
          onFinish={formik.submitForm}
        >
          <MenuSectionTitle
            title={categoryId ? 'Изменить категорию' : 'Добавить категорию'}
            onButtonClick={() => navigate('/dashboard/modifiers')}
          />

          <Form.Item label="Название категории" validateStatus={formik.errors.name ? 'error' : undefined}>
            <Input placeholder="Введите название категории" {...formik.getFieldProps('name')} />
            <ErrorMessageLabel message={formik.errors.name} />
          </Form.Item>

          <div>
            <Button type="primary" htmlType="submit" disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
              {categoryId ? 'Обновить' : 'Создать'}
            </Button>
            {!!categoryId && (
              <Button type="primary" danger onClick={onDeleteCategory} className="ml-12">
                Удалить
              </Button>
            )}
          </div>
        </Form>

        <RemoveCategoryModal categoryId={categoryIdToRemove} onClose={onDeleteCategoryModalClosed} />
      </Spin>
    </div>
  );
};

export default SubmitModifierCategoryContainer;
