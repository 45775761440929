import React from 'react';
import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';

import css from './PagePreloader.module.scss';

type Props = SpinProps;

const PagePreloader = (props: Props) => (
  <div className={css.container}>
    <Spin size="large" {...props} />
  </div>
);

export default PagePreloader;
