import axios from 'utils/axios';
// import { mockedAxios as axios } from 'utils/axios';

type SubmitData = {
  name: string;
  price: number;
};

const createModifier = async (data: SubmitData) => axios.post<null>(`/modifiers`, data);

export default createModifier;
