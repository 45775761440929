import React, { useCallback } from 'react';
import { Form, Select } from 'antd';
import { useFormikContext } from 'formik';
import { OptionProps } from 'antd/lib/select';

import { useSelector } from 'react-redux';
import { uniq, without } from 'lodash';
import { CheckOutlined } from '@ant-design/icons';

import { selectIikoEnabled } from 'redux/user/selectors';
import { useAppSelector } from 'utils/hooks';
import { selectModifierCategories, selectModifiers } from 'redux/modifiers/selectors';
import type { SubmitFormData } from './SubmitDishContainer';
import css from './ModifiersSelector.module.scss';
import { getFormContainer } from './utils';

const ModifiersSelector = () => {
  const iikoEnabled = useSelector(selectIikoEnabled);

  const formik = useFormikContext<SubmitFormData>();
  const modifierCategories = useAppSelector(selectModifierCategories);
  const modifiers = useAppSelector(selectModifiers);

  const { values, setFieldValue } = formik;
  // Question to BE: Why this is modifierIds, not modifiersIds
  const { modifierIds } = values;

  const hasAllItemsFromCategory = useCallback(
    (ids: number[]) => ids.filter((modId) => !modifierIds.includes(modId)).length === 0,
    [modifierIds]
  );

  const onCategoryClick = useCallback(
    (categoryId: number) => {
      const categoryModifiersIds = modifierCategories.find((cat) => cat.id === categoryId)!.items;

      if (!hasAllItemsFromCategory(categoryModifiersIds)) {
        setFieldValue('modifierIds', uniq([...modifierIds, ...categoryModifiersIds]));
      } else {
        setFieldValue('modifierIds', without(modifierIds, ...categoryModifiersIds));
      }
    },
    [modifierCategories, hasAllItemsFromCategory, setFieldValue, modifierIds]
  );

  const filterOption = useCallback((inputValue: string, option: OptionProps | undefined): boolean => {
    const lowerInput = inputValue.toLowerCase();
    if (!option) {
      return false;
    }

    if (option.key.includes('group')) {
      return option.searchLabel.toLowerCase().includes(lowerInput);
    }

    return option.label.toLowerCase().includes(lowerInput);
  }, []);

  return (
    <>
      <h4 className="pt-12">Модификаторы к блюду</h4>
      <Form.Item label="Можно не указывать модификаторы">
        <Select
          {...formik.getFieldProps('modifierIds')}
          mode="multiple"
          disabled={iikoEnabled}
          placement="topLeft"
          optionFilterProp="label"
          placeholder="Выберите одиночный модификатор или группу модификаторов"
          getPopupContainer={getFormContainer}
          onChange={(nextValue) => {
            formik.setFieldValue('modifierIds', nextValue);
          }}
          filterOption={filterOption}
        >
          {modifierCategories.map((category) => {
            if (!category.items.length) {
              return null;
            }

            return (
              <Select.OptGroup
                key={`group.${category.id}`}
                searchLabel={category.name}
                label={
                  <div className="relative">
                    {category.name}
                    <div className={css.hiddenClickableOverlay} onClick={() => onCategoryClick(category.id)} />
                    {hasAllItemsFromCategory(category.items) && (
                      <div className={css.categoryCheckmark}>
                        <CheckOutlined />
                      </div>
                    )}
                  </div>
                }
              >
                {category.items.map((modifierId) => (
                  <Select.Option key={modifierId} value={modifierId} label={modifiers[modifierId].name}>
                    {modifiers[modifierId].name}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
};

export default ModifiersSelector;
