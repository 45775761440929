import { FlatModifiersMap, ModifierCtegory } from 'entities/modifiers';
import { getErrorMessage } from 'utils/getErrorMessage';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { modifiersApi } from 'api';
import { handleError } from '../../utils/axios';
import { AsyncThunkConfig } from '../../utils/redux';
import { selectOwnerPointSlug } from '../user/selectors';

type ModifiersResponse = {
  modifierCategories: ModifierCtegory[];
  modifiers: FlatModifiersMap;
};

const fetchAllModifiers = createAsyncThunk<ModifiersResponse, void, AsyncThunkConfig>(
  'modifiers/fetchAllModifiers',
  async (_, { rejectWithValue, getState }) => {
    try {
      const pointSlug = selectOwnerPointSlug(getState());
      const {
        data: { modifierCategories }
      } = await modifiersApi.getModifierCategories(pointSlug!);
      const {
        data: { modifiers }
      } = await modifiersApi.getModifiers(pointSlug!);

      const modifiersMap = modifiers.reduce((acc, item) => ({ ...acc, [item.id]: item }), {} as FlatModifiersMap);

      return { modifierCategories, modifiers: modifiersMap };
    } catch (e) {
      const { axiosError, error } = handleError(e);
      if (axiosError) {
        return rejectWithValue(getErrorMessage(axiosError));
      }
      return rejectWithValue(error?.message);
    }
  }
);

export {
  // eslint-disable-next-line import/prefer-default-export
  fetchAllModifiers
};
