import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import css from './Preloader.module.scss';

function Preloader() {
  return (
    <div className={css.preloaderContainer}>
      <Spin indicator={<LoadingOutlined spin />} tip="Loading" />
    </div>
  );
}

export default Preloader;
