import { GetAllManagersResponse } from 'api/managersApi/getAllManagers';
import { Manager } from 'entities/managers';
import mock from './mockAdapter';

const getMockedManagerList = (id: number): Manager => ({
  id,
  email: `test-manager-${id}@test.com`,
  firstName: `Василий`,
  lastName: `Иванович ${id}`
});

const allManagersSuccessResponse: GetAllManagersResponse = {
  page: 1,
  perPage: 3,
  totalCount: 3,
  totalPages: 1,
  items: [1, 2, 3].map(getMockedManagerList)
};

mock.onGet('/managers').reply(200, allManagersSuccessResponse);

mock.onPost('/managers').replyOnce(401).onPost('/managers').reply(200, {
  id: 10
});
