import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet, useMatch } from 'react-router-dom';
import { Spin, Button, Divider, notification } from 'antd';
import cn from 'classnames';

import { selectModifiersLoadingFlags } from 'redux/modifiers/selectors';
import { useAppThunkDispatch } from 'utils/hooks';
import { fetchAllModifiers } from 'redux/modifiers/actions';
import { selectIikoEnabled } from 'redux/user/selectors';
import { MenuSectionTitle, PagePreloader } from 'components';
import ModifierList from './ModifierList';

import css from './ModifiersPage.module.scss';

const ModifiersPage = () => {
  const dispatch = useAppThunkDispatch();
  const match = useMatch('/dashboard/modifiers');
  const { loading, loaded } = useSelector(selectModifiersLoadingFlags);
  const iikoEnabled = useSelector(selectIikoEnabled);

  const getModifiers = useCallback(async () => {
    try {
      await dispatch(fetchAllModifiers()).unwrap();
    } catch (error: any) {
      notification.error({ message: error });
    }
  }, [dispatch]);

  useEffect(() => {
    getModifiers();
  }, [getModifiers]);

  if (!loaded) {
    return <PagePreloader />;
  }

  return (
    <>
      <div
        className={cn(css.modifiersContainer, {
          [css.modifiersContainerHidden]: !match
        })}
      >
        <Spin spinning={loading}>
          <div className="m-20">
            <MenuSectionTitle title="Модификаторы" />
            <Divider />
            <div className={css.modifiersButtonsContainer}>
              {!iikoEnabled && (
                <Link to="/dashboard/modifiers/create-modifier">
                  <Button type="primary">Добавить модификатор</Button>
                </Link>
              )}
              {!iikoEnabled && (
                <Link to="/dashboard/modifiers/create-category">
                  <Button type="primary">Добавить категорию</Button>
                </Link>
              )}
            </div>
            <ModifierList />
          </div>
        </Spin>
      </div>
      <Outlet />
    </>
  );
};

export default ModifiersPage;
