import axios from 'utils/axios';
import { NewManagerData } from '../../entities/managers';

export type CreateManagerResponse = {
  id: number;
};

const createManager = async (data: NewManagerData) => axios.post<CreateManagerResponse>('/admin/create_manager', data);

export default createManager;
