import { ForgotPasswordRequestData } from 'entities/auth';
import axios from 'utils/axios';

const forgotPassword = async (data: ForgotPasswordRequestData): Promise<null> =>
  axios.post('/forgot_password', {
    ...data,
    email: data.email.toLowerCase()
  });

export default forgotPassword;
