import Logo from 'components/Logo';
import React from 'react';
import { Card } from 'antd';
import css from './AuthLayout.module.scss';

type Props = {
  children: React.ReactNode;
};

const AuthLayout = ({ children }: Props) => (
  <div className={css.pageWrapper}>
    <Card className={css.card}>
      <div className={css.logoContainer}>
        <Logo />
      </div>
      {children}
    </Card>
  </div>
);

export default AuthLayout;
