import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';

const selectState = (state: RootState) => state.auth;

const selectIsAuthorized = createSelector([selectState], (state) => state.isAuthorized);

const selectAuthLoading = createSelector([selectState], (state) => state.loading);

const selectRole = createSelector([selectState], (state) => state.role!);

export { selectAuthLoading, selectIsAuthorized, selectRole };
