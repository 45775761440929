import { CategoryById, Dish, Menu } from 'entities/menu';
import mock from './mockAdapter';

const mockedMenu: Menu = {
  pointName: "Your Mom's Hot Buns",
  menu: [
    {
      categoryId: 101,
      isHidden: false,
      categoryName: 'Супы',
      items: [
        {
          id: 11,
          name: 'Уха',
          isHidden: false,
          weight: '300',
          ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
          sizes: [{ id: 142, name: 'Стандарт', isDefault: true, price: 450 }],
          description:
            'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
          image:
            'https://images.unsplash.com/photo-1560684352-8497838a2229?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1128'
        },
        {
          id: 12,
          name: 'Солянка',
          isHidden: false,
          weight: '300',
          ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
          sizes: [
            { id: 140, name: 'Стандарт', isDefault: true, price: 420 },
            { id: 141, name: 'На двоих', isDefault: false, price: 700 }
          ],
          description:
            'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
          image: null
        },
        {
          id: 20,
          name: 'Грибной крем-суп',
          isHidden: false,
          weight: '300',
          ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
          sizes: [{ id: 138, name: 'Стандарт', isDefault: true, price: 300 }],
          description:
            'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
          image:
            'https://images.unsplash.com/photo-1610725663727-08695a1ac3ff?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064'
        },
        {
          id: 21,
          name: 'Борщ',
          isHidden: false,
          weight: '300',
          ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
          sizes: [{ id: 137, name: 'Стандарт', isDefault: true, price: 300 }],
          description:
            'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
          image:
            'https://images.unsplash.com/photo-1611068120813-eca5a8cbf793?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774'
        },
        {
          id: 22,
          name: 'Томатный крем-суп',
          isHidden: false,
          weight: '300',
          ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
          sizes: [{ id: 136, name: 'Стандарт', isDefault: true, price: 350 }],
          description:
            'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
          image:
            'https://images.unsplash.com/photo-1620791144170-8a443bf37a33?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064'
        },
        {
          id: 23,
          name: 'Минестроне',
          isHidden: false,
          weight: '300',
          ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
          sizes: [{ id: 135, name: 'Стандарт', isDefault: true, price: 350 }],
          description:
            'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
          image:
            'https://images.unsplash.com/photo-1518710339019-eee82fe8d97f?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774'
        },
        {
          id: 24,
          name: 'Харчо',
          isHidden: false,
          weight: '300',
          ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
          sizes: [{ id: 134, name: 'Стандарт', isDefault: true, price: 350 }],
          description:
            'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
          image: null
        }
      ]
    },
    {
      categoryId: 102,
      isHidden: false,
      categoryName: 'Мясо',
      items: [
        {
          id: 13,
          name: 'Стейк Рибай',
          isHidden: false,
          weight: '300',
          ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
          sizes: [
            { id: 132, name: 'Стандарт', isDefault: true, price: 950 },
            { id: 133, name: 'Большой', isDefault: false, price: 1500 }
          ],
          description:
            'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
          image:
            'https://images.unsplash.com/photo-1555939594-58d7cb561ad1?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774'
        },
        {
          id: 14,
          name: 'Медальоны из телятины',
          isHidden: false,
          weight: '300',
          ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
          sizes: [{ id: 131, name: 'Стандарт', isDefault: true, price: 550 }],
          description:
            'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
          image:
            'https://images.unsplash.com/photo-1555939594-58d7cb561ad1?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774'
        }
      ]
    },
    {
      categoryId: 103,
      isHidden: false,
      categoryName: 'Салаты',
      items: [
        {
          id: 15,
          name: 'Греческий',
          isHidden: false,
          weight: '300',
          ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
          sizes: [
            { id: 129, name: 'Стандарт', isDefault: true, price: 320 },
            { id: 130, name: 'Большой', isDefault: false, price: 500 }
          ],
          description:
            'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
          image:
            'https://images.unsplash.com/photo-1600335895229-6e75511892c8?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774'
        },
        {
          id: 16,
          name: 'Цезарь с курицей',
          isHidden: false,
          weight: '300',
          ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
          sizes: [{ id: 128, name: 'Стандарт', isDefault: true, price: 350 }],
          description:
            'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
          image: null
        },
        {
          id: 19,
          name: 'Цезарь с креветками',
          isHidden: false,
          weight: '300',
          ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
          sizes: [{ id: 127, name: 'Стандарт', isDefault: true, price: 420 }],
          description:
            'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
          image:
            'https://images.unsplash.com/photo-1600335895229-6e75511892c8?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774'
        }
      ]
    },
    {
      categoryId: 104,
      isHidden: false,
      categoryName: 'Десерты',
      items: [
        {
          id: 17,
          name: 'Медовик',
          isHidden: false,
          weight: '300',
          ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
          sizes: [{ id: 126, name: 'Стандарт', isDefault: true, price: 250 }],
          description:
            'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
          image:
            'https://images.unsplash.com/photo-1563805042-7684c019e1cb?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=654'
        },
        {
          id: 18,
          name: 'Блинчики',
          isHidden: false,
          weight: '300',
          ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
          sizes: [
            { id: 123, name: 'Два блинчика', isDefault: true, price: 150 },
            { id: 124, name: 'Три блинчика', isDefault: false, price: 210 },
            { id: 125, name: 'Четыре блинчика', isDefault: false, price: 300 }
          ],
          description:
            'So delicious So delicious So delicious So delicious So delicious So delicious So delicious So delicious! Mmmmmm',
          image:
            'https://images.unsplash.com/photo-1563805042-7684c019e1cb?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=654'
        }
      ]
    }
  ]
};

mock.onGet('/api/v1/products').reply(200, mockedMenu);

const mockedCategory: CategoryById = {
  name: 'Mocked category',
  pointId: 1,
  id: 1,
  isHidden: false
};

const getMockedDish = (id: number): Dish | {} =>
  mockedMenu.menu.reduce((acc, category) => {
    const foundItem = category.items.filter((dish) => dish.id === id);

    if (foundItem.length > 0) {
      return { ...foundItem[0] };
    }

    return acc;
  }, {});

mockedMenu.menu.forEach((category) =>
  category.items.forEach((dish) => {
    mock.onGet(`/api/v1/products/${dish.id}`).reply(200, getMockedDish(dish.id));
  })
);

mock.onGet(/\/menu_categories\/\d+/).reply(200, mockedCategory);
mock.onPost('/menu_categories').reply(200);
mock.onPut(/\/menu_categories\/\d+/).reply(200);
mock.onDelete(/\/menu_categories\/\d+/).reply(200);

mock.onPost('/products').reply(200, null);
mock.onPut(/\/products\/\d+/).reply(200);
mock.onDelete(/\/products\/\d+/).reply(200);
