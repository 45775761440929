import React, { useEffect } from 'react';
import { Form, Input, Modal, notification } from 'antd';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { NewManagerData } from 'entities/managers';
import { ErrorMessageLabel } from 'components';
import { useFormValidatedOnce } from 'utils/hooks';
import { emailFieldValidation } from 'utils/validation/fields';
import errorMessages from 'utils/validation/validationErrorMessages';
import { managersApi } from 'api';

import css from './CreateManagerModal.module.scss';

const formValidationSchema = yup
  .object({
    email: emailFieldValidation,
    firstName: yup.string().required(errorMessages.common.isEmpty('Имя')),
    lastName: yup.string().required(errorMessages.common.isEmpty('Фамилия'))
  })
  .required();

type Props = {
  isOpened: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

function CreateManagerModal({ isOpened, onClose, onSuccess }: Props) {
  const { setSubmitCount, validatedOnce } = useFormValidatedOnce();

  const onSubmit = async (data: NewManagerData) => {
    try {
      await managersApi.createManager(data);

      notification.success({
        message: 'Менеджер успешно создан'
      });
      onClose();
      onSuccess();
    } catch (error) {
      notification.error({
        message: 'Что-то пошло не так'
      });
    }
  };

  const formik = useFormik<NewManagerData>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: ''
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: validatedOnce,
    onSubmit
  });

  setSubmitCount(formik.submitCount);

  const { resetForm } = formik;

  useEffect(() => {
    if (!isOpened) {
      resetForm();
    }
  }, [isOpened, resetForm]);

  return (
    <div>
      <Modal
        title="Добавить менеджера"
        closable={!formik.isSubmitting}
        maskClosable={!formik.isSubmitting}
        cancelButtonProps={{
          disabled: formik.isSubmitting
        }}
        confirmLoading={formik.isSubmitting}
        visible={isOpened}
        onCancel={onClose}
        cancelText="Отменить"
        okText="Добавить"
        okButtonProps={{
          onClick: () => formik.handleSubmit()
        }}
      >
        <Form className={css.form} name="create-manager" autoComplete="off" onFinish={formik.submitForm}>
          <Form.Item validateStatus={formik.errors.email ? 'error' : undefined}>
            <Input placeholder="Email" {...formik.getFieldProps('email')} />
            <ErrorMessageLabel message={formik.errors.email} />
          </Form.Item>
          <Form.Item validateStatus={formik.errors.lastName ? 'error' : undefined}>
            <Input placeholder="Фамилия" {...formik.getFieldProps('lastName')} />
            <ErrorMessageLabel message={formik.errors.lastName} />
          </Form.Item>
          <Form.Item validateStatus={formik.errors.firstName ? 'error' : undefined}>
            <Input placeholder="Имя" {...formik.getFieldProps('firstName')} />
            <ErrorMessageLabel message={formik.errors.firstName} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default React.memo(CreateManagerModal);
