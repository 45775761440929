import { createReducer } from '@reduxjs/toolkit';
import { StoreState } from './types';
import { fetchFullMenu } from './actions';

const initialState: StoreState = {
  menu: {
    pointName: '',
    menu: []
  },
  loading: false,
  loaded: false
};

const menuReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchFullMenu.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchFullMenu.fulfilled, (state, action) => {
      state.menu = action.payload;
      state.loading = false;
      state.loaded = true;
    })
    .addCase(fetchFullMenu.rejected, (state) => {
      state.loading = false;
      state.loaded = false;
    });
});

export default menuReducer;
