import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik, FormikConfig } from 'formik';
import * as yup from 'yup';
import { Form, Input, Select, DatePicker, Button, Spin, notification, Divider } from 'antd';
import moment from 'moment';

import { pointsApi, ratesApi } from 'api';
import { PointFormData, PointFormSubmitData } from 'entities/points';
import { Rate } from 'entities/rates';
import errorMessages from 'utils/validation/validationErrorMessages';
import { useFormValidatedOnce } from 'utils/hooks';
import notificationMessages from 'utils/notificationMessages';
import { BlockModalConfig, useBlockerWithModal } from 'utils/navigation';
import { ErrorMessageLabel, MenuSectionTitle } from 'components';

import css from './PointPage.module.scss';

const blockModalConfig: BlockModalConfig = {
  title: 'Вы не сохранили точку',
  description: 'Уходя со страницы вы не сохраните изменения в точке. Продолжить?',
  onOkText: 'Продолжить',
  onCancelText: 'Отмена'
};

const initialValues: PointFormData = {
  name: '',
  address: '',
  rateId: null,
  expiresAt: null
};

const formValidationSchema = yup
  .object({
    name: yup.string().required(errorMessages.common.isEmpty('Название')),
    address: yup.string().required(errorMessages.common.isEmpty('Адрес')),
    rateId: yup
      .number()
      .required(errorMessages.common.isEmpty('Тип лицензии'))
      .typeError(errorMessages.common.isEmpty('Тип лицензии')),
    expiresAt: yup
      .string()
      .required(errorMessages.common.isEmpty('Срок действия'))
      .typeError(errorMessages.common.isEmpty('Срок действия'))
  })
  .required();

type Params = {
  id: string | undefined;
};

const PointPage = () => {
  const [rates, setRates] = useState<Rate[]>([]);
  const params = useParams<Params>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const isNew = !params.id;
  const pointId = isNew ? undefined : parseInt(params.id, 10);

  useEffect(() => {
    ratesApi
      .getRates()
      .then((res) => {
        setRates(res.data.items);
      })
      .catch(() => notification.error({ message: notificationMessages.rates.get.failure() }));
  }, []);

  const onSubmit: FormikConfig<PointFormData>['onSubmit'] = useCallback(
    (data, { resetForm }) => {
      const submitData = {
        ...data,
        expiresAt: +`${+data.expiresAt!}000000`,
        id: pointId
      } as PointFormSubmitData;
      if (isNew) {
        return pointsApi
          .createPoint(submitData)
          .then(() => {
            notification.success({
              message: notificationMessages.point.create.success(data.name)
            });
            resetForm({
              values: initialValues
            });
            setTimeout(() => {
              navigate('/dashboard/points');
            }, 50);
          })
          .catch(() => {
            notification.error({
              message: notificationMessages.defaultError()
            });
          });
      }
      return pointsApi
        .updatePoint(pointId!, submitData)
        .then(() => {
          notification.success({
            message: notificationMessages.point.update.success(data.name)
          });
          resetForm({
            values: initialValues
          });
          setTimeout(() => {
            navigate('/dashboard/points');
          }, 20);
        })
        .catch(() => {
          notification.error({
            message: notificationMessages.defaultError()
          });
        });
    },
    [pointId, isNew, navigate]
  );

  const { setSubmitCount, validatedOnce } = useFormValidatedOnce();

  const formik = useFormik<PointFormData>({
    initialValues,
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: validatedOnce,
    onSubmit
  });

  const { resetForm } = formik;

  setSubmitCount(formik.submitCount);

  useBlockerWithModal(blockModalConfig, formik.dirty);

  useEffect(() => {
    if (!isNew) {
      setLoading(true);
      pointsApi
        .getPoint(pointId!)
        .then((point) => {
          resetForm({
            values: {
              ...point.data,
              expiresAt: moment(point.data.expiresAt)
            }
          });
          setLoading(false);
        })
        .catch(() => {
          notification.error({
            message: notificationMessages.point.get.failure(pointId!)
          });
          navigate('/dashboard/points');
        });
    }
  }, [isNew, pointId, resetForm, navigate]);

  return (
    <Spin spinning={formik.isSubmitting || loading}>
      <Form className={css.form} layout="vertical" autoComplete="off" onFinish={formik.submitForm}>
        <MenuSectionTitle
          title={isNew ? 'Создать точку' : 'Изменить точку'}
          onButtonClick={() => navigate('/dashboard/points')}
        />

        <Divider />
        <Form.Item label="Название" validateStatus={formik.errors.name ? 'error' : undefined}>
          <Input placeholder="Введите название точки" {...formik.getFieldProps('name')} />
          <ErrorMessageLabel message={formik.errors.name} />
        </Form.Item>
        <Form.Item label="Адрес" validateStatus={formik.errors.address ? 'error' : undefined}>
          <Input placeholder="Введите адрес точки" {...formik.getFieldProps('address')} />
          <ErrorMessageLabel message={formik.errors.address} />
        </Form.Item>
        <Form.Item label="Тариф" validateStatus={formik.errors.rateId ? 'error' : undefined}>
          <Select
            {...formik.getFieldProps('rateId')}
            placeholder="Выберите тариф"
            onChange={(what) => {
              formik.setFieldValue('rateId', what);
            }}
          >
            {rates.map((subscription) => (
              <Select.Option key={subscription.id} value={subscription.id}>
                {subscription.name}
              </Select.Option>
            ))}
          </Select>
          <ErrorMessageLabel message={formik.errors.rateId} />
        </Form.Item>
        <Form.Item label="Действует до" validateStatus={formik.errors.expiresAt ? 'error' : undefined}>
          <DatePicker
            name="expiresAt"
            showToday={false}
            placeholder="Выберите дату окончания лицензии"
            value={formik.values.expiresAt}
            className="full-width"
            disabledDate={(date) => date && date.isBefore(moment().subtract(2, 'day'))}
            onChange={(date) => {
              if (date) {
                formik.setFieldValue('expiresAt', date);
              }
            }}
          />
          <ErrorMessageLabel message={formik.errors.expiresAt} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={formik.isSubmitting || !formik.isValid}>
            {isNew ? 'Создать' : 'Сохранить'}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default PointPage;
