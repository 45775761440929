import React, { useMemo } from 'react';
import css from './MenuPreview.module.scss';
import { Dish } from '../../../../entities/menu';
import CardItem from './CardItem';
import ListItem from './ListItem';
import { MenuConfig } from '../../../../entities/points';

const mockedItems: Dish[] = [
  {
    id: 15,
    categoryId: 103,
    name: 'Греческий',
    isHidden: false,
    recommendedProductIds: [],
    modifierIds: [],
    weight: '300',
    ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
    sizes: [
      { id: 140, name: 'Стандарт', isDefault: true, price: 320 },
      { id: 141, name: 'На двоих', isDefault: false, price: 600 }
    ],
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur mattis magna sit amet nulla molestie ornare. Nulla vitae porttitor urna. Pellentesque nec ante vehicula, luctus odio interdum, interdum enim.',
    image: 'https://picsum.photos/400/400'
  },
  {
    id: 16,
    categoryId: 103,
    name: 'Цезарь с курицей',
    isHidden: false,
    recommendedProductIds: [],
    modifierIds: [],
    weight: '350',
    ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
    sizes: [{ id: 142, name: 'Стандарт', isDefault: true, price: 420 }],
    description:
      'Donec iaculis neque sit amet dui consequat, at lobortis nisi finibus. Vivamus ut sapien sit amet mi interdum blandit sed posuere velit',
    image: 'https://picsum.photos/400/400'
  },
  {
    id: 19,
    categoryId: 103,
    name: 'Цезарь с креветками',
    isHidden: false,
    modifierIds: [],
    recommendedProductIds: [],
    weight: '320',
    ingredients: 'Milk, Chocolate, Onion, Garlic, Peanuts, Almond',
    sizes: [{ id: 143, name: 'Стандарт', isDefault: true, price: 500 }],
    description:
      'Nam gravida ex ac felis porttitor dapibus. Curabitur mi nibh, fringilla eu lacus id, eleifend iaculis elit.',
    image: 'https://picsum.photos/400/400'
  }
];

type Props = MenuConfig;

const MenuPreview = ({ menuView, menuIsImageShown, menuIsDescriptionShown, menuGridColsCount }: Props) => {
  const renderedItems = useMemo(() => {
    if (menuView === 'card') {
      return mockedItems.map((dish) => (
        <div
          className={`${css.cardItemWrapper} ${menuGridColsCount === 2 && css.cardItemWrapperTwoColumns}`}
          key={dish.id}
        >
          <CardItem hideImage={!menuIsImageShown} item={dish} hideDescription={!menuIsDescriptionShown} />
        </div>
      ));
    }

    return mockedItems.map((dish) => <ListItem hideDescription={!menuIsDescriptionShown} key={dish.id} item={dish} />);
  }, [menuGridColsCount, menuIsDescriptionShown, menuIsImageShown, menuView]);

  return (
    <div className={`${css.container} ${menuView === 'list' ? css.listViewWrapper : css.cardViewWrapper}`}>
      {renderedItems}
    </div>
  );
};

export default MenuPreview;
