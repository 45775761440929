import { createReducer } from '@reduxjs/toolkit';
import { StoreState } from './types';
import { fetchStopList } from './actions';

const initialState: StoreState = {
  stopList: [],
  loading: false,
  loaded: false
};

const stopListReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchStopList.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchStopList.fulfilled, (state, action) => {
      state.stopList = action.payload;
      state.loading = false;
      state.loaded = true;
    })
    .addCase(fetchStopList.rejected, (state) => {
      state.loading = false;
      state.loaded = false;
    });
});

export default stopListReducer;
