import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../store';

const selectState = (state: RootState) => state.promo;

const selectLoadingState = createSelector(selectState, ({ loading, loaded }) => ({
  loading,
  loaded
}));

const selectPromos = createSelector(selectState, ({ items }) => items);

export { selectLoadingState, selectPromos };
