import axios from 'utils/axios';

type Payload = {
  categories: Array<{
    id: number;
    products: number[];
  }>;
};

const sendNewMenuOrder = async (pointId: number, data: Payload) =>
  axios.post(`products/change_sorting/${pointId}`, data);

export default sendNewMenuOrder;
