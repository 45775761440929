import { mockedAxios as axios } from 'utils/axios';

import { Manager } from '../../entities/managers';

type GetAllManagersParams = {
  page?: number;
  perPage?: number;
};

export type GetAllManagersResponse = {
  page: number;
  perPage: number;
  totalPages: number;
  totalCount: number;
  items: Manager[];
};

const getAllManagers = async (params: GetAllManagersParams = {}) =>
  axios.get<GetAllManagersResponse>('/managers', {
    params
  });

export default getAllManagers;
