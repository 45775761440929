import axios from 'utils/axios';
// import { mockedAxios as axios } from 'utils/axios';

type Params = {
  name: string;
  pointId: number;
};

const updateCategory = async (id: number, data: Params) => axios.put<null>(`/modifier_categories/${id}`, data);

export default updateCategory;
