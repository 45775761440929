import jwtDecode from 'jwt-decode';

import { authApi } from 'api';
import { getAccessToken, getDataFromToken, setAuthTokens, clearAuthTokens, logoutRedirect } from 'utils/auth';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthRequestData, UserRole, AuthToken, ForgotPasswordRequestData } from 'entities/auth';
import { fetchUserInfo } from 'redux/user/actions';
import { handleError } from 'utils/axios';
import { getErrorMessage } from 'utils/getErrorMessage';

const loginAction = createAsyncThunk<
  UserRole,
  AuthRequestData,
  {
    rejectValue?: string;
  }
>('auth/login', async (userData, { rejectWithValue, dispatch }) => {
  try {
    const response = await authApi.login(userData);

    const {
      data: { accessToken, refreshToken }
    } = response;

    setAuthTokens(accessToken, refreshToken);

    await dispatch(fetchUserInfo()).unwrap();

    const { role } = getDataFromToken()!;

    return role;
  } catch (e) {
    const { axiosError, error } = handleError(e);

    if (axiosError) {
      return rejectWithValue(getErrorMessage(axiosError));
    }
    return rejectWithValue(error?.message);
  }
});

type ResetPasswordPayload = {
  password: string;
  token: string;
};

const resetPassword = createAsyncThunk<
  UserRole,
  ResetPasswordPayload,
  {
    rejectValue?: string;
  }
>('auth/resetPassword', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await authApi.resetPassword(payload);

    setAuthTokens(data.accessToken, data.refreshToken);
    const { role } = getDataFromToken()!;

    await dispatch(fetchUserInfo()).unwrap();

    return role;
  } catch (e) {
    const { axiosError, error } = handleError(e);
    if (axiosError) {
      return rejectWithValue(getErrorMessage(axiosError));
    }
    return rejectWithValue(error?.message);
  }
});

const forgotPassword = createAsyncThunk<
  string | undefined,
  ForgotPasswordRequestData,
  {
    rejectValue?: string;
  }
>('auth/forgotPassword', async (payload, { rejectWithValue }) => {
  try {
    await authApi.forgotPassword(payload);

    return undefined;
  } catch (e) {
    const { axiosError, error } = handleError(e);
    if (axiosError) {
      return rejectWithValue(getErrorMessage(axiosError));
    }
    return rejectWithValue(error?.message);
  }
});

const initApp = createAsyncThunk('auth/initApp', async (_, { dispatch }) => {
  try {
    const accessToken = getAccessToken();
    if (!accessToken) {
      return undefined;
    }

    // This is actually a good idea from copilot
    // const {
    //   data: { accessToken: newAccessToken }
    // } = await authApi.refreshToken({ refreshToken: accessToken });
    //
    // localStorage.setItem('accessToken', newAccessToken);
    // const decodedToken = jwtDecode<AuthToken>(newAccessToken);

    const decodedToken = jwtDecode<AuthToken>(accessToken);

    await dispatch(fetchUserInfo()).unwrap();

    return decodedToken.role;
  } catch (e) {
    logoutRedirect();
    return undefined;
  }
});

const logout = createAsyncThunk('auth/logout', async () => {
  clearAuthTokens();
  logoutRedirect();
});

export { loginAction, resetPassword, forgotPassword, initApp, logout };
