import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import { MainLayout, RequireAuth } from './layouts';
import DashboardRouter from './DashboardRouter';
import AuthRouter from './AuthRouter';

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="auth/*" element={<AuthRouter />} />
        <Route
          path="dashboard/*"
          element={
            <RequireAuth>
              <DashboardRouter />
            </RequireAuth>
          }
        />
        <Route path="*" element={<Navigate to="/auth" />} />
      </Route>
      <Route path="*" element={<Navigate to="/auth" />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
