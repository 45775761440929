import axios from 'utils/axios';
import { AuthRequestData, AuthResponseWithTokens } from 'entities/auth';

const login = async (userData: AuthRequestData) =>
  axios.post<AuthResponseWithTokens>('/login', {
    ...userData,
    email: userData.email.toLowerCase()
  });

export default login;
