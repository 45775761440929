import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useAppSelector } from '../../utils/hooks';
import { selectState } from '../../redux/user/selectors';

const SentryWatcher = () => {
  const { firstName, lastName, role, pointId, pointSlug } = useAppSelector(selectState);

  useEffect(() => {
    if (!firstName || !lastName) {
      Sentry.setUser(null);
    } else {
      Sentry.setUser({
        username: `${firstName} ${lastName}`,
        pointId,
        pointSlug,
        role
      });
    }
  }, [firstName, lastName, role, pointId, pointSlug]);

  return null;
};

export default SentryWatcher;
