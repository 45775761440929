import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth/reducer';
import userReducer from './user/reducer';
import pointsReducer from './points/reducer';
import managersReducer from './managers/reducer';
import menuReducer from './menu/reducer';
import promoReducer from './promo/reducer';
import stopListReducer from './stopList/reducer';
import layoutReducer from './layout/reducer';
import tablesReducer from './tables/reducer';
import modifiersReducer from './modifiers/reducer';

const reducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  managers: managersReducer,
  points: pointsReducer,
  menu: menuReducer,
  promo: promoReducer,
  stopList: stopListReducer,
  layout: layoutReducer,
  tables: tablesReducer,
  modifiers: modifiersReducer
});

export default reducer;
