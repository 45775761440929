import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Spin, Divider, notification } from 'antd';

import { fetchPoints } from 'redux/points/actions';
import { selectLoadingState, selectPagination } from 'redux/points/selectors';
import { useAppThunkDispatch } from 'utils/hooks';
import { MenuSectionTitle, PagePreloader } from 'components';
import PointsTable from './PointsTable';

import css from './PointsPage.module.scss';

const PointsPage = () => {
  const dispatch = useAppThunkDispatch();
  const { page } = useSelector(selectPagination);
  const { loaded, isLoading } = useSelector(selectLoadingState);

  const getPoints = useCallback(async () => {
    try {
      await dispatch(fetchPoints(page)).unwrap();
    } catch (error: any) {
      notification.error({ message: error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    getPoints();
  }, [getPoints]);

  if (!loaded) {
    return <PagePreloader />;
  }

  return (
    <div className={css.container}>
      <Spin spinning={isLoading}>
        <MenuSectionTitle title="Управление точками" />
        <Divider />
        <PointsTable />
      </Spin>
    </div>
  );
};

export default PointsPage;
