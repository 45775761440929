import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';

export const selectState = (state: RootState) => state.user;

const selectUsername = createSelector([selectState], (state) => ({
  firstName: state.firstName,
  lastName: state.lastName
}));

const selectUserLoading = createSelector([selectState], ({ isLoading }) => isLoading);

const selectOwnerPoint = createSelector([selectState], ({ point }) => point);
const selectOwnerPointId = createSelector([selectState], ({ pointId }) => pointId);
const selectOwnerPointSlug = createSelector([selectState], ({ pointSlug }) => pointSlug);
const selectIikoEnabled = createSelector([selectState], ({ point }) => Boolean(point?.mode === 'IIKO'));

const selectPointMenuConfig = createSelector([selectState], ({ point }) => point?.menuConfig);

export {
  selectUserLoading,
  selectOwnerPoint,
  selectUsername,
  selectOwnerPointId,
  selectOwnerPointSlug,
  selectIikoEnabled,
  selectPointMenuConfig
};
