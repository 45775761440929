import { createReducer } from '@reduxjs/toolkit';
import { StoreState } from './types';
import { fetchAllModifiers } from './actions';

const initialState: StoreState = {
  categories: [],
  items: [],
  loading: false,
  loaded: false
};

const modifiersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAllModifiers.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchAllModifiers.fulfilled, (state, action) => {
      state.categories = action.payload.modifierCategories;
      state.items = action.payload.modifiers;
      state.loading = false;
      state.loaded = true;
    })
    .addCase(fetchAllModifiers.rejected, (state) => {
      state.loading = false;
      state.loaded = false;
    });
});

export default modifiersReducer;
