import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tree, Input } from 'antd';
import { TreeProps } from 'antd/lib/tree';

import { selectIikoEnabled } from 'redux/user/selectors';
import { selectModifierCategories, selectModifiers } from 'redux/modifiers/selectors';

import css from './ModifierList.module.scss';

type TreeTitleProps = {
  onEdit: () => void;
  title: string;
  hideEdit: boolean;
};

const TreeTitle = ({ title, onEdit, hideEdit }: TreeTitleProps) => (
  <div className={css.treeElement}>
    <span className={css.treeTitle}>{title}</span>
    <div className={css.actionButtons}>
      {!hideEdit && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
          className={css.editButton}
        >
          Изменить
        </span>
      )}
    </div>
  </div>
);

const ModifierList = () => {
  const iikoEnabled = useSelector(selectIikoEnabled);

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const modifiers = useSelector(selectModifiers);
  const modifierCategories = useSelector(selectModifierCategories);
  const navigate = useNavigate();

  useEffect(() => {
    setExpandedKeys(modifierCategories.map((category) => `category-${category.id}`));
  }, [modifierCategories]);

  const onEditModifier = useCallback(
    (id: number) => {
      navigate(`/dashboard/modifiers/edit-modifier/${id}`);
    },
    [navigate]
  );

  const onEditCategory = useCallback(
    (id: number) => {
      navigate(`/dashboard/modifiers/edit-category/${id}`);
    },
    [navigate]
  );

  const treeData: TreeProps['treeData'] = useMemo(() => {
    const data: TreeProps['treeData'] = [];

    modifierCategories.forEach((category) => {
      const categoryItems: TreeProps['treeData'] = [];

      category.items.forEach((modifierId) => {
        const currentModifier = modifiers[modifierId];

        if (
          (searchValue && currentModifier.name.toLowerCase().includes(searchValue.trim().toLowerCase())) ||
          !searchValue.length
        ) {
          categoryItems.push({
            title: (
              <TreeTitle
                title={currentModifier.name}
                onEdit={() => onEditModifier(currentModifier.id)}
                hideEdit={iikoEnabled}
              />
            ),
            key: `modifier-${category.id}-${currentModifier.id}`,
            selectable: false
          });
        }
      });

      return data.push({
        title: (
          <TreeTitle
            title={category.name}
            onEdit={() => onEditCategory(category.id)}
            hideEdit={iikoEnabled || category.isDefault}
          />
        ),
        key: `category-${category.id}`,
        children: categoryItems
      });
    });

    return data;
  }, [iikoEnabled, onEditCategory, onEditModifier, searchValue, modifierCategories, modifiers]);

  return (
    <div className="mt-20 relative">
      <Input.Search
        className="mb-20"
        placeholder="Введите название модификатора"
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
      />

      <Tree
        expandedKeys={expandedKeys}
        className={css.modifiersTreeWrapper}
        onSelect={(_, data) => {
          const toggledNodeKey = String(data.node.key);
          setExpandedKeys((oldKeys) => {
            if (oldKeys.includes(String(toggledNodeKey))) {
              return oldKeys.filter((key) => key !== toggledNodeKey);
            }
            return [...oldKeys, toggledNodeKey];
          });
        }}
        defaultExpandAll
        treeData={treeData}
      />
    </div>
  );
};

export default ModifierList;
