import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import ruRU from 'antd/lib/locale/ru_RU';
import 'moment/locale/ru';
import 'antd/dist/antd.min.css';
import 'utils/scss/indents.scss';
import 'utils/scss/layout.scss';
import 'utils/scss/typography.scss';
// Import to enable mocks
import 'utils/axios/mocks';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SentryWatcher } from 'components';
import reportWebVitals from './reportWebVitals';

import Router from './router';

import store from './redux/store';

moment.locale('ru');

Sentry.init({
  // TODO Later it can be moved to .env
  dsn: 'https://4b5551aa6da9427e8d759aea2198ccb5@o1346460.ingest.sentry.io/6624376',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production' && !process.env.CI
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <SentryWatcher />
    <ConfigProvider locale={ruRU}>
      <Router />
    </ConfigProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
