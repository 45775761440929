import axios from 'utils/axios';

type Params = {
  hallId: number;
  name: string;
  number: number;
};

const createTable = async (data: Params) => axios.post(`/tables`, data);

export default createTable;
