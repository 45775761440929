import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik, FormikConfig } from 'formik';
import * as yup from 'yup';
import { Form, Input, Button, Spin, Upload, Select, notification } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import { filesApi, promoApi } from 'api';
import errorMessages from 'utils/validation/validationErrorMessages';
import { useBlockerWithModal, BlockModalConfig } from 'utils/navigation';
import { useAppThunkDispatch, useFormValidatedOnce } from 'utils/hooks';
import { ErrorMessageLabel, MenuSectionTitle } from 'components';
import { selectLoadingFlags, selectMenu } from 'redux/menu/selectors';
import { fetchFullMenu } from 'redux/menu/actions';
import { SubmitPromoData } from 'entities/promo';
import { selectPromos } from 'redux/promo/selectors';
import { fetchAllPromos } from 'redux/promo/actions';
import getImagePath, { getImageUuid } from 'utils/getImagePath';
import { isBlob } from 'utils/utils';
import overridesCss from 'utils/scss/overrides.module.scss';
import notificationMessages from 'utils/notificationMessages';
import DeletePromoModal from './DeletePromoModal';

import css from './SubmitPromoContainer.module.scss';

const formId = 'submitPromoForm';

const maxSelectedProducts = 10;

const blockModalConfig: BlockModalConfig = {
  title: 'Вы не сохранили промо акцию',
  description: 'Уходя вы не сохраните изменения акции. Продолжить?',
  onOkText: 'Продолжить',
  onCancelText: 'Отмена'
};

const formValidationSchema = yup
  .object({
    name: yup.string().required(errorMessages.common.isEmpty('Название')),
    image: yup.mixed().required(errorMessages.common.isEmpty('Изображение'))
  })
  .required();

type Params = {
  id: string | undefined;
};

type SubmitFormData = SubmitPromoData;

const initialValues: SubmitFormData = {
  name: '',
  image: '',
  description: '',
  productIds: []
};

const maxPromosCount = 10;

const SubmitPromoPage = () => {
  const dispatch = useAppThunkDispatch();
  const { loaded: menuLoaded } = useSelector(selectLoadingFlags);
  const menu = useSelector(selectMenu);
  const promos = useSelector(selectPromos);
  const [promoIdToDelete, setPromoIdToDelete] = useState<number | null>(null);

  const previousImageRef = useRef<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    // TODO Remove second fetch
    if (promos.length >= maxPromosCount) {
      notification.error({
        message: 'Ошибка',
        description: notificationMessages.promo.maxCount.failure(maxPromosCount)
      });
      navigate('/dashboard/promo');
    }
  }, [dispatch, navigate, promos.length]);

  const getFullMenu = useCallback(async () => {
    try {
      await dispatch(fetchFullMenu()).unwrap();
    } catch (error: any) {
      notification.error({ message: error });
    }
  }, [dispatch]);

  useEffect(() => {
    if (!menuLoaded) {
      getFullMenu();
    }
  }, [getFullMenu, menuLoaded]);

  const params = useParams<Params>();
  const [loading, setLoading] = useState(false);

  const isNew = !params.id;
  const promoId = parseInt(params.id || '0', 10);

  const onSubmit: FormikConfig<SubmitFormData>['onSubmit'] = useCallback(
    async (data, { resetForm }) => {
      const { image } = data;

      let imageLink = isBlob(image) ? '' : image;

      if (data.image !== null && typeof data.image !== 'string') {
        const response = await filesApi.uploadFile(data.image);

        imageLink = response.data.file;
      }

      const nextData = {
        ...data,
        image: imageLink
      };

      return (isNew ? promoApi.createPromo(nextData) : promoApi.updatePromo(promoId, nextData))
        .then(() => {
          if (previousImageRef.current && imageLink && imageLink !== previousImageRef.current) {
            const imageUuid = getImageUuid(previousImageRef.current);

            filesApi.deleteFile(imageUuid);
          }
          notification.success({
            message: isNew ? notificationMessages.promo.create.success() : notificationMessages.promo.update.success()
          });
          resetForm({
            values: initialValues
          });
          setTimeout(() => {
            navigate('/dashboard/promo');
            dispatch(fetchAllPromos());
          }, 20);
        })
        .catch(() => {
          notification.error({
            message: notificationMessages.defaultError()
          });
        });
    },
    [promoId, isNew, navigate, dispatch]
  );

  const { setSubmitCount, validatedOnce } = useFormValidatedOnce();

  const formik = useFormik<SubmitFormData>({
    initialValues,
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: validatedOnce,
    onSubmit
  });

  useBlockerWithModal(blockModalConfig, formik.dirty);

  const { resetForm } = formik;

  setSubmitCount(formik.submitCount);

  useEffect(() => {
    let shouldOmitResponse = false;

    if (!isNew) {
      setLoading(true);
      promoApi
        .getPromo(promoId)
        .then(({ data }) => {
          if (!shouldOmitResponse) {
            resetForm({
              values: data
            });
            previousImageRef.current = data.image;
            setLoading(false);
          }
        })
        .catch(() => {
          notification.error({
            message: notificationMessages.promo.get.failure(promoId)
          });
          navigate('/dashboard/promo');
        });
    } else {
      resetForm({ values: initialValues });
    }

    return () => {
      shouldOmitResponse = true;
    };
  }, [isNew, promoId, resetForm, navigate]);

  const recommendedItemsLimitReached = formik.values.productIds.length >= maxSelectedProducts;

  const onDeletePromo = useCallback(() => {
    setPromoIdToDelete(promoId);
  }, [promoId]);

  const onDeletePromoModalClosed = useCallback(() => {
    resetForm();
    setPromoIdToDelete(null);
  }, [resetForm]);

  return (
    <div className={`${css.submitPromoContainer} ${overridesCss.shortSelectItems}`}>
      <Spin spinning={formik.isSubmitting || loading}>
        <Form
          className={css.submitPromoForm}
          name="submit-promo"
          layout="vertical"
          id={formId}
          autoComplete="off"
          onFinish={formik.submitForm}
        >
          <div className={css.headerContainer}>
            <MenuSectionTitle
              title={isNew ? 'Добавить акцию ' : 'Изменить акцию'}
              onButtonClick={() => navigate('/dashboard/promo')}
            />
          </div>
          <div className={css.fieldsContainer}>
            <Form.Item label="Название акции" validateStatus={formik.errors.name ? 'error' : undefined}>
              <Input placeholder="Введите название акции" {...formik.getFieldProps('name')} />
              <ErrorMessageLabel message={formik.errors.name} />
            </Form.Item>
            {formik.values.image && (
              <Form.Item validateStatus={formik.errors.image ? 'error' : undefined}>
                {typeof formik.values.image === 'string' ? (
                  <img
                    width={300}
                    style={{ objectFit: 'contain', maxHeight: 300 }}
                    src={getImagePath(formik.values.image)}
                    alt="Ссылка на картинку"
                  />
                ) : (
                  <img
                    width={300}
                    style={{ objectFit: 'contain', maxHeight: 300 }}
                    src={URL.createObjectURL(formik.values.image)}
                    alt="Ссылка на картинку"
                  />
                )}
              </Form.Item>
            )}

            <Form.Item label="Изображение">
              <ImgCrop
                quality={1}
                aspect={288 / 125}
                modalTitle="Кадрировать изображение"
                modalOk="Применить"
                modalCancel="Отмена"
              >
                <Upload
                  name="logo"
                  beforeUpload={(data) => {
                    formik.setFieldValue('image', data);
                  }}
                  listType="picture"
                  fileList={[]}
                  accept="image/*"
                >
                  <Button icon={<UploadOutlined />}>
                    {formik.values.image ? 'Изменить изображение' : 'Загрузить изображение'}
                  </Button>
                </Upload>
              </ImgCrop>
              <ErrorMessageLabel message={formik.errors.image} />
            </Form.Item>
            <Form.Item label="Описание">
              <Input.TextArea placeholder="Введите описание акции" {...formik.getFieldProps('description')} />
            </Form.Item>

            <h4 className="pt-12">Выберите участвующие в акции блюда (максимум {maxSelectedProducts})</h4>
            <Form.Item label="Можно не указывать блюда и добавится простая акция">
              <Select
                {...formik.getFieldProps('productIds')}
                mode="multiple"
                placement="topLeft"
                optionFilterProp="label"
                placeholder="Выберите одно или несколько блюд"
                getPopupContainer={() => document.getElementById('formId') || document.body}
                onChange={(nextValue) => {
                  formik.setFieldValue('productIds', nextValue);
                }}
              >
                {menu.map((category) => {
                  if (!category.items.length) {
                    return null;
                  }

                  return (
                    <Select.OptGroup key={category.categoryId} label={category.categoryName}>
                      {category.items.map((item) => {
                        const isActive = formik.values.productIds.includes(item.id);
                        const shouldBeDisabled = !isActive && recommendedItemsLimitReached;

                        return (
                          <Select.Option key={item.id} disabled={shouldBeDisabled} value={item.id} label={item.name}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select.OptGroup>
                  );
                })}
              </Select>
            </Form.Item>

            <div className={css.submitPromoButtonsContainer}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              >
                {isNew ? 'Создать' : 'Сохранить'}
              </Button>
              {!isNew && (
                <Button type="primary" danger className="ml-12" onClick={onDeletePromo}>
                  Удалить
                </Button>
              )}
            </div>
          </div>
        </Form>
        <DeletePromoModal promoId={promoIdToDelete} onClose={onDeletePromoModalClosed} />
      </Spin>
    </div>
  );
};

export default SubmitPromoPage;
