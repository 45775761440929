import axios from 'utils/axios';

type Params = {
  pointId: number;
  name: string;
};

const createHall = async (data: Params) => axios.post(`/halls`, data);

export default createHall;
