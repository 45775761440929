import Axios from 'axios';
import { clearAuthTokens, getAccessToken, getRefreshToken, logoutRedirect, setAuthTokens } from 'utils/auth';
import type { AuthResponseWithTokens } from 'entities/auth';
import axios from './axios';

// This request placed here to avoid big cycle dependency
const refreshToken = async (token: string) => axios.post<AuthResponseWithTokens>('/refresh', { refreshToken: token });

let refreshRequest: null | Promise<void>;

const updateTokens = () => {
  if (refreshRequest) {
    return refreshRequest;
  }

  refreshRequest = refreshToken(getRefreshToken()!)
    .then((res) => {
      setAuthTokens(res.data.accessToken, res.data.refreshToken);
    })
    .catch((e) => {
      // Trying to call global unblock window function from history block hook
      // @ts-ignore
      window?.globalUnblock?.();
      clearAuthTokens();
      logoutRedirect();
      throw e;
    })
    .finally(() => {
      setTimeout(() => {
        refreshRequest = null;
      }, 100);
    });

  return refreshRequest;
};

axios.interceptors.response.use(undefined, (error) => {
  if (
    // If user has no access token
    !getAccessToken() ||
    // or this is cancelled request
    Axios.isCancel(error) ||
    // or if the status is not about permissions
    ![401, 403].includes(error?.response?.status) ||
    // we should not try retry refresh token request
    ['/refresh'].includes(error?.config.url) ||
    // or it's already retry of some request
    error?.config.isRetry
  ) {
    // then we throw original error
    return Promise.reject(error);
  }

  return updateTokens()
    .then(() =>
      // then we retry request
      axios.request({ ...error.config, isRetry: true })
    )
    .catch(() => {
      // at this moment we already redirected
    });
});

axios.interceptors.request.use((config) => {
  const accessToken = getAccessToken();

  if (accessToken && config.headers) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});
