export default {
  common: {
    isEmpty: (field: string) => `Поле "${field}" обязательно для заполнения`,
    isInvalid: (field: string) => `Поле "${field}" заполнено некорректно`
  },
  password: {
    // Need to fix a message to match the actual regexp
    notMatchPattern: () =>
      `Пароль должен содержать минимум 8 символов, одну цифру, символ и одну букву в верхнем и нижнем регистре`,
    passwordsNotMatch: () => `Пароли не совпадают`
  }
};
