import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';

const selectState = (state: RootState) => state.modifiers;

const selectModifiersLoadingFlags = createSelector(selectState, ({ loading, loaded }) => ({ loading, loaded }));

const selectModifierCategories = createSelector(selectState, ({ categories }) => categories);

const selectModifiers = createSelector(selectState, ({ items }) => items);

export { selectModifiersLoadingFlags, selectModifierCategories, selectModifiers };
