import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Spin, notification, Button, Checkbox } from 'antd';
import { useFormik, FormikConfig } from 'formik';
import * as yup from 'yup';

import { menuApi } from 'api';
import { useBlockerWithModal, BlockModalConfig } from 'utils/navigation';
import { useAppThunkDispatch, useFormValidatedOnce } from 'utils/hooks';
import { fetchFullMenu } from 'redux/menu/actions';
import { selectOwnerPointId } from 'redux/user/selectors';
import errorMessage from 'utils/validation/validationErrorMessages';
import notificationMessages from 'utils/notificationMessages';
import { ErrorMessageLabel, MenuSectionTitle } from 'components';
import RemoveCategoryModal from './RemoveCategoryModal';

import css from './SubmitCategoryContainer.module.scss';

const blockModalConfig: BlockModalConfig = {
  title: 'Вы не сохранили категорию',
  description: 'Уходя со страницы вы не сохраните изменения в категории. Продолжить?',
  onOkText: 'Продолжить',
  onCancelText: 'Отмена'
};

const formValidationSchema = yup
  .object({
    name: yup.string().required(errorMessage.common.isEmpty('Название'))
  })
  .required();

type FormData = {
  name: string;
  isHidden: boolean;
};

type Params = {
  id: string | undefined;
};

type SubmitFormData = {
  name: string;
  isHidden: boolean;
};

const initialValues: SubmitFormData = {
  name: '',
  isHidden: false
};

const SubmitCategoryContainer = () => {
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  const pointId = useSelector(selectOwnerPointId)!;
  const params = useParams<Params>();
  const categoryId = parseInt(params.id || '0', 10);
  const [categoryIdToRemove, setCategoryIdToRemove] = useState<number | null>(null);

  const onSubmit: FormikConfig<SubmitFormData>['onSubmit'] = useCallback(
    (data: FormData, { resetForm }) => {
      (categoryId
        ? menuApi.updateCategory(categoryId, {
            ...data,
            pointId
          })
        : menuApi.createCategory({
            ...data,
            pointId
          })
      )
        .then(() => {
          notification.success({
            message: categoryId
              ? notificationMessages.category.update.success()
              : notificationMessages.category.create.success()
          });
          resetForm({ values: initialValues });
          setTimeout(() => {
            navigate('/dashboard/menu');
            dispatch(fetchFullMenu());
          }, 20);
        })
        .catch(() => {
          notification.error({ message: notificationMessages.defaultError() });
        });
    },
    [categoryId, dispatch, pointId, navigate]
  );

  const { setSubmitCount, validatedOnce } = useFormValidatedOnce();

  const formik = useFormik<FormData>({
    initialValues,
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: validatedOnce,
    onSubmit
  });

  setSubmitCount(formik.submitCount);

  useBlockerWithModal(blockModalConfig, formik.dirty);

  const { resetForm, setSubmitting, values, setValues } = formik;

  const onSubmitHandler = async () => {
    await setValues({
      ...values,
      name: values.name.trim()
    });
    await formik.submitForm();
  };

  useEffect(() => {
    let shouldOmitResponse = false;

    if (categoryId) {
      setSubmitting(true);
      menuApi
        .getCategory(categoryId)
        .then((res) => {
          if (!shouldOmitResponse) {
            resetForm({
              values: {
                name: res.data.name,
                isHidden: res.data.isHidden
              }
            });
          }
        })
        .catch(() => {
          notification.error({ message: notificationMessages.category.get.failure(categoryId) });
          navigate('/dashboard/menu');
        });
    } else {
      resetForm({
        values: initialValues
      });
    }

    return () => {
      shouldOmitResponse = true;
    };
  }, [categoryId, resetForm, setSubmitting, navigate]);

  const onDeleteCategory = useCallback(() => {
    setCategoryIdToRemove(categoryId);
  }, [categoryId]);

  const onDeleteCategoryModalClosed = useCallback(() => {
    resetForm();

    setTimeout(() => {
      setCategoryIdToRemove(null);
      dispatch(fetchFullMenu());
      navigate('/dashboard/menu');
    }, 50);
  }, [resetForm, dispatch, navigate]);

  return (
    <div className={css.submitCategoryContainer}>
      <Spin spinning={formik.isSubmitting}>
        <Form
          name="create-category"
          className={css.submitCategoryForm}
          layout="vertical"
          autoComplete="off"
          onFinish={onSubmitHandler}
        >
          <MenuSectionTitle
            title={categoryId ? 'Изменить категорию' : 'Добавить категорию'}
            onButtonClick={() => navigate('/dashboard/menu')}
          />

          <Form.Item
            label="Название категории"
            validateStatus={formik.errors.name ? 'error' : undefined}
            data-name="nameFormItem"
          >
            <Input placeholder="Введите название категории" {...formik.getFieldProps('name')} />
            <ErrorMessageLabel message={formik.errors.name} />
          </Form.Item>

          <Form.Item data-name="isHiddenFormItem">
            <Checkbox {...formik.getFieldProps('isHidden')} checked={values.isHidden}>
              Скрыть категорию
            </Checkbox>
          </Form.Item>

          <div>
            <Button type="primary" htmlType="submit" disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
              {categoryId ? 'Обновить' : 'Создать'}
            </Button>
            {!!categoryId && (
              <Button type="primary" danger onClick={onDeleteCategory} className="ml-12">
                Удалить
              </Button>
            )}
          </div>
        </Form>

        <RemoveCategoryModal categoryId={categoryIdToRemove} onClose={onDeleteCategoryModalClosed} />
      </Spin>
    </div>
  );
};

export default SubmitCategoryContainer;
