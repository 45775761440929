import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Divider, Form, notification, Spin } from 'antd';
import { useFormik } from 'formik';

import { useAppThunkDispatch } from 'utils/hooks';
import { BlockModalConfig, useBlockerWithModal } from 'utils/navigation';
import notificationMessages from 'utils/notificationMessages';
import { selectLoadingFlags } from 'redux/menu/selectors';
import { selectStopList, selectStopListLoadingFlags } from 'redux/stopList/selectors';
import { fetchFullMenu } from 'redux/menu/actions';
import { fetchStopList, updateStopListAction } from 'redux/stopList/actions';
import { MenuSectionTitle, PagePreloader } from 'components';
import { Helmet } from 'react-helmet';
import StopList from './StopList';
import DishList from './DishList';

import css from './StopListPage.module.scss';
import { selectIikoEnabled } from '../../redux/user/selectors';

const blockModalConfig: BlockModalConfig = {
  title: 'Вы не сохранили стоп-лист',
  description: 'Уходя со страницы вы не сохраните изменения в стоп-листе. Продолжить?',
  onOkText: 'Продолжить',
  onCancelText: 'Отмена'
};

type SubmitFormData = {
  productsIds: number[];
};

const StopListPage = () => {
  const iikoEnabled = useSelector(selectIikoEnabled);

  const dispatch = useAppThunkDispatch();
  const { loaded: isMenuLoaded, loading: isMenuLoading } = useSelector(selectLoadingFlags);
  const { loading: isStopListLoading } = useSelector(selectStopListLoadingFlags);
  const stopList = useSelector(selectStopList);

  const formik = useFormik<SubmitFormData>({
    initialValues: {
      productsIds: stopList
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        await dispatch(updateStopListAction(values.productsIds)).unwrap();
        dispatch(fetchStopList());
        resetForm({
          values
        });
        notification.success({ message: notificationMessages.stopList.update.success() });
      } catch (error: any) {
        notification.error({ message: notificationMessages.defaultError() });
      }
    }
  });

  const { resetForm } = formik;

  useEffect(() => {
    resetForm({
      values: {
        productsIds: stopList
      }
    });
  }, [resetForm, stopList]);

  useBlockerWithModal(blockModalConfig, formik.dirty);

  const getFullMenu = useCallback(async () => {
    try {
      await dispatch(fetchFullMenu()).unwrap();
    } catch (error: any) {
      notification.error({ message: error });
    }
  }, [dispatch]);

  const getStopList = useCallback(async () => {
    try {
      await dispatch(fetchStopList()).unwrap();
    } catch (error: any) {
      notification.error({ message: error });
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isMenuLoaded) {
      getFullMenu();
    }
  }, [getFullMenu, isMenuLoaded]);

  useEffect(() => {
    getStopList();
  }, [getStopList]);

  const {
    setFieldValue,
    values: { productsIds }
  } = formik;

  const onSelectProductHandler = useCallback(
    (productId: number, action: 'add' | 'remove') => {
      if (action === 'add') {
        setFieldValue('productsIds', [...productsIds, productId]);
      }
      if (action === 'remove') {
        setFieldValue(
          'productsIds',
          productsIds.filter((id) => id !== productId)
        );
      }
    },
    [setFieldValue, productsIds]
  );

  const clearList = useCallback(() => {
    setFieldValue('productsIds', []);
  }, [setFieldValue]);

  if (isMenuLoading || isStopListLoading || !formik.values) {
    return <PagePreloader />;
  }

  return (
    <>
      <Helmet>
        <title>Jiff Food - стоп-лист</title>
      </Helmet>
      <div className={css.container}>
        <Spin spinning={isMenuLoading || isStopListLoading || formik.isSubmitting}>
          <div className="m-20">
            <MenuSectionTitle title="Стоп-лист" />
            <Divider />
            <Form name="stop-list" autoComplete="off" onFinish={formik.submitForm} className={css.stopListForm}>
              <StopList clearList={clearList} currentStopList={productsIds} onSelect={onSelectProductHandler} />
              <DishList currentStopList={productsIds} onSelect={onSelectProductHandler} />

              {!iikoEnabled && (
                <div className={css.buttons}>
                  <Button type="primary" disabled={!formik.dirty} htmlType="submit">
                    Сохранить
                  </Button>
                  <Button type="default" disabled={!formik.dirty} htmlType="reset" onClick={() => formik.resetForm()}>
                    Отмена
                  </Button>
                </div>
              )}
            </Form>
          </div>
        </Spin>
      </div>
    </>
  );
};

export default StopListPage;
