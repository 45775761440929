import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../store';

const selectState = (state: RootState) => state.points;

const selectPoints = createSelector(selectState, (state) => state.points);

const selectPagination = createSelector(selectState, ({ page, totalPages, totalCount, perPage }) => ({
  page,
  totalPages,
  totalCount,
  perPage
}));

const selectLoadingState = createSelector(selectState, ({ loaded, isLoading }) => ({
  loaded,
  isLoading
}));

export { selectState, selectPoints, selectPagination, selectLoadingState };
