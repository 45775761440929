import { StopListProductIds } from 'entities/stopList';
import axios from 'utils/axios';

const updateStopList = async (pointSlug: string, productsIds: StopListProductIds) =>
  axios.put(
    `/stop-list`,
    { items: productsIds },
    {
      params: {
        pointSlug
      }
    }
  );

export default updateStopList;
