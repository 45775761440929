import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik, FormikConfig } from 'formik';
import * as yup from 'yup';
import { Form, Input, Button, Spin, Select, notification } from 'antd';

import { tablesApi } from 'api';
import { selectIikoEnabled } from 'redux/user/selectors';
import { selectAllTables } from 'redux/tables/selectors';
import { fetchAllHalls } from 'redux/tables/actions';
import { Table } from 'entities/tables';
import errorMessages from 'utils/validation/validationErrorMessages';
import { useBlockerWithModal, BlockModalConfig } from 'utils/navigation';
import { useAppSelector, useAppThunkDispatch, useFormValidatedOnce } from 'utils/hooks';
import notificationMessages from 'utils/notificationMessages';

import { ErrorMessageLabel, MenuSectionTitle } from 'components';
import RemoveTableModal from './RemoveTableModal';

import css from './SubmitTableContainer.module.scss';

const formId = 'submitDishForm';

const blockModalConfig: BlockModalConfig = {
  title: 'Вы не сохранили настройки стола',
  description: 'Уходя вы не сохраните настройки стола. Продолжить?',
  onOkText: 'Продолжить',
  onCancelText: 'Отмена'
};

type SubmitTableData = Omit<Table, 'uuid' | 'id'>;

const formValidationSchema = yup
  .object({
    name: yup.string().required(errorMessages.common.isEmpty('Название')),
    number: yup.number().required(errorMessages.common.isEmpty('Номер стола')),
    hallId: yup.number().required(errorMessages.common.isEmpty('Зал')).typeError(errorMessages.common.isEmpty('Зал'))
  })
  .required();

type Params = {
  id: string | undefined;
};

const initialValues: Table = {
  name: '',
  // @ts-ignore
  hallId: null,
  // @ts-ignore
  number: null
};

const SubmitTableContainer = () => {
  const dispatch = useAppThunkDispatch();
  const iikoEnabled = useSelector(selectIikoEnabled);
  const [tableIdToRemove, setTableIdToRemove] = useState<number | null>(null);
  const params = useParams<Params>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const halls = useAppSelector(selectAllTables);

  const isNew = !params.id;
  const tableId = parseInt(params.id || '0', 10);

  const onSubmit: FormikConfig<SubmitTableData>['onSubmit'] = useCallback(
    async (data, { resetForm }) =>
      (isNew ? tablesApi.createTable(data) : tablesApi.updateTable(tableId, data))
        .then(() => {
          notification.success({
            message: isNew ? notificationMessages.table.create.success() : notificationMessages.table.update.success()
          });
          resetForm({
            values: data,
            isSubmitting: true
          });
          setTimeout(() => {
            navigate('/dashboard/tables');
            dispatch(fetchAllHalls());
          }, 20);
        })
        .catch((error) => {
          notification.error({
            message:
              error.response.status === 400
                ? notificationMessages.table.create.failure()
                : notificationMessages.defaultError()
          });
        }),
    [tableId, isNew, navigate, dispatch]
  );

  const { setSubmitCount, validatedOnce } = useFormValidatedOnce();

  const formik = useFormik<SubmitTableData>({
    initialValues,
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: validatedOnce,
    onSubmit
  });

  useBlockerWithModal(blockModalConfig, formik.dirty);

  const { resetForm } = formik;

  setSubmitCount(formik.submitCount);

  useEffect(() => {
    let shouldOmitResponse = false;

    if (!isNew) {
      setLoading(true);
      tablesApi
        .getTable(tableId)
        .then((res) => {
          if (!shouldOmitResponse) {
            const { data } = res;
            const { name, number, hallId } = data;

            resetForm({
              values: {
                name,
                number,
                hallId
              }
            });
            setLoading(false);
          }
        })
        .catch(() => {
          notification.error({
            message: notificationMessages.table.get.failure(tableId)
          });
          navigate('/dashboard/tables');
        });
    } else {
      resetForm({
        values: { ...initialValues, hallId: halls[0].id }
      });
    }

    return () => {
      shouldOmitResponse = true;
    };
  }, [isNew, tableId, resetForm, navigate, iikoEnabled, halls]);

  const onDeleteTable = useCallback(() => {
    setTableIdToRemove(tableId);
  }, [tableId]);

  const onDeleteTableModalClosed = useCallback(() => {
    resetForm();

    setTimeout(() => {
      setTableIdToRemove(null);
      dispatch(fetchAllHalls());
      navigate('/dashboard/tables');
    }, 50);
  }, [resetForm, dispatch, navigate]);

  return (
    <div className={css.submitTableContainer}>
      <Spin spinning={formik.isSubmitting || loading}>
        <Form
          className={css.submitTableForm}
          name="submit-table"
          layout="vertical"
          id={formId}
          autoComplete="off"
          onFinish={formik.submitForm}
        >
          <div className={css.headerContainer}>
            <MenuSectionTitle
              title={isNew ? 'Создать стол' : 'Изменить стол'}
              onButtonClick={() => navigate('/dashboard/tables')}
            />
          </div>

          <div className={css.fieldsContainer}>
            <Form.Item label="Название стола" validateStatus={formik.errors.name ? 'error' : undefined}>
              <Input placeholder="Введите название стола" disabled={iikoEnabled} {...formik.getFieldProps('name')} />
              <ErrorMessageLabel message={formik.errors.name} />
            </Form.Item>

            <Form.Item label="Номер стола" validateStatus={formik.errors.number ? 'error' : undefined}>
              <Input
                placeholder="Введите номер стола"
                value={formik.values.number}
                onChange={(e) => {
                  e.stopPropagation();
                  const { value } = e.target;

                  if (Number.isNaN(+value)) {
                    return undefined;
                  }

                  return formik.setFieldValue('number', +value);
                }}
              />
              <ErrorMessageLabel message={formik.errors.number} />
            </Form.Item>
            <Form.Item label="Зал" validateStatus={formik.errors.hallId ? 'error' : undefined}>
              <Select
                {...formik.getFieldProps('hallId')}
                placeholder="Выберите зал, к которому относится стол"
                disabled={iikoEnabled}
                onChange={(nextHallId) => {
                  formik.setFieldValue('hallId', nextHallId);
                }}
              >
                {halls.map((hall) => (
                  <Select.Option key={hall.id} value={hall.id}>
                    {hall.name}
                  </Select.Option>
                ))}
              </Select>
              <ErrorMessageLabel message={formik.errors.hallId} />
            </Form.Item>

            <div className={css.submitTableButtonsContainer}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              >
                {isNew ? 'Создать' : 'Сохранить'}
              </Button>
              {!isNew && !iikoEnabled && (
                <Button type="primary" danger onClick={onDeleteTable} className="ml-12">
                  Удалить
                </Button>
              )}
            </div>
          </div>
        </Form>
        <RemoveTableModal tableId={tableIdToRemove} onClose={onDeleteTableModalClosed} />
      </Spin>
    </div>
  );
};

export default SubmitTableContainer;
