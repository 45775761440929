import React from 'react';
import { Form, Input, Modal, notification } from 'antd';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ErrorMessageLabel } from 'components';
import { useAppThunkDispatch, useFormValidatedOnce } from 'utils/hooks';
import { emailFieldValidation } from 'utils/validation/fields';
import errorMessages from 'utils/validation/validationErrorMessages';
import { pointsApi } from 'api';
import notificationMessages from 'utils/notificationMessages';
import { fetchPoints } from 'redux/points/actions';

type Props = {
  pointId: number | null;
  onCancel: () => void;
};

const formValidationSchema = yup
  .object({
    email: emailFieldValidation,
    firstName: yup.string().required(errorMessages.common.isEmpty('Фамилия')),
    lastName: yup.string().required(errorMessages.common.isEmpty('Имя'))
  })
  .required();

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
};

const InviteOwnerModal = ({ pointId, onCancel }: Props) => {
  const dispatch = useAppThunkDispatch();

  const onSubmit = (data: FormData) =>
    pointsApi
      .inviteOwner(pointId!, {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email
      })
      .then(() => {
        notification.success({
          message: notificationMessages.owner.invite.success()
        });
        dispatch(fetchPoints(1));
        onCancel();
      })
      .catch(() => {
        notification.error({
          message: notificationMessages.owner.invite.failure()
        });
      });

  const { setSubmitCount, validatedOnce } = useFormValidatedOnce();

  const formik = useFormik<FormData>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: ''
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: validatedOnce,
    onSubmit
  });

  setSubmitCount(formik.submitCount);

  return (
    <div>
      <Modal
        title="Пригласить управляющего"
        closable={!formik.isSubmitting}
        maskClosable={!formik.isSubmitting}
        cancelButtonProps={{
          disabled: formik.isSubmitting
        }}
        confirmLoading={formik.isSubmitting}
        visible={!!pointId}
        onCancel={onCancel}
        cancelText="Закрыть"
        okText="Пригласить"
        okButtonProps={{
          onClick: () => formik.handleSubmit()
        }}
      >
        <Form name="login" layout="vertical" autoComplete="off" onFinish={formik.submitForm}>
          <Form.Item label="Email" validateStatus={formik.errors.email ? 'error' : undefined}>
            <Input placeholder="Введите email пользователя" {...formik.getFieldProps('email')} />
            <ErrorMessageLabel message={formik.errors.email} />
          </Form.Item>
          <Form.Item label="Фамилия" validateStatus={formik.errors.firstName ? 'error' : undefined}>
            <Input placeholder="Введите фамилия" {...formik.getFieldProps('firstName')} />
            <ErrorMessageLabel message={formik.errors.firstName} />
          </Form.Item>
          <Form.Item label="Имя" validateStatus={formik.errors.lastName ? 'error' : undefined}>
            <Input placeholder="Введите имя" {...formik.getFieldProps('lastName')} />
            <ErrorMessageLabel message={formik.errors.lastName} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default InviteOwnerModal;
